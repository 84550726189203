import styled from "@emotion/styled";
import { Button, ButtonProps, createPolymorphicComponent, Switch, TextInput } from "@mantine/core";
import { ClearableSelect } from "ui";

export const QuestionnaireCreatorLayout = styled.section`
  background-color: ${({ theme }) => theme.other.monochromeColor[5]};
  display: flex;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
`;

export const QuestionnaireCreatorContainer = styled.div`
  width: 700px;
  margin-top: 2rem;
`;

export const QuestionnaireCreatorTop = styled.header`
  background-color: ${({ theme }) => theme.other.primaryColor};

  h1 {
    margin: 0;
    padding: 1rem 1.5rem;
    color: ${({ theme }) => theme.other.monochromeColor[6]};
  }
`;

export const QuestionnaireCreatorTopDescription = styled.div`
  min-height: 136px;
  background-color: ${({ theme }) => theme.other.tertiaryColor};
  padding: 1rem 1.5rem;
  color: ${({ theme }) => theme.other.monochromeColor[6]};
  font-size: 0.8rem;
`;

export const QuestionnaireCreatorBody = styled.section`
  padding: 4rem;
  background-color: ${({ theme }) => theme.other.monochromeColor[6]};
`;

export const QuestionnaireCreatorCard = styled.div<{ focused?: boolean }>`
  border: 1px solid ${({ theme, focused }) => (focused ? theme.colors.klp[4] : theme.other.monochromeColor[3])};
  border-radius: 10px;
  padding: 1.5rem;
`;

export const QCSubtitle = styled.h2`
  color: ${({ theme }) => theme.other.tertiaryColor};
  margin-top: 3rem;
  margin-bottom: 1.5rem;
`;

export const QCButtonContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const QCPlaceholderContainer = styled.div`
  margin-top: 2rem;
  margin-bottom: 3.75rem;
`;

export const QCShortAnswerPlaceholder = styled.div`
  height: 1.5rem;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.other.monochromeColor[2]};
  border-bottom: 1px dashed ${({ theme }) => theme.other.monochromeColor[3]};
  width: 14rem;
`;

export const QCLongAnswerPlaceholder = styled.div`
  height: 1.5rem;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.other.monochromeColor[2]};
  border-bottom: 1px dashed ${({ theme }) => theme.other.monochromeColor[3]};
`;

export const QCSelectCircleWrapper = styled.div`
  width: 12px;
  padding-top: 6px;
`;

export const QCSelectCircle = styled.div`
  border-radius: 12px;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.other.monochromeColor[2]};
  cursor: pointer;
  display: inline-block;
  outline: none;
  height: 12px;
  width: 12px;
  min-height: 12px;
  min-width: 12px;
`;

export const QCSelectLayout = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  column-gap: 8px;
  padding-left: 4px;

  span {
    font-size: 0.75rem;
    color: ${({ theme }) => theme.other.monochromeColor[2]};
    border-bottom: 1px solid transparent;
  }
`;

export const QCSelectButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding-left: 8px;
`;

export const QCUploadPlaceholder = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  jusify-content: center;
  gap: 8px;
  border: 1px solid ${({ theme }) => theme.other.monochromeColor[3]};
  color: ${({ theme }) => theme.other.monochromeColor[2]};
  border-radius: 1.2px;
  width: fit-content;
  text-wrap: nowrap;
`;

export const QCSelectAddOption = styled.button`
  background: none;
  height: 30px;
  color: ${({ theme }) => theme.other.monochromeColor[2]};
  border: none;
  border-bottom: 1px solid transparent;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: 0.75rem;

  :hover {
    border-bottom: 1px solid ${({ theme }) => theme.other.primaryColor};
  }
`;

// Title and description inputs

export const QCTitleInput = styled(TextInput)`
  font-family: Inter;
  width: 100%;

  input {
    font-family: inherit;
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.other.monochromeColor[3]};
    border-radius: 0;
    font-size: 1.125rem;
    padding-left: 0;
    padding-bottom: 1rem;
    font-weight: ${({ theme }) => theme.other.font.header2.fontWeight};
    color: ${({ theme }) => theme.other.primaryColor};

    &:focus,
    &:focus-within {
      border-color: ${({ theme }) => theme.other.primaryColor};
      outline: none;
    }
  }
`;

export const QCDescriptionInput = styled(TextInput)`
  font-family: Inter;
  width: 100%;

  input {
    font-family: inherit;
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.other.monochromeColor[3]};
    border-radius: 0;

    padding-left: 0;
    font-weight: 600;
    font-style: italic;
    color: ${({ theme }) => theme.other.monochromeColor[2]};

    &:focus,
    &:focus-within {
      border-color: ${({ theme }) => theme.other.primaryColor};
      outline: none;
    }
  }
`;

// Inputs specific for QuestionnaireCreator

export const QCInput = styled(TextInput)`
  font-family: Inter;
  width: 100%;

  .mantine-Input-rightSection {
    display: none;
  }

  :hover {
    .mantine-Input-rightSection {
      display: flex;
    }
  }

  input {
    font-family: inherit;
    background-color: ${({ theme }) => theme.other.monochromeColor[5]};
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.other.monochromeColor[3]};
    border-radius: 1.2px;

    &:focus,
    &:focus-within {
      border-color: ${({ theme }) => theme.other.primaryColor};
      outline: none;
    }
  }
`;

export const QCSwitch = styled(Switch)`
  align-items: center;
  .mantine-Switch-label {
    font-family: Inter;
    font-weight: 400;
  }

  .mantine-Switch-body {
    height: 30px;
    align-items: center;
  }

  .mantine-Switch-track {
    height: 8px;
    overflow: visible;
  }

  .mantine-Switch-thumb {
    box-shadow: 0px 0px 5px 0.5px rgba(0, 0, 0, 0.25);
    height: 13px;
    width: 13px;
    left: -2px;
  }

  .mantine-Switch-trackLabel {
    min-width: 16px;
  }

  input:checked + * > .mantine-Switch-thumb {
    left: calc(100% - 12px - -2px);
    border-color: ${({ theme }) => theme.other.primaryColor};
    background-color: ${({ theme }) => theme.other.primaryColor};
  }

  input:checked + .mantine-Switch-track {
    background-color: ${({ theme }) => theme.other.secondaryColor};
    border-color: ${({ theme }) => theme.other.secondaryColor};
  }
`;

export const QCSelectStyled = styled(ClearableSelect)`
  .mantine-Select-icon {
    width: 54px;
    line-height: 30px;
    color: ${({ theme }) => theme.other.monochromeColor[0]};
  }

  .mantine-Select-input {
    font-family: Inter;
    border: 1px solid ${({ theme }) => theme.other.monochromeColor[3]};
    border-radius: 1.2px;
    padding-left: 54px;
  }
`;

const StyledButton = styled(Button)`
  font-weight: 400;
  font-family: Inter;

  :hover {
    background: transparent;
    color: ${({ theme }) => theme.other.primaryColor};
  }
`;

export const QCButton = createPolymorphicComponent<"button", ButtonProps>(StyledButton);

export const QCSelectIcon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Footer

export const QCFooter = styled.footer`
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;
