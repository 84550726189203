import { TAnalyticsChartData, TAnalyticsTableData } from "types";
import { DonutChart } from "ui";
import { CashLogo, IndustryLogo } from "ui/icons";

import { RelativeContainer, ReportsChartCardContainer, StyleDonutLabel } from "../styles";

export type ReportsChartProps = {
  chartData: {
    emissionChart: TAnalyticsChartData[];
    spendChart: TAnalyticsChartData[];
  };
  selectedGroup: { from: "chart" | "table"; id: string | null };
  setSelectedGroup: React.Dispatch<React.SetStateAction<{ from: "chart" | "table"; id: string | null }>>;
  tableData: TAnalyticsTableData;
};

export const ReportsChartCard = ({ selectedGroup, chartData, tableData, setSelectedGroup }: ReportsChartProps) => {
  const renderChart = (frequencyHolder: "CO2e" | "Spend", donutChartData: TAnalyticsChartData[]) => (
    <DonutChart
      selectedID={selectedGroup.id}
      width={177}
      height={177}
      donutChartData={donutChartData}
      tableData={tableData}
      frequencyHolder={frequencyHolder}
      setHover={(s: TAnalyticsChartData) => {
        s.id && setSelectedGroup({ id: s.id, from: "chart" });
      }}
      setHoverOff={() => {
        setSelectedGroup({ id: null, from: "chart" });
      }}
    />
  );

  const emissionsChart = renderChart("CO2e", chartData.emissionChart);

  const spendChard = renderChart("Spend", chartData.spendChart);

  return (
    <ReportsChartCardContainer>
      <RelativeContainer>
        <StyleDonutLabel>
          <IndustryLogo />
          CO₂e Emissions
        </StyleDonutLabel>
        {emissionsChart}
      </RelativeContainer>
      <RelativeContainer>
        <StyleDonutLabel>
          <CashLogo />
          Spend
        </StyleDonutLabel>
        {spendChard}
      </RelativeContainer>
    </ReportsChartCardContainer>
  );
};
