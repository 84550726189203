import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Burger, Container, createStyles, Group, Menu, Tabs, useMantineTheme } from "@mantine/core";
import Button from "ui/buttons/Button";
import { KloopifyLogo } from "ui/icons";

const useStyles = createStyles(theme => ({
  header: {
    backgroundColor: theme?.other?.monochromeColor[6],
    boxShadow: "0px 0px 14px rgba(0, 0, 0, 0.08)",
    height: "120px"
  },
  mainSection: {
    paddingTop: "20px",
    paddingBottom: "20px",
    maxWidth: "1366px",
    width: "100%",
    backgroundColor: theme?.other?.monochromeColor[6],
    display: "flex",
    justifyContent: "space-between",
    paddingLeft: "0px",
    paddingRight: "0px",
    [theme.fn.smallerThan(1366)]: {
      width: "calc(100% - 20px)"
    },
    [theme.fn.smallerThan(1100)]: {
      width: "75%"
    }
  },
  mainSectionNoTabs: {
    [theme.fn.smallerThan(1100)]: {
      justifyContent: "center"
    }
  },
  link: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    textDecoration: "none",
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,

    [theme.fn.smallerThan("sm")]: {
      height: 42,
      display: "flex",
      alignItems: "center",
      width: "100%"
    },

    ...theme.fn.hover({
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[6] : theme.colors.gray[0]
    })
  },

  burger: {
    [theme.fn.largerThan("xs")]: {
      display: "none"
    }
  },

  tabs: {
    padding: "0",
    [theme.fn.smallerThan(1100)]: {
      display: "none"
    }
  },

  tabsList: {
    border: "0px !important"
  },

  tab: {
    height: "70px",
    padding: "25px 0 25px 0",
    marginLeft: "16px",
    marginRight: "16px",
    backgroundColor: theme?.other?.monochromeColor[6],
    border: "0px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "19px",
    width: "auto",
    color: theme?.other?.primaryColor,

    "&:hover": {
      backgroundColor: theme?.other?.monochromeColor[6]
    },

    "&[data-active]": {
      backgroundColor: theme?.other?.monochromeColor[6],
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "19px"
    }
  },

  subLink: {
    width: "100%",
    padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,
    borderRadius: theme.radius.md,

    ...theme.fn.hover({
      backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[0]
    }),

    "&:active": theme.activeStyles
  },

  dropdownFooter: {
    backgroundColor: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[0],
    margin: -theme.spacing.md,
    marginTop: theme.spacing.sm,
    padding: `${theme.spacing.md} calc(${theme.spacing.md} * 2)`,
    paddingBottom: theme.spacing.xl,
    borderTop: `1px solid ${theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]}`
  },

  hiddenMobile: {
    display: "flex",
    gap: "10px",
    [theme.fn.smallerThan(1100)]: {
      display: "none"
    }
  },

  showMobile: {
    [theme.fn.largerThan(1100)]: {
      display: "none"
    }
  },

  hiddenDesktop: {
    [theme.fn.largerThan("sm")]: {
      display: "none"
    }
  },

  headerSpace: {
    display: "flex",
    justifyContent: "space-between"
  }
}));

interface HeaderTabsProps {
  tabs: string[];
  showLoginButton?: boolean;
  showDemoButton?: boolean;
}

export function PublicHeaderScreen({ tabs, showLoginButton, showDemoButton }: HeaderTabsProps) {
  const { classes } = useStyles();
  const theme = useMantineTheme();

  const navigate = useNavigate();

  const [userMenuOpened, setUserMenuOpened] = useState<boolean>(false);

  const items = tabs.map(tab => (
    <Tabs.Tab value={tab} key={tab}>
      {tab}
    </Tabs.Tab>
  ));

  return (
    <div className={classes.header}>
      <Container className={`${classes.mainSection} ${!tabs.length ? classes.mainSectionNoTabs : ""} `}>
        <Group>
          <KloopifyLogo />
        </Group>
        <Group>
          <Tabs
            defaultValue="Home"
            variant="outline"
            classNames={{
              root: classes.tabs,
              tabsList: classes.tabsList,
              tab: classes.tab
            }}
          >
            <Tabs.List>{items}</Tabs.List>
          </Tabs>
          <Group className={classes.hiddenMobile}>
            {showLoginButton && (
              <Button buttonVariant="filled" onClick={() => navigate("/")} width="150px">
                Log In
              </Button>
            )}
          </Group>

          {tabs.length ? (
            <Group className={classes.showMobile}>
              <Menu
                width={260}
                styles={{
                  item: {
                    fontFamily: "Inter",
                    fontStyle: "normal",
                    fontWeight: 400,
                    fontSize: "16px",
                    lineHeight: "19px",
                    color: theme?.other?.primaryColor,
                    padding: "10px"
                  }
                }}
                position="bottom-end"
                transitionProps={{
                  transition: "pop-top-right"
                }}
                onClose={() => setUserMenuOpened(false)}
                onOpen={() => setUserMenuOpened(true)}
              >
                <Menu.Target>
                  <Burger opened={userMenuOpened} color={theme?.other?.primaryColor} />
                </Menu.Target>
                <Menu.Dropdown>
                  {tabs?.map((tab: string, index: number) => {
                    if (index === tabs?.length - 1) {
                      return (
                        <Fragment key={index}>
                          <Menu.Item>{tab}</Menu.Item>
                          {showDemoButton && (
                            <Menu.Item>
                              <Button buttonVariant="outline" width="100%">
                                Schedule Demo
                              </Button>
                            </Menu.Item>
                          )}
                          {showLoginButton && (
                            <Menu.Item>
                              <Button buttonVariant="filled" onClick={() => navigate("/")} width="100%">
                                Log In
                              </Button>
                            </Menu.Item>
                          )}
                        </Fragment>
                      );
                    }

                    return <Menu.Item key={index}>{tab}</Menu.Item>;
                  })}
                </Menu.Dropdown>
              </Menu>
            </Group>
          ) : null}
        </Group>
      </Container>
    </div>
  );
}
