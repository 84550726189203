import React, { useEffect, useState } from "react";

import { Button } from "@mantine/core";
import { ClassificationMultiSelect } from "components";
import { useGetClassificationData } from "modules/transactions/hooks";
import { methodDropdownConfig } from "modules/transactions/transactions.config";
import { TAnalyticsFilters, TOverviewDashboardModal } from "types";
import { ModalFooter } from "ui";
import Checkbox from "ui/inputs/Checkbox";
import GroupCheckbox from "ui/inputs/GroupCheckbox";

import {
  AdditionalFilterContainer,
  AdditionalFilterHeader,
  AdditionalFilterInputWrapper,
  AdditionalFilterModal,
  AdditionalFilterTitle,
  AdditionalFilterTopSpacer
} from "../styles";

type ReportsAdditionalFilterProps = {
  filters: TAnalyticsFilters;
  modal: TOverviewDashboardModal;
  setFilters: React.Dispatch<React.SetStateAction<TAnalyticsFilters>>;
  setModal: React.Dispatch<React.SetStateAction<TOverviewDashboardModal>>;
};

export const ReportsAdditionalFilters = ({ filters, setFilters, modal, setModal }: ReportsAdditionalFilterProps) => {
  const { naicsDetailCodes, naicsIndustryCodes, naicsSectorCodes } = useGetClassificationData();

  const [middlewareFilter, setMiddlawereFilter] = useState(filters);

  const close = () => setModal(prev => ({ ...prev, additionalFilter: false }));

  const update = (updated: Partial<TAnalyticsFilters>) => setMiddlawereFilter(prev => ({ ...prev, ...updated }));

  const showResults = () => {
    close();
    setFilters(prev => ({ ...prev, ...middlewareFilter }));
  };

  const reset = () => {
    update({
      methods: [],
      naicsCodes: [],
      naicsSectors: [],
      naicsIndustries: []
    });
  };

  useEffect(() => {
    if (modal.additionalFilter) setMiddlawereFilter(filters);
  }, [modal]);

  return (
    <AdditionalFilterModal withCloseButton opened={modal.additionalFilter} setOpened={close}>
      <AdditionalFilterHeader>Additional Filters</AdditionalFilterHeader>
      <AdditionalFilterContainer>
        <div>
          <AdditionalFilterInputWrapper>
            <Checkbox checked={Boolean(middlewareFilter.methods?.length)} onChange={() => update({ methods: [] })} />
            <AdditionalFilterTitle inline>Method</AdditionalFilterTitle>
          </AdditionalFilterInputWrapper>
          <AdditionalFilterTopSpacer>
            <GroupCheckbox
              value={middlewareFilter.methods || []}
              setValue={(methods: string[]) => update({ methods })}
              data={methodDropdownConfig}
              offset={0}
            />
          </AdditionalFilterTopSpacer>
        </div>

        <div>
          <ClassificationMultiSelect
            title="NAICS Code"
            placeholder="Search NAICS Code"
            params={{ "levels[]": "DETAIL" }}
            onChange={values => update({ naicsCodes: values })}
            value={middlewareFilter.naicsCodes}
            initialData={naicsDetailCodes}
            type="detail"
          />
        </div>
        <div>
          <ClassificationMultiSelect
            title="NAICS Sector"
            placeholder="Search NAICS Sector"
            params={{ "levels[]": "SECTOR" }}
            onChange={values => update({ naicsSectors: values })}
            value={middlewareFilter.naicsSectors}
            initialData={naicsSectorCodes}
            type="sector"
          />
        </div>
        <div>
          <ClassificationMultiSelect
            title="NAICS Industry"
            placeholder="Search NAICS Industry"
            params={{ "levels[]": "INDUSTRY" }}
            onChange={values => update({ naicsIndustries: values })}
            value={middlewareFilter.naicsIndustries}
            initialData={naicsIndustryCodes}
            placement="top"
            type="industry"
          />
        </div>
      </AdditionalFilterContainer>
      <ModalFooter>
        <Button variant="subtle" onClick={reset}>
          Reset Filter
        </Button>
        <Button onClick={showResults}>Show Results</Button>
      </ModalFooter>
    </AdditionalFilterModal>
  );
};
