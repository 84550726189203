import { identity, pickBy } from "lodash";
import axiosInstance from "services/AxiosInstance";
import { TAnalyticsOptions, TAnalyticsSummaryResponse } from "types";

export type TGetAnalyticsSummaryServicePayload = Partial<{
  accountId: string;
  buyspaceId: string | null;
  end: string;
  interval: string;
  methods: string[];
  naicsCodes: string[];
  naicsIndustries: string[];
  naicsSectors: string[];
  start: string;
}>;

export const getAnalyticsSummaryService = async (
  payload: TGetAnalyticsSummaryServicePayload,
  options?: TAnalyticsOptions
): Promise<TAnalyticsSummaryResponse> => {
  const {
    start = "1970-01-01T00:00:00Z",
    end = "2030-01-01T00:00:00Z",
    methods = [],
    naicsCodes = [],
    naicsSectors = [],
    naicsIndustries = [],
    interval = "month",
    buyspaceId = null
  } = payload;

  const params = pickBy(
    { ...options, start, end, interval, buyspaceId, methods, naicsCodes, naicsSectors, naicsIndustries },
    identity
  );

  const response = await axiosInstance.get(`/analytics/${payload.accountId}/summary`, { params });

  return response.data;
};
