import styled from "@emotion/styled";
import { Avatar } from "@mantine/core";
import { ChevronRight, PolygonDangerColor } from "ui/icons";

export const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 2rem 0;
  @media only screen and (max-width: 525px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export const HeadBoxWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 24px rgba(28, 50, 79, 0.1);
  border-radius: 4px;
  height: fit-content;
  width: fit-content;
`;

export const HeadBox = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 24px rgba(28, 50, 79, 0.1);
  border-radius: 4px;
  height: fit-content;
  padding: 2.125rem 3.25rem;
  position: relative;
`;

export const WelcomeMessage = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
  text-align: center;
  color: #32a752;
  margin-bottom: 2px;
  text-transform: capitalize;
`;

export const HeaderText = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: 1.875rem;
  line-height: 2.25rem;
  text-align: center;
  color: #32a752;
  margin-bottom: 14px;
`;

export const DescriptionText = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.1875rem;
  text-align: center;
  color: #5c5c5c;
  margin-bottom: 37px;
  max-width: 391px;
  margin-left: auto;
  margin-right: auto;
`;

export const StyleUserProfileImageWrapper = styled.div`
  position: relative;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 28px;
`;

export const StyleUserProfileImage = styled(Avatar)`
  border-radius: 50%;
  width: 9.375rem;
  height: 9.375rem;
  border: 6px solid #32a752;
`;

export const UserName = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: #292e2a;
  margin-bottom: 7px;
  text-transform: capitalize;
`;

export const SelectAccountLabel = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.1875rem;
  color: #292e2a;
  text-align: left;
  padding: 1.625rem 3.6875rem;
`;

export const StyleAccountList = styled.div`
  border-top: 2px solid #c0c0c0;
  min-height: 50px;
  height: fit-content;
  padding: 1.625rem 3.6875rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

export const AccountLabel = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  color: #292e2a;
  max-width: 280px;
`;

export const IconLeft = styled.div`
  min-width: 50px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

export const IconLabelWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 27px;
`;

export const AccountSideIcon = styled(ChevronRight)`
  min-width: 10px;
  margin-left: 5px;
`;

export const ListingWrapper = styled.div`
  max-height: 315px;
  overflow: auto;
`;

export const ToolTipWrapper = styled.div`
  margin-left: 8px;
  margin-top: 1px;
`;

export const WrapperStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  position: absolute;
  svg: {
    color: ${({ theme }) => theme.other.dangerColor};
  }
`;

export const UserEmail = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: #292e2a;
  position: relative;
  display: flex;
  justify-content: center;
`;

export const UserEmailWithAccount = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: #292e2a;
`;

export const PolygonArrow = styled(PolygonDangerColor)`
  position: relative;
  top: -21px;
`;

export const LoaderWrapper = styled.div`
  margin: 50px 20px;
  height: 100px;
`;
