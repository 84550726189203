import { QCFormValues, TQCQuestion } from "modules/questionnaire";
import { QuestionnaireQuestionScope, QuestionnaireQuestionType } from "types";

export const initialValues: QCFormValues = {
  title: "",
  description: "",
  supplierQuestionList: [],
  productQuestionList: [],
  _focused: { scope: "supplierQuestionList", index: 0, autoFocus: true }
};

export const qcSelectProductQuestion: TQCQuestion = {
  type: QuestionnaireQuestionType.ITEM,
  scope: QuestionnaireQuestionScope.SCOPE_ITEM,
  question:
    "Select a Product. Our records of the selected product will be updated with the information provided below.",
  required: true,
  choices: []
};

export const qcNewQuestion: TQCQuestion = {
  type: QuestionnaireQuestionType.SHORT_TEXT,
  question: "",
  required: false,
  choices: []
};
