import { useParams } from "react-router-dom";

import { Button } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useCurrentUser } from "modules/login";
import { ModalTitle } from "ui";
import { PlusIcon } from "ui/icons";
import Modal from "ui/overlays/Modal";

import { useCreateBuyspace } from "../hooks";
import { BuyspaceCondition } from "../types";
import { BuyspaceForm } from "./BuyspaceForm";
import { BuyspaceProvider } from "./BuyspaceProvider";

const allowedRoles = ["INTERNAL_ANALYST", "INTERNAL_ADMIN", "ACCOUNT_ADMIN"];

export const BuyspaceModal = () => {
  const [opened, { open, close }] = useDisclosure(false);

  const createBuyspaceMutation = useCreateBuyspace({ onSuccess: close });

  const { id } = useParams() as { id: string };

  const onCreateBuyspace = (values: { conditions: BuyspaceCondition[]; name: string }) =>
    createBuyspaceMutation.mutate({
      accountId: id,
      ...values
    });

  const {
    userDetails: { userRole }
  } = useCurrentUser();

  if (userRole && !allowedRoles.includes(userRole)) {
    return null;
  }

  return (
    <div>
      <Button onClick={open} leftIcon={<PlusIcon />} size="lg">
        Add Buyspace
      </Button>
      <Modal opened={opened} handleClose={close} withCloseButton>
        <ModalTitle>New Buyspace</ModalTitle>
        <BuyspaceProvider>
          <BuyspaceForm onClose={close} onSubmit={onCreateBuyspace} />
        </BuyspaceProvider>
      </Modal>
    </div>
  );
};
