import React, { FC, useContext, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";

import { useForm, UseFormReturnType, yupResolver } from "@mantine/form";
import useAddUserToAccountMutation from "modules/account/mutations/AddUserToAccount";
import { CurrentUserContext } from "modules/login/CurrentUserContext";
import { useChangeUserStatusMutation, useDeleteUserMutation } from "modules/users";
import { useRemoveUserRole } from "modules/users/hooks/useRemoveUserRole";
import { UserInterface } from "modules/users/types/UserDto";
import { TUser, TUserAccount } from "types";
import ErrorHandler from "ui/errors/ErrorHandler";
import Pagination from "ui/navigation/Pagination";
import ConfirmationDeleteModal from "ui/overlays/ConfirmationDeleteModal";
import SuccessModal from "ui/overlays/SuccessModal";

import { ActivateUserModal } from "../ActivateUserModal";
import AddInternalUser from "../AddInternalUser";
import AssignUserToAccountModal from "../AssignUserToAccountModal";
import { SuspendUserModal } from "../SuspendUserModal";
import UpdateInternalUser from "../UpdateInternalUser";
import { UserFeatureFlagsModal } from "../UserFeatureFlagsModal/UserFeatureFlagsModal";
import { generateUserTableMenuConfig } from "../configs/userTableMenuConfig";
import UserTableRows from "./UserTableRows";
import { addUserToAccountSchema, initialUserTableModal } from "./userTable.data";
import { StyledTable, StyledTableHead, StyleHeaderTable, StyleTH, TableWrapper } from "./userTable.styles";
import { AddUserToAccountFormInterface, IUserTableMenuConfig, UserListingInterface } from "./userTable.types";

type UserTableProps = {
  activePage: number;
  errorMessage: string;
  getMenuConfigFunc?: (element: UserListingInterface) => IUserTableMenuConfig[];
  handleAccountCross?: (accountDetails: TUserAccount) => void;
  isError: boolean;
  limit: number;
  modal: typeof initialUserTableModal;
  modalController: (updatedModal: Partial<typeof initialUserTableModal>) => void;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  totalRecords: number | undefined;
  userListingList: TUser[];
};

export const UserTable: FC<UserTableProps> = ({
  userListingList,
  isError,
  activePage,
  errorMessage,
  totalRecords,
  limit,
  modal,
  getMenuConfigFunc,
  modalController,
  setPage
}) => {
  const { userDetails } = useContext(CurrentUserContext);

  const [selectedUserDetail, setSelectedUserDetail] = useState<UserInterface>();
  const [selectedUserRole, setSelectedUserRole] = useState("");
  const [targetRecord, setTargetRecord] = useState<string>("");
  const [activeRoleTile, setActiveRoleTile] = useState<TUserAccount>();

  const changeUserStatusMutation = useChangeUserStatusMutation();
  const addUserToAccountMutation = useAddUserToAccountMutation();
  const deleteUserMutation = useDeleteUserMutation();
  const removeUserRoleMutation = useRemoveUserRole();

  const removeUserRole = (accountDetails: TUserAccount) => {
    modalController({ confirmRemove: false });
    removeUserRoleMutation.mutate(accountDetails);
  };

  const handleAccountCross = (accountDetails: TUserAccount) => {
    setActiveRoleTile(accountDetails);
    modalController({ confirmRemove: true });
  };

  const handleEditUserClick = (userListing: UserListingInterface) => {
    setSelectedUserDetail(userListing);
    modalController({ updateUser: true });
  };

  const handleDeleteUserClick = (userListing: UserListingInterface) => {
    const { id } = userListing;
    setTargetRecord(id);
    modalController({ deleteUser: true });
  };

  const handleFeatureFlagsClick = (userListing: UserListingInterface) => {
    setSelectedUserDetail(userListing);
    modalController({ featureFlags: true });
  };

  const handleSuspendActivateUser = (userListing: UserListingInterface) => {
    const { id, status } = userListing;
    setTargetRecord(id);
    if (status === "ACTIVE") modalController({ suspendUser: true });
    else modalController({ activateUser: true });
  };

  const handleAddToAccount = (userListing: UserListingInterface) => {
    const { id, accounts } = userListing;
    if (accounts?.length) {
      setSelectedUserRole(accounts[0].role);
    }
    setTargetRecord(id);
    modalController({ assignUser: true });
  };

  const handleAddUserToAccount = (value: { account: string; role: string }) => {
    addUserToAccountMutation.mutate({
      accountId: value.account,
      userId: targetRecord,
      userRole: value.role
    });
    addUserToAccountForm.reset();
    modalController({ assignUser: false });
    setSelectedUserRole("");
    setTargetRecord("");
  };

  const handleDeleteConfirmation = () => {
    const id = targetRecord ?? "";
    if (id) {
      deleteUserMutation.mutate(id);
      modalController({ deleteUser: false });
    }
  };

  const changeUserStatusHandler = (status: string) => {
    const id = targetRecord ?? "";
    const value = status;
    if (id) {
      changeUserStatusMutation.mutate({ userId: id, status: value });
      if (value === "SUSPENDED") modalController({ suspendUser: false });
      else modalController({ activateUser: false });
    }
  };

  const addUserToAccountForm: UseFormReturnType<
    AddUserToAccountFormInterface,
    (values: AddUserToAccountFormInterface) => AddUserToAccountFormInterface
  > = useForm({
    initialValues: {
      account: "",
      role: ""
    },
    validate: yupResolver(addUserToAccountSchema)
  });

  const getDefaultMenuConfig = (userListing: UserListingInterface) => {
    const handlers = [
      handleEditUserClick,
      handleDeleteUserClick,
      handleSuspendActivateUser,
      handleAddToAccount,
      handleFeatureFlagsClick
    ];
    return generateUserTableMenuConfig(userDetails, handlers, userListing);
  };

  const getMenuConfig = getMenuConfigFunc || getDefaultMenuConfig;

  useEffect(() => {
    if (removeUserRoleMutation.isSuccess) modalController({ successRemove: true });
  }, [removeUserRoleMutation.isSuccess]);

  return (
    <ErrorBoundary FallbackComponent={ErrorHandler}>
      <TableWrapper>
        <StyledTable>
          <StyledTableHead>
            <tr>
              {["Firstname", "Lastname", "Email", "Accounts", "Status", "Action"].map((tableHeader: string) => (
                <StyleTH key={tableHeader} isLast={tableHeader === "Action"}>
                  {tableHeader}
                </StyleTH>
              ))}
            </tr>
          </StyledTableHead>
          <tbody>
            <UserTableRows
              userListingList={userListingList}
              getMenuConfigFunc={getMenuConfig}
              handleAccountCross={handleAccountCross}
            />
          </tbody>
        </StyledTable>
      </TableWrapper>
      {isError || (userListingList?.length === 0 && <StyleHeaderTable>{errorMessage}</StyleHeaderTable>)}
      <Pagination activePage={activePage} setPage={setPage} totalRecords={totalRecords} limit={limit} />

      <AddInternalUser openModal={modal.addNewUser} setOpenModal={() => modalController({ addNewUser: false })} />
      <UpdateInternalUser
        openModal={modal.updateUser}
        setOpenModal={() => modalController({ updateUser: false })}
        details={selectedUserDetail}
      />
      <ConfirmationDeleteModal
        modalPadding={"55px 24px 34px 27px !important"}
        descriptionLineHeight="19px"
        buttonMarginTop="44px"
        openModal={modal.deleteUser}
        confirmBtnText="Delete User"
        setOpenModal={() => modalController({ deleteUser: false })}
        titleText="Delete User?"
        descriptionText="Are you sure you want to delete this user? Any imports belonging to this user will remain active."
        handleConfirm={handleDeleteConfirmation}
      />
      <AssignUserToAccountModal
        selectedUserRole={selectedUserRole}
        openModal={modal.assignUser}
        setOpenModal={() => modalController({ assignUser: false })}
        handleConfirm={handleAddUserToAccount}
        addUserToAccountForm={addUserToAccountForm}
      />
      <SuspendUserModal
        openModal={modal.suspendUser}
        setOpenModal={() => modalController({ suspendUser: false })}
        handleConfirm={changeUserStatusHandler}
      />
      <ActivateUserModal
        openModal={modal.activateUser}
        setOpenModal={() => modalController({ activateUser: false })}
        handleConfirm={changeUserStatusHandler}
      />
      <ConfirmationDeleteModal
        modalPadding={"55px 24px 34px 27px !important"}
        descriptionLineHeight="19px"
        buttonMarginTop="44px"
        openModal={modal.confirmRemove}
        confirmBtnText="Remove"
        setOpenModal={() => modalController({ confirmRemove: false })}
        titleText="Remove User Role?"
        descriptionText="Are you sure you want to remove this user?"
        handleConfirm={() => {
          if (activeRoleTile) removeUserRole(activeRoleTile);
        }}
      />
      <UserFeatureFlagsModal
        opened={modal.featureFlags}
        close={() => modalController({ featureFlags: false })}
        user={selectedUserDetail}
      />

      <SuccessModal
        openModal={modal.successRemove}
        setOpenModal={() => modalController({ successRemove: false })}
        message={`User Role Is Successfully Removed.`}
      />
    </ErrorBoundary>
  );
};
