import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { TAnalyticsOptions } from "types";

import { getAnalyticsGroupedListService, TGetAnalyticsGroupedListServicePayload } from "../api";

export const useGetAnalyticsGrouped = (
  payload: TGetAnalyticsGroupedListServicePayload,
  options?: TAnalyticsOptions,
  queryOptions?: { enabled: boolean }
) => {
  const enabled = queryOptions === undefined ? true : queryOptions.enabled;
  return useQuery({
    queryKey: [QUERY_KEYS.get_analytics_grouped, payload],
    queryFn: () => getAnalyticsGroupedListService(payload, options),
    enabled: Boolean(payload.accountId) && enabled
  });
};
