import { ReactNode } from "react";

import { usePermissionGuard } from "hooks";
import { Feature } from "types";

type UserPermissionGuardProps = {
  children: ReactNode;
  excludeExternal?: boolean;
  fallback?: JSX.Element;
  module: Feature;
};

export const UserPermissionGuard = ({
  module,
  children,
  fallback,
  excludeExternal = false
}: UserPermissionGuardProps) => {
  const isGuarded = usePermissionGuard({ module, excludeExternal });

  return <>{isGuarded ? fallback : children}</>;
};
