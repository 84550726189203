import React from "react";

import styled from "@emotion/styled";
import { Pagination as MTPagination } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

interface PaginationProps {
  totalRecords?: number;
  initialPage?: number;
  siblings?: number;
  activePage?: number;
  setPage: ((page: number) => void) | React.Dispatch<React.SetStateAction<number>>;
  limit?: number;
  centered?: boolean;
}
const PaginationWrapper = styled.div<{ centered?: boolean }>`
  padding: 10px 0 20px 0;
  display: flex;
  justify-content: ${({ centered }) => (centered ? "center" : "flex-end")};
  @media only screen and (max-width: 1099px) {
    justify-content: center;
  }
`;
const Pagination: React.FC<PaginationProps> = ({
  totalRecords = 20,
  initialPage = 1,
  siblings = 1,
  setPage,
  limit = 20,
  activePage = 1,
  centered
}) => {
  const matches = useMediaQuery("(max-width: 36rem)");
  const siblingsCount = matches ? 0 : siblings;

  return (
    <PaginationWrapper centered={centered}>
      <MTPagination
        value={activePage} // Controlled active page number
        onChange={event => setPage(event)} // Callback fired after change of each page
        radius="xl"
        boundaries={0}
        total={Math.ceil(totalRecords / limit)} // Total amount of pages
        siblings={siblingsCount} // Siblings amount on left/right side of selected page
        defaultValue={initialPage} // Active initial page
      />
    </PaginationWrapper>
  );
};
export default Pagination;
