import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { SortingState } from "@tanstack/react-table";
import { isArray } from "lodash";
import { optionMap, sortByMap, statusMap } from "modules/questionnaire/questionnaire.data";
import { getSupplierName } from "modules/transactions";
import { StyledFilterTilesWrapper } from "modules/transactions/transactions.styles";
import FilterTile from "ui/tiles/FilterTile/FilterTile";

type QuestionnaireRequestListFilterTilesProps = {
  setSorting: Dispatch<SetStateAction<SortingState>>;
};

export const QuestionnaireRequestListFilterTiles = ({ setSorting }: QuestionnaireRequestListFilterTilesProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [supplierNameList, setSupplierNameList] = useState<string[]>([]);

  const params = useMemo(
    () => ({
      supplierIds: searchParams.getAll("supplierIds"),
      statuses: searchParams.getAll("statuses"),
      maxRespondBy: searchParams.get("maxRespondBy") || [],
      sortBy: searchParams.get("sortBy") || []
    }),
    [searchParams]
  );

  const fetchSupplierNameList = async () => {
    const supplierIds = params.supplierIds;
    if (supplierIds) {
      const requestList = supplierIds.map(supplierId => getSupplierName(supplierId));
      const responseList = await Promise.all(requestList);
      const namesList = responseList.map(response => response.data.name);
      setSupplierNameList(namesList);
      return;
    }
    setSupplierNameList([]);
  };

  const getValue = (key: string, value: string) => {
    switch (key) {
      case "sortBy":
        return sortByMap[value as keyof typeof sortByMap];
      case "statuses":
        return statusMap[value as keyof typeof statusMap];
      default:
        return value;
    }
  };

  const onClick = (key: string, value: string) => {
    if (key === "sortBy") setSorting([]);
    setSearchParams(p => [...p].filter(i => i[1] !== value));
  };

  const normalizeValue = (val: string | string[]) => (isArray(val) ? val : [val]);

  const tiles = useMemo(() => {
    const entries = Object.entries(params);

    return entries.map((entry, entryIndex) => {
      const [option, optionValue] = entry;

      const label = optionMap[option];

      const valueList = normalizeValue(optionValue);

      const normalizedValueList = option === "supplierIds" ? supplierNameList : valueList;

      return normalizedValueList.map((value, valueIndex) => (
        <FilterTile
          key={entryIndex + valueIndex}
          text={label + getValue(option, value)}
          onClick={() => onClick(option, valueList[valueIndex])}
        />
      ));
    });
  }, [params, supplierNameList]);

  useEffect(() => {
    fetchSupplierNameList();
  }, [params.supplierIds]);

  return <StyledFilterTilesWrapper>{tiles}</StyledFilterTilesWrapper>;
};
