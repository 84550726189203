import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { errorModal } from "modules/error/ErrorHandlingModals";
import { ErrorResponse } from "types";

import { deleteAccount } from "../apis/DeleteAccount";

const useDeleteAccountMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: deleteAccount,
    mutationKey: [QUERY_KEYS.delete_account],
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [QUERY_KEYS.accounts_listing] });
    },
    onError: (error: ErrorResponse) => errorModal(error)
  });
  return mutation;
};
export default useDeleteAccountMutation;
