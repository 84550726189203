import React from "react";

import { Button, Grid, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { IimportFiltersData, importFiltersInitialData } from "modules/imports/types/ImportDto";
import Checkbox from "ui/inputs/Checkbox";
import GroupCheckbox, { SelectAllCheckbox } from "ui/inputs/GroupCheckbox";
import Modal from "ui/overlays/Modal";
import { ModalFooter, ModalTitle } from "ui/styles";

import { dateFilter, statusFilter } from "./importsFilters.data";

interface ImportsFiltersInterface {
  updateImportFilters: (data: Partial<IimportFiltersData>) => void;
  importFilters: IimportFiltersData;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  openModal: boolean;
  setFilter: () => void;
}

export const ImportsFilters = ({
  updateImportFilters,
  importFilters,
  setOpenModal,
  openModal,
  setFilter
}: ImportsFiltersInterface) => {
  const { status, accountName, dates, lastUpdatedMin, lastUpdatedMax } = importFilters;

  const isStartDateChecked = lastUpdatedMin !== null && String(lastUpdatedMin).length > 0;
  const isEndDateChecked = lastUpdatedMax !== null && String(lastUpdatedMax).length > 0;
  const isDateChecked = dates.length > 0 || isStartDateChecked || isEndDateChecked;
  const isStatusChecked = status.length === statusFilter.length;
  const isAccountChecked = accountName !== "";

  const handleDateAllCheckbox = () => {
    if (isDateChecked) updateImportFilters({ dates: [], lastUpdatedMax: null, lastUpdatedMin: null });
  };

  const handleStatusAllCheckbox = () => {
    if (status.length === statusFilter.length) return updateImportFilters({ status: [] });
    updateImportFilters({ status: statusFilter.map(status => status.value) });
  };

  const handleAccountAllCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget;
    if (!checked) updateImportFilters({ accountName: "" });
  };

  const handleDateCheckbox = (dates: string[]) => {
    updateImportFilters({ dates, lastUpdatedMax: null, lastUpdatedMin: null });
  };

  const handleStatusCheckbox = (status: string[]) => {
    updateImportFilters({ status });
  };

  const handleAccountInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value) updateImportFilters({ accountName: value });
  };

  const handlePeriodInput = (period: Date | null, key: "lastUpdatedMax" | "lastUpdatedMin") => {
    updateImportFilters({ [key]: period, dates: [] });
  };

  const applyFilters = () => {
    setFilter();
    setOpenModal(false);
  };

  const onModalClose = () => setOpenModal(false);

  const resetFilters = () => updateImportFilters(importFiltersInitialData);

  return (
    <Modal opened={openModal} setOpened={setOpenModal} handleClose={onModalClose} withCloseButton>
      <ModalTitle>Filter By</ModalTitle>
      <Grid gutter={24}>
        <Grid.Col xs={12} sm={4}>
          <SelectAllCheckbox header="Date" value={isDateChecked} onChange={handleDateAllCheckbox} />
          <GroupCheckbox value={dates} setValue={handleDateCheckbox} data={dateFilter} radioMode />
          <div style={{ marginTop: "7px", marginLeft: "31px" }}>
            <Checkbox
              checked={isStartDateChecked}
              onChange={event => {
                if (!event.currentTarget.checked) {
                  updateImportFilters({ lastUpdatedMin: null });
                }
              }}
              label="Start Date"
            />
          </div>
          <div style={{ marginTop: "7px", marginLeft: "57px" }}>
            <DateInput
              placeholder="MM/DD/YYYY"
              valueFormat="MM/DD/YYYY"
              maw={160}
              onChange={dateValue => {
                handlePeriodInput(dateValue as Date, "lastUpdatedMin");
              }}
              value={lastUpdatedMin ? new Date(lastUpdatedMin) : null}
            />
          </div>
          <div style={{ marginTop: "7px", marginLeft: "31px" }}>
            <Checkbox
              checked={isEndDateChecked}
              onChange={event => {
                if (!event.currentTarget.checked) {
                  updateImportFilters({ lastUpdatedMax: null });
                }
              }}
              label="End Date"
            />
          </div>

          <div style={{ marginTop: "7px", marginLeft: "57px" }}>
            <DateInput
              placeholder="MM/DD/YYYY"
              valueFormat="MM/DD/YYYY"
              maw={160}
              onChange={dateValue => {
                handlePeriodInput(dateValue as Date, "lastUpdatedMax");
              }}
              value={lastUpdatedMax ? new Date(lastUpdatedMax) : lastUpdatedMax}
            />
          </div>
        </Grid.Col>
        <Grid.Col xs={12} sm={4}>
          <SelectAllCheckbox header="Status" value={isStatusChecked} onChange={handleStatusAllCheckbox} />
          <GroupCheckbox value={status} setValue={handleStatusCheckbox} data={statusFilter} />
        </Grid.Col>

        <Grid.Col xs={12} sm={4}>
          <SelectAllCheckbox header="Account" onChange={handleAccountAllCheckbox} value={isAccountChecked} />
          <div style={{ marginTop: "7px", marginLeft: "31px" }}>
            <Checkbox checked={isAccountChecked} onChange={handleAccountAllCheckbox} label="Name" />
          </div>
          <div style={{ marginTop: "5px", marginLeft: "57px" }}>
            <TextInput value={accountName} onChange={handleAccountInput} maw={160} />
          </div>
        </Grid.Col>
      </Grid>
      <ModalFooter>
        <Button variant="subtle" onClick={resetFilters}>
          Reset Filter
        </Button>
        <Button onClick={applyFilters}>Show Results</Button>
      </ModalFooter>
    </Modal>
  );
};
