import styled from "@emotion/styled";

export const StyleTooltipPercent = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 0.875rem;
  text-transform: capitalize;
  margin-bottom: 5px;
  margin-top: 10px;
`;
export const StyleTooltipDescription = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 0.875rem;
  margin-bottom: 5px;
`;
export const StyleTooltipValue = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 0.875rem;
  color: ${props => props.theme.other.monochromeColor[2]};
  margin-bottom: 10px;
`;
export const TooltipOnTableInteraction = styled.div`
  background: #ffffff;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  min-height: 70px;
  min-width: 160px;
  width: auto;
  text-align: left;
  padding: 10px;
  position: absolute;
  top: -15px;
  right: 35%;
  @media only screen and (max-width: 550px) {
    top: auto;
    bottom: -10px;
    right: 0;
  }
`;
export const TooltipWrapper = styled.div`
  padding: 2px 5px 2px 2px;
`;
