import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import jwt_decode from "jwt-decode";
import { useGetUserInfo } from "modules/users";
import { JwtTokenInterface } from "modules/users/types/UserDto";
import { LOGIN_TYPE, USER_TYPE } from "modules/users/types/UserTypesEnum";
import { ResponseType } from "types";
import { MSWindowLogo } from "ui/icons";

import { CurrentUserContext } from "../CurrentUserContext";
import { useAuthenticateUserMutation } from "../hooks";
import { StyleLoginButton, StyleLoginButtonText } from "../styles/Login.styles";
import { signIn } from "./AzureLogin/api";

export const AzureLogin = () => {
  const navigate = useNavigate();

  const { userDetails, setUserDetails, setUserId, setJWT_Token } = useContext(CurrentUserContext);

  const getUserInfoQuery = useGetUserInfo(userDetails.userId, Boolean(userDetails.userId));

  const authenticateUserMutate = useAuthenticateUserMutation();

  /**
   * Gets access token from azure and runs authentification
   */
  const login = async () => {
    const accessToken = await signIn();
    if (accessToken) {
      localStorage.setItem("token", accessToken);
      authenticateUserMutate.mutate(
        { accessToken: accessToken, signWith: LOGIN_TYPE.azure },
        { onSuccess: onSuccessAuthentification }
      );
    }
  };

  /**
   * Runs user query when authentification is succeded
   */
  const onSuccessAuthentification = (data: ResponseType<{ jwtToken: string }>) => {
    const jwtToken = data.data.jwtToken;
    const decodedJWT_token = jwt_decode<JwtTokenInterface>(jwtToken);
    localStorage.setItem("jwt_Token", jwtToken);
    setJWT_Token(jwtToken);
    setUserId(decodedJWT_token.sub);
  };

  /**
   * Updates user information when user query succeded
   */
  const onSuccessUserQuery = () => {
    const userData = getUserInfoQuery.data?.data;
    if (userData) {
      const { status, id, ...user } = userData;
      const { role, userId, ...account } = userData.accounts[0];
      setUserDetails(details => ({ ...details, ...user, ...account, userId: id, userRole: role }));

      if (userData.userType === USER_TYPE.internal) return navigate(`/accounts`);
      navigate("/account-selection");
    }
  };

  useEffect(() => onSuccessUserQuery(), [getUserInfoQuery.isSuccess]);

  return (
    <StyleLoginButton onClick={login}>
      <MSWindowLogo />
      <StyleLoginButtonText>Sign in with Microsoft 365</StyleLoginButtonText>
    </StyleLoginButton>
  );
};
