import React, { useEffect, useMemo, useState } from "react";

import { Text } from "@mantine/core";
import { uniq } from "lodash";
import { confirmationModal, successModal } from "modules/error/ErrorHandlingModals";
import {
  QuestionnaireModal,
  QuestionnaireNormalized,
  QuestionnaireQuestionScope,
  QuestionnaireRequestStatus,
  TQuestionnaire,
  TQuestionnaireRequest,
  TQuestionnaireResponseStatus
} from "types";

import {
  usePostQuestionnaireRequestApproveMutation,
  usePostQuestionnaireRequestRejectMutation,
  usePostQuestionnaireResponseMutation
} from "../hooks";
import { validationMap } from "../questionnaire.data";
import { normalizeQuestions } from "../questionnaire.utils";
import { QuestionnaireApproveFooter } from "./QuestionnaireApproveFooter";
import { QuestionnaireContent } from "./QuestionnaireContent";
import { QuestionnaireFactory } from "./QuestionnaireFactory";
import { QuestionnaireFooter } from "./QuestionnaireFooter";
import { QuestionnaireOverlay } from "./QuestionnaireOverlay";

type DynamicQuestionnaireProps = {
  approveEnabled?: boolean;
  questionnaireData: TQuestionnaire;
  questionnaireRequestData?: TQuestionnaireRequest;
  readOnly?: boolean;
  submitEnabled?: boolean;
};

export const DynamicQuestionnaire: React.FC<DynamicQuestionnaireProps> = ({
  questionnaireData,
  questionnaireRequestData,
  approveEnabled,
  readOnly = false,
  submitEnabled = true
}) => {
  const questionnaireMutation = usePostQuestionnaireResponseMutation();

  const status = questionnaireRequestData?.status ?? QuestionnaireRequestStatus.IN_PROGRESS;

  const isQuestionnaireApprovalEnabled = questionnaireRequestData?.status === QuestionnaireRequestStatus.SUBMITTED;

  const isQuestionnaireSubmitted = [
    QuestionnaireRequestStatus.APPROVED,
    QuestionnaireRequestStatus.REJECTED,
    QuestionnaireRequestStatus.SUBMITTED
  ].includes(status);

  const { mutate: handleApproveMutation } = usePostQuestionnaireRequestApproveMutation();
  const { mutate: handleRejectMutation } = usePostQuestionnaireRequestRejectMutation();

  const onQuestionnaireApprove = () => {
    confirmationModal({
      title: <Text color="klp.4">Approve Questionnaire Request</Text>,
      message:
        "This action will merge Products(s) that might be attached to the QUESTIONNAIRE, approving this QUESTIONNAIRE might update some Products in our app.",
      onConfirm: () => {
        if (questionnaireRequestData && questionnaireRequestData.id) {
          handleApproveMutation(questionnaireRequestData.id, {
            onSuccess: () =>
              successModal(
                `Questionnaire Request ${questionnaireRequestData.questionnaire.title} Successfully Approved`
              )
          });
        }
      }
    });
  };

  const onQuestionnaireReject = () => {
    if (questionnaireRequestData && questionnaireRequestData.id) {
      handleRejectMutation(questionnaireRequestData.id, {
        onSuccess: () =>
          successModal(`Questionnaire Request ${questionnaireRequestData.questionnaire.title} Successfully Rejected`)
      });
    }
  };

  const [questionnaireModal, setQuestionnaireModal] = useState<QuestionnaireModal | undefined>();

  const [normalizedQuestionnaire, setNormalizedQuestionnaire] = useState<QuestionnaireNormalized[]>([]);

  const isOneOfUploadedLoading = normalizedQuestionnaire.some(questionnaire =>
    questionnaire.questions.some(i => i.loading)
  );

  const isItemScopeExists = useMemo(() => {
    const itemQuestions = normalizedQuestionnaire.find(i => i.scope === QuestionnaireQuestionScope.SCOPE_ITEM);
    if (itemQuestions) return itemQuestions?.questions?.length > 0;
  }, [normalizedQuestionnaire]);

  const onChange = (response: string | string[], id: string, group: string, loading?: boolean, error?: string) => {
    if (isQuestionnaireSubmitted || readOnly) return;
    setNormalizedQuestionnaire(prev => {
      return prev.map(p => {
        if (p.id === group) {
          const questions = p.questions.map(question =>
            question.id === id ? { ...question, error: error || "", response, loading: loading || false } : question
          );
          return { ...p, questions };
        }
        return p;
      });
    });
  };

  const validateResponses = () => {
    const validated = normalizedQuestionnaire.map(normalized => {
      const { questions } = normalized;
      const validatedQuestions = questions.map(question => {
        const { response, required } = question;
        const validator = validationMap[question.type];
        const error = validator(response as string);
        return required ? { ...question, error } : question;
      });
      return { ...normalized, questions: validatedQuestions };
    });

    setNormalizedQuestionnaire(validated);
    return (
      validated
        .flatMap(i => i.questions)
        .map(i => i.error)
        .filter(i => i).length === 0
    );
  };

  const postResponses = async (status: TQuestionnaireResponseStatus) => {
    const valid = status === "SUBMITTED" ? validateResponses() : true;

    if (!valid) return;
    if (!questionnaireRequestData) return;

    const questionList = normalizedQuestionnaire.flatMap(questionnaire => questionnaire.questions);
    const uniqueQuestionIdList = uniq(questionList.map(question => question.id));
    const responses = uniqueQuestionIdList.map(unique => {
      const questions = questionList.filter(question => question.id === unique);

      const response = questions.map(question =>
        question.response && question.response.length ? question.response : null
      );

      const normalizedResponse =
        questions[0].scope === QuestionnaireQuestionScope.SCOPE_SUPPLIER ? response[0] : response;

      return { questionnaireQuestionId: unique, response: normalizedResponse };
    });

    if (responses.length) {
      const accountId = questionnaireRequestData.account.id;
      const questionnaireRequestId = questionnaireRequestData.id;
      const completedByName = questionnaireRequestData.sendTo;

      await questionnaireMutation.mutateAsync({
        accountId,
        questionnaireRequestId,
        completedByName,
        status,
        responses
      });
    }
    setQuestionnaireModal(QuestionnaireModal[status]);
  };

  const submit = () => postResponses("SUBMITTED");

  const save = () => postResponses("PENDING");

  useEffect(() => {
    if (questionnaireData && questionnaireRequestData) {
      const normalized = normalizeQuestions(
        questionnaireData.questions,
        questionnaireRequestData.questionnaireResponses
      );
      setNormalizedQuestionnaire(normalized);
    }
  }, [questionnaireData, questionnaireRequestData]);

  return (
    <>
      <QuestionnaireContent
        questions={normalizedQuestionnaire}
        onChange={onChange}
        submitted={isQuestionnaireSubmitted || readOnly}
        setNormalizedQuestionnaire={setNormalizedQuestionnaire}
      />
      {isItemScopeExists && (
        <QuestionnaireFactory
          questions={normalizedQuestionnaire}
          setNormalizedQuestionnaire={setNormalizedQuestionnaire}
          submitted={isQuestionnaireSubmitted || readOnly}
        />
      )}
      {approveEnabled && (
        <QuestionnaireApproveFooter
          onApprove={onQuestionnaireApprove}
          onReject={onQuestionnaireReject}
          disabled={!isQuestionnaireApprovalEnabled}
        />
      )}
      {submitEnabled && (
        <QuestionnaireFooter
          submit={submit}
          save={save}
          disabled={isOneOfUploadedLoading || isQuestionnaireSubmitted}
        />
      )}

      <QuestionnaireOverlay questionnaireModal={questionnaireModal} setQuestionnaireModal={setQuestionnaireModal} />
    </>
  );
};
