import styled from "@emotion/styled";

export const InsightTableWrapper = styled.table`
  thead {
    tr {
      th {
        font-weight: 500;
        padding: 0.25rem;
        font-size: 1.25rem;
      }
      th:last-child {
        border-right: none;
      }
    }
  }

  tbody {
    tr {
      td {
        box-sizing: border-box;
        text-align: left;
        font-size: 1.5rem;
        padding: 1rem;
        height: auto;
      }
    }
  }
`;

export const InsightTableHeadCell = styled.div<{ centered: boolean }>`
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  color: #333333;
  justify-content: ${({ centered }) => (centered ? "center" : "flex-start")};
  align-items: center;
  font-size: 1.25rem !important;
`;
