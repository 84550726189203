import { IBuyspaceFilters } from "types";

export const buyspaceConditionKeys = {
  COST_CENTER_ID: "Cost Center",
  SUPPLIER_ID: "Supplier",
  ITEM_ID: "Commodity",
  COST_CENTER_NAME: "Cost Center Name",
  SUPPLIER_NAME: "Supplier Name",
  ITEM_NAME: "Commodity Name",
  NAICS_CODE_NAME: "NAICS Code Name",
  NAICS_SECTOR_NAME: "NAICS Sector Name",
  NAICS_INDUSTRY_NAME: "NAICS Industry Name",
  NAICS_CODE: "NAICS Code",
  NAICS_INDUSTRY: "NAICS Industry",
  NAICS_SECTOR: "NAICS Sector",
  TRANSACTION_SPEND: "Transaction Spend",
  TRANSACTION_CO2E: "Transaction CO₂e"
};

export const buyspaceOperator = {
  EQUAL: "=",
  NOT_EQUAL: "not",
  LOWER: "<",
  LOWER_OR_EQUAL: "<=",
  GREATER: ">",
  GREATER_OR_EQUAL: ">=",
  IN: "=",
  NOT_IN: "not",
  APPROXIMATELY_EQUAL: "matches"
};

export const conditionKeyToDataKey = {
  SUPPLIER_ID: "suppliers",
  ITEM_ID: "items",
  COST_CENTER_ID: "costCenters",
  NAICS_CODE: "naicsCodes",
  NAICS_INDUSTRY: "naicsIndustries",
  NAICS_SECTOR: "naicsSectors"
} as const;

export const initialBuyspaceFilters: IBuyspaceFilters = {
  page: 0,
  limit: 10
};
