import { useState } from "react";

import { Alert, Group } from "@mantine/core";
import { useCurrentUser } from "modules/login";
import { WarningIcon } from "ui/icons";

import { useGetCustomFields } from "../hooks";
import { CreateCustomFieldModal } from "./CreateCustomFieldModal";
import { CustomFieldLoader } from "./CustomFieldLoader";
import { CustomFieldRemoveButton } from "./CustomFieldRemoveButton";
import { DeleteCustomFieldModal } from "./DeleteCustomFieldModal";
import { CustomFieldBadge, CustomFieldSectionContainer } from "./customFieldSection.styles";

export const CustomFieldSection = () => {
  const {
    userDetails: { accountId }
  } = useCurrentUser();

  const [removedFieldId, setRemovedFieldId] = useState<string | null>(null);

  const onDelete = (customFieldId: string) => setRemovedFieldId(customFieldId);
  const onDeleteCancel = () => setRemovedFieldId(null);

  const { data, isLoading, error } = useGetCustomFields({ accountId });

  const fields = data.data.map(field => (
    <CustomFieldBadge
      variant="outline"
      size="xl"
      rightSection={<CustomFieldRemoveButton onClick={() => onDelete(field.id)} />}
      key={field.id}
    >
      {'"' + field.fieldLabel + '": ' + field.field}
    </CustomFieldBadge>
  ));

  const renderContent = () => {
    if (error && !isLoading) {
      return (
        <Alert icon={<WarningIcon />} title="Error!" color="red" w="100%">
          {error.errorMessage}
        </Alert>
      );
    }

    if (isLoading) {
      return <CustomFieldLoader />;
    }

    return (
      <Group spacing="lg">
        {fields}
        <CreateCustomFieldModal />
        <DeleteCustomFieldModal accountId={accountId} id={removedFieldId} onClose={onDeleteCancel} />
      </Group>
    );
  };

  return <CustomFieldSectionContainer>{renderContent()}</CustomFieldSectionContainer>;
};
