import { Center, LoadingOverlay, Select, SelectItem } from "@mantine/core";
import { useGetAnalyticsGrouped } from "modules/analytics";
import { defaultAnalyticsFilters, useGetSupplierById } from "modules/supplierDetails";
import Loader from "ui/feedback/Loader";

import { MergeSupplierInformation } from "./MergeSupplierInformation";
import { MergeSupplierModalContainer } from "./styles";

type MergeSuppliersModalContentProps = {
  onChange: (value: string) => void;
  options: SelectItem[];
  value: string | undefined;
};

export const MergeSuppliersModalContent: React.FC<MergeSuppliersModalContentProps> = ({ options, onChange, value }) => {
  const { data: supplierData, isLoading: supplierLoading } = useGetSupplierById(value);

  const { data: analyticsData, isLoading: analyticsLoading } = useGetAnalyticsGrouped({
    supplier: value,
    accountId: supplierData?.account.id,
    interval: "month",
    ...defaultAnalyticsFilters
  });

  const isLoading = Boolean((supplierLoading || analyticsLoading) && value);

  const renderInformation = () => {
    if (!supplierData || !analyticsData || !value) {
      return <Center>Select a target supplier.</Center>;
    }

    return <MergeSupplierInformation supplierData={supplierData} summaryData={analyticsData} />;
  };

  return (
    <>
      <Center>
        <Select data={options} value={value} onChange={onChange} w={300} />
      </Center>
      <MergeSupplierModalContainer>
        {renderInformation()}
        <LoadingOverlay visible={isLoading} loader={<Loader />} />
      </MergeSupplierModalContainer>
    </>
  );
};
