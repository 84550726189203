import { graphColorKey } from "constants/colorPalette";
import moment from "moment-timezone";
import {
  QuestionnaireRequestStatus,
  TAnalyticsChartData,
  TAnalyticsData,
  TAnalyticsResponse,
  TAnalyticsTabularData,
  TMethodologyObject,
  TQuestionnaireRequest
} from "types";
import { sortArray } from "utils/common.utils";

import { InsightsCardTypeEnum, MapNamings } from "./analytics.types";

export const MILLIONS_OF_DOLLARS = 1_000_000 * 100;

const groupBrokenValues = ["null", "n/a", "n/"];

export const convertToTimeSeriesData = (inputs: TAnalyticsData[] | null) => {
  const xAxis: string[] = [];
  const y1Axis: number[] = [];
  const y2Axis: number[] = [];
  const y3Axis: number[] = [];

  if (inputs?.length && inputs[0].interval.length) {
    inputs.sort((a, b) => {
      return moment(a.interval[0]).valueOf() - moment(b.interval[0]).valueOf();
    });
    inputs.forEach(input => {
      xAxis.push(input?.interval[0] ? moment(input?.interval[0]).tz("Etc/UTC").format("MMM-YYYY") : "");

      y1Axis.push(input.spend);

      const co2e = Number(input?.co2e.toFixed(2));
      y2Axis.push(co2e);

      const carbonIntensity = input.co2e / (input.spend / MILLIONS_OF_DOLLARS);

      y3Axis.push(carbonIntensity);
    });
  }
  return {
    xAxis,
    series: [
      {
        name: "Spend",
        insightsType: InsightsCardTypeEnum.spend,
        data: y1Axis
      },
      {
        name: "CO₂ Equivalent Emissions",
        insightsType: InsightsCardTypeEnum.co2e,
        data: y2Axis
      },
      {
        name: "Carbon Intensity",
        insightsType: InsightsCardTypeEnum.intensity,
        data: y3Axis
      }
    ]
  };
};

const formatGroupItem = (item?: string) => {
  if (!item) return "";
  return groupBrokenValues.includes(item) ? "" : item;
};

export const convertToTabular = (
  groupData: TAnalyticsResponse[],
  summaryData: TAnalyticsData,
  colorCodesList: string[]
): TAnalyticsTabularData[] => {
  const tabularData = groupData
    .map((groupItem, groupIndex) => {
      const { group, data } = groupItem;
      const { co2e, co2Cost, spend, methodology, transactions, suppliers, items, dataQuality, urgencyScore } = data[0];
      const { _name, supplier, item, costCenter, naicsCode, naicsIndustry, naicsSector } = group;

      const naicsIdentifier = naicsSector || naicsIndustry || naicsCode || "n/a";
      const naicsName = groupBrokenValues.includes(naicsIdentifier) ? "Not Mapped" : naicsIdentifier;

      const CO2TonsPercentValue = (co2e * 100) / summaryData.co2e;
      const costPercentValue = (co2Cost * 100) / summaryData.co2Cost;
      const spendPercentValue = (spend * 100) / summaryData.spend;

      const CO2TonsPercent =
        CO2TonsPercentValue >= 1
          ? Math.floor(CO2TonsPercentValue).toString()
          : ((co2e * 100) / summaryData.co2e).toFixed(2);

      const costPercent = costPercentValue >= 1 ? Math.floor(costPercentValue).toString() : costPercentValue.toFixed(2);
      const spendPercent =
        spendPercentValue >= 1 ? Math.floor(spendPercentValue).toString() : spendPercentValue.toFixed(2);

      const carbonIntensity = spend > 0 ? co2e / (spend / MILLIONS_OF_DOLLARS) : 0;

      return {
        id: String(groupIndex),
        CO2Tons: co2e,
        cost: co2Cost,
        spend: spend,
        dataQuality,
        urgencyScore: urgencyScore || 0,
        CO2TonsPercent,
        costPercent,
        spendPercent,
        description: _name || `Unspecified (${naicsName})`,
        supplierId: formatGroupItem(supplier),
        itemId: formatGroupItem(item),
        costCenterId: formatGroupItem(costCenter),
        naicsSectorId: formatGroupItem(naicsSector),
        naicsIndustryId: formatGroupItem(naicsIndustry),
        naicsCodeId: formatGroupItem(naicsCode),
        methodology,
        transactions: transactions || 0,
        suppliers: suppliers || 0,
        items: items || 0,
        carbonIntensity
      };
    })
    .filter(d => !!d);

  const tabularDataWithColors = sortArray(tabularData, "CO2Tons", "desc").map((tabularItem, tabularIndex) => {
    const colorCode = graphColorKey[tabularIndex] || colorCodesList[tabularIndex];
    return { ...tabularItem, colorCode };
  });

  return tabularDataWithColors;
};

export const congregateTailData = (tailData: TAnalyticsChartData[], summary: TAnalyticsData | null) => {
  const CO2Tons = tailData.map(tail => tail.CO2Tons).reduce((prev, curr) => prev + curr, 0);
  const cost = tailData.map(tail => tail.cost).reduce((prev, curr) => prev + curr, 0);
  const spend = tailData.map(tail => tail.spend).reduce((prev, curr) => prev + curr, 0);

  const CO2TonsPercent = Number((CO2Tons / (summary?.co2e || 1)) * 100).toFixed(2);
  const spendPercent = Number((spend / (summary?.spend || 1)) * 100).toFixed(2);
  const costPercent = Number((cost / (summary?.co2Cost || 1)) * 100).toFixed(2);

  return { CO2Tons, cost, spend, CO2TonsPercent, spendPercent, costPercent } as TAnalyticsChartData;
};

// For DonutChart, converting the records and summing up the values after the limit to 'Others' object
export const convertTableDataWithOthers = (
  analyticsData: TAnalyticsTabularData[],
  summary: TAnalyticsData | null,
  colorCodesList: string[]
): { emissionChartData: TAnalyticsChartData[]; spendChartData: TAnalyticsChartData[] } => {
  const dataWithAssignedColors = analyticsData.map((analyticsItem, index) => {
    const colorCode = graphColorKey[index] || colorCodesList[index];
    return { ...analyticsItem, colorCode };
  });

  const mainSpendChunk = dataWithAssignedColors.filter(analyticsItem => Number(analyticsItem.spendPercent) >= 1);
  const tailSpendChunk = dataWithAssignedColors
    .filter(analyticsItem => Number(analyticsItem.spendPercent) < 1)
    .map(tail => ({
      ...tail,
      CO2TonsPercent: Number((tail.CO2Tons / (summary?.co2e || 1)) * 100).toFixed(2),
      spendPercent: Number((tail.spend / (summary?.spend || 1)) * 100).toFixed(2)
    }));

  const spendTailProperties = congregateTailData(tailSpendChunk, summary);

  const spendTailObject = {
    ...spendTailProperties,
    id: "tail",
    colorCode: `#8b5a19`,
    description: "Tail Spend"
  };

  const mainEmissionChunk = dataWithAssignedColors.filter(analyticsItem => Number(analyticsItem.CO2TonsPercent) >= 1);

  const tailEmissionChunk = dataWithAssignedColors
    .filter(analyticsItem => Number(analyticsItem.CO2TonsPercent) < 1)
    .map(tail => ({
      ...tail,
      CO2TonsPercent: Number((tail.CO2Tons / (summary?.co2e || 1)) * 100).toFixed(2),
      spendPercent: Number((tail.spend / (summary?.spend || 1)) * 100).toFixed(2)
    }));

  const emissionTailProperties = congregateTailData(tailEmissionChunk, summary);

  const emissionTailObject = {
    ...emissionTailProperties,
    id: "tail",
    colorCode: `#8b5a19`,
    description: "Tail Emissions"
  };

  return {
    emissionChartData: [...mainEmissionChunk, emissionTailObject],
    spendChartData: [...mainSpendChunk, spendTailObject]
  };
};

export const generateRandomColors = (list: unknown[]) => {
  const placeholderList = Array(list.length).fill(0);
  return placeholderList.map(
    () => "hsl(" + 360 * Math.random() + "," + (30 + 70 * Math.random()) + "%," + (40 + 10 * Math.random()) + "%)"
  );
};

export const mapFilterNamings: MapNamings = {
  group: "Group",
  timeWindow: "Time Window",
  methods: "Methods",
  naicsCodes: "NAICS Codes",
  naicsSectors: "NAICS Sectors",
  naicsIndustries: "NAICS Industries",
  buyspaceId: "Buyspace"
} as const;

export const mapFilterValues: MapNamings = {
  itemName: "Commodity Name",
  item: "Commodity",
  costCenter: "Cost Center",
  naicsSector: "Sector",
  naicsIndustry: "Industry",
  naicsCode: "Commodity Category",
  l12m: "Last 12 Months",
  ytd: "Year to Date",
  ly: "Last Year",
  l30d: "Last 30 Days",
  mtd: "Month to Date",
  lm: "Last Month",
  custom: "Custom",
  SS: "Supplier Specific",
  SB: "Spend Based",
  CB: "Commodity Based"
} as const;

export const calculateCarbonIntensity = (co2e: number, spend: number) =>
  spend ? co2e / (spend / MILLIONS_OF_DOLLARS) : 0;

export const calculateStandardDeviation = (arr: number[]) => {
  if (arr && arr.length === 0) {
    return { sDeviation: 0, mean: 0 };
  }
  const mean = arr.reduce((acc, val) => acc + val, 0) / arr.length;
  const sDeviation = Math.sqrt(
    arr.reduce((acc, val) => acc.concat((val - mean) ** 2), [] as number[]).reduce((acc, val) => acc + val, 0) /
      arr.length
  );
  return { sDeviation, mean };
};

export const calculateRanks = (data: TAnalyticsTabularData[]) => {
  const order = data.map((d, idx) => ({
    ...d,
    order: {
      originalOrder: idx
    }
  }));

  const byCO2e = [...order].sort((a, b) => b.CO2Tons - a.CO2Tons);
  const byCO2eOrder = byCO2e.map((d, idx) => ({ ...d, order: { ...d.order, byCO2eOrder: idx + 1 } }));

  const bySpend = [...byCO2eOrder].sort((a, b) => b.spend - a.spend);
  const bySpendOrder = bySpend.map((d, idx) => ({ ...d, order: { ...d.order, bySpendOrder: idx + 1 } }));

  const byCarbonIntensity = [...bySpendOrder].sort((a, b) => {
    const aValue = calculateCarbonIntensity(a.CO2Tons, a.spend);
    const bValue = calculateCarbonIntensity(b.CO2Tons, b.spend);

    return bValue - aValue;
  });

  const byCarbonIntensityOrder = byCarbonIntensity.map((d, idx) => ({
    ...d,
    carbonIntensity: calculateCarbonIntensity(d.CO2Tons, d.spend),
    order: { ...d.order, byCarbonIntensityOrder: idx + 1 }
  }));

  const originalOrderList = [...byCarbonIntensityOrder].sort((a, b) => a.order.originalOrder - b.order.originalOrder);

  return originalOrderList;
};

export const calculateWeightedMethodologyValue = (methodology: TMethodologyObject) => {
  const isSupplierSpendMax = methodology.ss >= Math.max(methodology.cb, methodology.sb);
  const isCommodityBasedMax = methodology.cb >= Math.max(methodology.ss, methodology.sb);

  if (isSupplierSpendMax) {
    return methodology.ss * 100;
  }

  if (isCommodityBasedMax) {
    return methodology.cb * 10;
  }

  return methodology.sb;
};

export const isPending = (q: TQuestionnaireRequest) =>
  [QuestionnaireRequestStatus.IN_PROGRESS, QuestionnaireRequestStatus.SENT].includes(q.status);

export const isOverdue = (q: TQuestionnaireRequest) => isPending(q) && moment().isAfter(q.respondBy);

export const mapMilestoneStatuses = {
  PENDING: "Pending",
  IN_PROGRESS: "In-Progress",
  DONE: "Done"
};

export const mapMilestoneColors = {
  PENDING: "#D9D9D9",
  IN_PROGRESS: "#FCB954",
  DONE: "#32A752"
};

export const mapMilestoneCardTitleColors = {
  PENDING: "#666666",
  IN_PROGRESS: "#FCB954",
  DONE: "#32A752"
};
