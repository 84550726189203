import styled from "@emotion/styled";
import { BGLogo } from "ui/icons";

export const PublicLayoutWrapper = styled.div`
  min-height: 100vh;
  position: relative;
  padding-bottom: 208px;
  box-sizing: border-box;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.xl}) {
    padding-bottom: 248px;
  }
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.md}) {
    padding-bottom: 360px;
  }
`;

export const StyleFooterPositionWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

export const StyleBackground = styled(BGLogo)`
  position: absolute;
  right: 0px;
  bottom: 0;
  z-index: -1;
  width: auto;
  height: 900px;
  @media only screen and (max-height: 1200px) {
    height: 700px;
  }
  @media only screen and (max-height: 1000px) {
    height: 500px;
  }
`;
