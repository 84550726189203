import { useMemo } from "react";

import { TAnalyticsData, TAnalyticsFilters } from "types";

import { analyticsServiceOptions } from "../analytics.data";
import { convertToTimeSeriesData } from "../analytics.utils";
import { useGetAnalyticsSummaryQuery } from "../hooks";
import { ReportsCardsContainer } from "../styles";
import { ReportsChartCard, ReportsChartProps } from "./ReportsChartCard";
import { ReportsEmissionsChartCard } from "./ReportsEmissionsChartCard";
import { ReportsTotalsCard } from "./ReportsTotalsCard";

type ReportsDashboardProps = { filters: TAnalyticsFilters } & ReportsChartProps & {
    summaryBase?: TAnalyticsData;
    summaryStatistics: { mean: number; sDeviation: number };
  };

export const ReportsDashboard = ({ filters, summaryBase, summaryStatistics, ...rest }: ReportsDashboardProps) => {
  const { data: summaryData } = useGetAnalyticsSummaryQuery(filters, analyticsServiceOptions);

  const seriesData = useMemo(() => convertToTimeSeriesData(summaryData?.data || []), [summaryData]);

  const { buyspaceId } = filters;

  const statistics = {
    transactions: rest?.tableData.summary?.transactions || 0,
    suppliers: rest?.tableData.summary?.suppliers || 0,
    commodities: rest?.tableData.summary?.items || 0,
    sDeviation: rest.tableData.statistics.sDeviation || 0,
    mean: rest.tableData.statistics.mean || 0
  };

  const summary = useMemo(() => {
    const spend = summaryData?.summary?.spend ?? 0;
    const co2e = summaryData?.summary?.co2e ?? 0;
    const co2Cost = summaryData?.summary?.co2Cost ?? 0;

    if (buyspaceId && summaryBase) {
      const baseSpend = summaryBase?.spend;
      const baseCo2e = summaryBase?.co2e;

      return {
        spend,
        co2e,
        co2Cost,
        buyspaceSpendFraction: spend / baseSpend,
        buyspaceCo2eFraction: co2e / baseCo2e
      };
    }

    return {
      spend,
      co2e,
      co2Cost,
      buyspaceSpendFraction: 0,
      buyspaceCo2eFraction: 0
    };
  }, [summaryData, summaryBase, buyspaceId]);

  return (
    <ReportsCardsContainer>
      <ReportsTotalsCard {...summary} statistics={statistics} />
      <ReportsEmissionsChartCard series={seriesData.series} xAxis={seriesData.xAxis} />
      <ReportsChartCard {...rest} />
    </ReportsCardsContainer>
  );
};
