import { ActionIcon, Menu } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useCurrentUser } from "modules/login";
import { UserRoles } from "modules/users/types/UserTypesEnum";
import { IBuyspace } from "types";
import { MenuHamburgerIcon } from "ui/icons";

import { useDeleteBuyspaceMutation } from "../hooks";
import { CenterAlign } from "../styles";
import { BuyspaceProvider } from "./BuyspaceProvider";
import { DeleteBuyspaceModal } from "./DeleteBuyspaceModal";
import { UpdateBuyspaceModal } from "./UpdateBuyspaceModal";

const placeholder = <Menu.Item>No Actions</Menu.Item>;

export const BuyspacesActionCell: React.FC<IBuyspace> = props => {
  const {
    userDetails: { userRole }
  } = useCurrentUser();

  const { id, name } = props;

  const [deleteModalOpened, { open: deleteModalOpen, close: deleteModalClose }] = useDisclosure();
  const [editModalOpened, { open: editModalOpen, close: editModalClose }] = useDisclosure();

  const { mutate } = useDeleteBuyspaceMutation();

  const onDeleteBuyspace = () => {
    mutate({ buyspaceId: id }, { onSuccess: deleteModalClose });
  };

  const menuOptions = [
    {
      label: "Update Buyspace",
      onClick: editModalOpen,
      allowRoles: [UserRoles.INTERNAL_ADMIN.value, UserRoles.INTERNAL_ANALYST.value, UserRoles.ACCOUNT_ADMIN.value]
    },
    {
      label: "Delete Buyspace",
      onClick: deleteModalOpen,
      allowRoles: [UserRoles.INTERNAL_ADMIN.value, UserRoles.INTERNAL_ANALYST.value, UserRoles.ACCOUNT_ADMIN.value]
    }
  ];

  const menu = menuOptions
    .filter(({ allowRoles }) => allowRoles.includes(userRole))
    .map(option => (
      <Menu.Item key={option.label} onClick={option?.onClick}>
        {option.label}
      </Menu.Item>
    ));

  const menuOrPlaceholder = menu.length > 0 ? menu : placeholder;

  return (
    <CenterAlign>
      <Menu position="bottom-end" withinPortal>
        <Menu.Target>
          <ActionIcon variant="transparent" c="gray.8">
            <MenuHamburgerIcon />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>{menuOrPlaceholder}</Menu.Dropdown>
      </Menu>
      <DeleteBuyspaceModal
        name={name}
        opened={deleteModalOpened}
        onClose={deleteModalClose}
        onDelete={onDeleteBuyspace}
      />
      <BuyspaceProvider>
        <UpdateBuyspaceModal opened={editModalOpened} onClose={editModalClose} {...props} />
      </BuyspaceProvider>
    </CenterAlign>
  );
};
