interface User {
  id: string;
  userType: string;
  firstName: string;
  lastName: string;
}
export interface ImportInterface {
  accountName: string;
  id: string;
  createdAt: string;
  accountId: string;
  originatedBy: User | null; // Details of User that originate import
  analyzedBy: User | null; // Details of User that analyzed import
  analyzedOn: Date | string | null;
  approvedBy: User | null; // Details of User that approved import
  approvedOn: Date | string | null;
  filename: string;
  transactionFileLocation: string | null;
  analysisFileLocation: string | null;
  transactions: number;
  suppliers: number;
  items: number;
  status: string;
  rejectedReasonNote: string | null;
  rejectedOn: Date | string | null;
  originatedById: string | null;
  analyzedById: string | null;
  approvedById: string | null;
  rejectedById: string | null;
  sourceFile?: string;
  analyzedFile?: string;
}
export interface IimportFiltersData {
  lastUpdatedMax: Date | null;
  lastUpdatedMin: Date | null;
  accountName: string;
  status: string[];
  dates: string[];
  page: number;
}

export const importFiltersInitialData = {
  lastUpdatedMax: null,
  lastUpdatedMin: null,
  accountName: "",
  status: [],
  dates: [],
  page: 1
};
