import { QuestionnaireValidateReady } from "types";

import { SelectorMap } from "../questionnaire.data";
import { QuestionnaireQuestion } from "../questionnaire.styles";
import QuestionnaireMappingText from "./QuestionnaireMappingText";

type QuestionnaireQuestionsProps = {
  group: string;
  onChange: (response: string | string[], id: string, group: string, loading?: boolean) => void;
  questions: QuestionnaireValidateReady[];
  submitted?: boolean;
};

export const QuestionnaireQuestions = ({ questions, group, onChange, submitted }: QuestionnaireQuestionsProps) => {
  return (
    <div>
      {questions.map(q => {
        const Selector = SelectorMap[q.type];
        return (
          <QuestionnaireQuestion key={q.id}>
            <Selector data={q} group={group} onChange={onChange} submitted={submitted} />
            <QuestionnaireMappingText mapping={q.itemMap} />
          </QuestionnaireQuestion>
        );
      })}
    </div>
  );
};
