import { useContext, useState } from "react";
import { useParams } from "react-router-dom";

import { Button, Text } from "@mantine/core";
import { confirmationModal } from "modules/error/ErrorHandlingModals";
import { ModalBodyMessage, ModalFooter, ModalTitle } from "ui";
import Modal from "ui/overlays/Modal";

import { CommodityContext } from "../context/CommodityContext";
import { useCommodityMergeMutation } from "../hooks";
import { CommodityMergeModalContent } from "./CommodityMergeModalContent";

type CommodityMergeModalProps = {
  onClose: () => void;
  opened: boolean;
};

export const CommodityMergeModal: React.FC<CommodityMergeModalProps> = ({ opened, onClose }) => {
  const { onRowSelectReset, rowSelection } = useContext(CommodityContext);
  const [targetItemId, setTargetItemId] = useState("");

  const { id: accountId } = useParams();

  const onModalClose = () => {
    setTargetItemId("");
    onClose();
  };

  const { mutate: handleCommodityMergeMutation } = useCommodityMergeMutation({
    options: {
      onSuccess: () => {
        onRowSelectReset();
        onTargetChange(null);
        onClose();
      }
    }
  });

  const onTargetChange = (value: string | null) => {
    setTargetItemId(value || "");
  };

  const onCommodityMerge = () => {
    if (accountId) {
      confirmationModal({
        title: <Text c="klp">Confirm Merge Commodities</Text>,
        message: `Merging action is irreversible. Do you want to continue?`,
        onConfirm: () => {
          const mergeIds = rowSelection
            .map(commodity => commodity.id)
            .filter(commodityId => commodityId !== targetItemId);
          handleCommodityMergeMutation({
            accountId,
            payload: {
              mergeItems: mergeIds,
              targetItemId
            }
          });
        }
      });
    }
  };

  return (
    <Modal opened={opened} withCloseButton handleClose={onModalClose}>
      <ModalTitle centered>Merge Commodities</ModalTitle>
      <ModalBodyMessage centered>
        Select one commodity to merge the others into. Once merged, all transactions that reference the other
        commodities will reference the commodity you select here, and the other commodities will be deleted.
      </ModalBodyMessage>
      <CommodityMergeModalContent onChange={onTargetChange} value={targetItemId} />
      <ModalFooter centered>
        <Button variant="subtle" onClick={onModalClose}>
          Cancel
        </Button>
        <Button onClick={onCommodityMerge} disabled={!targetItemId || !accountId}>
          Merge
        </Button>
      </ModalFooter>
    </Modal>
  );
};
