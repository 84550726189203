import { useRef } from "react";

import { notifications } from "@mantine/notifications";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { ResponseError, ResponseType } from "types";

import { deleteCustomField } from "../api";
import { DeleteNotification } from "../components";
import { CustomField } from "../types";

export const useDeleteCustomField = ({ accountId }: { accountId: string }) => {
  const queryClient = useQueryClient();

  const controller = new AbortController();

  const queryKey = [QUERY_KEYS.get_custom_fields, { accountId }];
  const previousData: ResponseType<CustomField[]> | undefined = queryClient.getQueryData(queryKey);

  const undoMutationRef = useRef<() => void>();

  const deleteCustomFieldWithDelay = async ({ customFieldId }: { customFieldId: string }): Promise<string> => {
    return await new Promise((resolve, reject) => {
      const promise = setTimeout(
        async () => {
          await deleteCustomField({ customFieldId });
          return resolve(customFieldId);
        },
        5000,
        { signal: controller.signal }
      );

      const rejectPromise = () => {
        clearTimeout(promise);
        controller.abort();
        reject({ errorMessage: "deletionCanceled" });
      };

      undoMutationRef.current = rejectPromise;
    });
  };

  return useMutation<string, ResponseError, { customFieldId: string }>({
    mutationFn: ({ customFieldId }) => deleteCustomFieldWithDelay({ customFieldId }),
    mutationKey: [QUERY_KEYS.delete_custom_fields],
    onMutate: ({ customFieldId }) => {
      const onUndo = () => {
        undoMutationRef.current && undoMutationRef.current();
        notifications.hide(customFieldId);
      };

      notifications.show({
        id: customFieldId,
        title: "Undo",
        message: <DeleteNotification onUndo={onUndo} />,
        autoClose: 5000,
        color: "klp"
      });

      if (previousData) {
        const updatedData = previousData.data.filter(({ id }) => id !== customFieldId);

        queryClient.setQueryData(queryKey, {
          ...previousData,
          data: updatedData
        });
      }

      return previousData;
    },
    onError: (err, _, previousValue) => {
      if (err.errorMessage === "deletionCanceled") {
        queryClient.setQueryData(queryKey, previousValue);
      }
    }
  });
};
