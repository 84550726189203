import { Button, Grid } from "@mantine/core";
import { Select, TextInput } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import { useCurrentUser } from "modules/login";
import { SuccessIcon } from "ui/icons";

import { customFieldSchema } from "../customFields.utils";
import { useCreateCustomField } from "../hooks";
import { CustomFieldType } from "../types/CustomFieldType.enum";
import { Form, FormErrorText, FormFooter } from "./createCustomFieldForm.styles";

export const CreateCustomFieldForm: React.FC<{ initialAccountId?: string; onClose: () => void }> = ({
  onClose,
  initialAccountId
}) => {
  const form = useForm({
    initialValues: {
      field: "",
      fieldLabel: "",
      type: CustomFieldType.TEXT
    },
    validate: yupResolver(customFieldSchema)
  });

  const {
    userDetails: { accountId }
  } = useCurrentUser();

  const { mutate, isLoading, reset, error } = useCreateCustomField({
    onSuccess: () => {
      notifications.show({
        message: "Custom Field has been successfully created",
        color: "klp",
        icon: <SuccessIcon />
      });
      onClose();
    }
  });

  const onCancel = () => {
    form.reset();
    reset();
    onClose();
  };

  const handleSubmit = (values: typeof form.values) => {
    mutate({ payload: { ...values, accountId: initialAccountId || accountId } });
  };

  return (
    <Form onSubmit={form.onSubmit(handleSubmit)}>
      <Grid columns={12} grow>
        <Grid.Col xs={12} sm={7}>
          <TextInput label="Field ID" {...form.getInputProps("field")} />
        </Grid.Col>
        <Grid.Col xs={12} sm={5}>
          <Select
            label="Data Type"
            data={[
              { label: "Text", value: CustomFieldType.TEXT },
              { label: "Numerical", value: CustomFieldType.NUMERICALL }
            ]}
            {...form.getInputProps("type")}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <TextInput label="Field Label" {...form.getInputProps("fieldLabel")} />
        </Grid.Col>
      </Grid>
      {error && <FormErrorText>{error.response?.data.errorMessage}</FormErrorText>}
      <FormFooter>
        <Button type="button" variant="default" onClick={onCancel} disabled={isLoading}>
          Cancel
        </Button>
        <Button type="submit" loading={isLoading}>
          Save Changes
        </Button>
      </FormFooter>
    </Form>
  );
};
