import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { errorModal } from "modules/error/ErrorHandlingModals";
import { ErrorResponse } from "types";

import { getFeatureFlagsService } from "../api";

export const useGetFeatureFlagsQuery = () =>
  useQuery({
    queryKey: [QUERY_KEYS.get_feature_flag_list],
    queryFn: getFeatureFlagsService,
    onError: (error: ErrorResponse) => errorModal(error),
    staleTime: Infinity,
    useErrorBoundary: true
  });
