import eatnpark from "assets/eatnpark.png";
import ggp from "assets/ggp.png";
import inari from "assets/inari.png";
import lsm from "assets/lsm.png";
import microsoft from "assets/microsoft.png";
import nasdaq from "assets/nasdaq.png";
import pact from "assets/pact.png";
import piaaca from "assets/piaaca.avif";
import pittsburgh from "assets/pittsburgh.png";
import splc from "assets/splc.png";
import spp from "assets/spp.png";
import wbcsd from "assets/wbcsd.png";

import { LogoRow } from "./Footer.styles";

export const FooterLogoRow = () => (
  <LogoRow>
    <div>
      <img src={eatnpark} />
    </div>
    <div>
      <img src={ggp} />
    </div>
    <div>
      <img src={inari} />
    </div>
    <div>
      <img src={microsoft} />
    </div>
    <div>
      <img src={pact} />
    </div>
    <div>
      <img src={lsm} />
    </div>
    <div>
      <img src={nasdaq} />
    </div>
    <div>
      <img src={piaaca} />
    </div>
    <div>
      <img src={pittsburgh} />
    </div>
    <div>
      <img src={splc} />
    </div>
    <div>
      <img src={spp} />
    </div>
    <div>
      <img src={wbcsd} />
    </div>
  </LogoRow>
);
