import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";

import { getFeatureFlagsAccountUserService } from "../api/getFeatureFlagsAccountService";

export const useGetFeatureFlagsAccountUserQuery = (apiArgs: { accountId: string; userId: string }) => {
  const { accountId, userId } = apiArgs;
  return useQuery({
    queryKey: [QUERY_KEYS.get_feature_flag_list_account_user, apiArgs],
    queryFn: () => getFeatureFlagsAccountUserService(accountId, userId),
    staleTime: Infinity,
    enabled: Boolean(accountId) && Boolean(userId)
  });
};
