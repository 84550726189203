import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { Button, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useGetCustomFields } from "modules/customFields";
import { CreateCustomFieldForm } from "modules/customFields/components/CreateCustomFieldForm";
import { confirmationModal } from "modules/error/ErrorHandlingModals";
import { useReuseTransactionMapping, useUpdateTransactionMetaMutation } from "modules/imports/hooks";
import { useCurrentUser } from "modules/login";
import { parse } from "papaparse";
import { ModalBodyMessage, ModalFooter, ModalTitle, Table } from "ui";
import Modal from "ui/overlays/Modal";

import { ImportHeaderType } from "../../types";
import { useImportContext } from "../ImportContext";
import { ImportMapModalContext } from "./ImportMapModalContext";
import { columns } from "./columns";
import { requiredKloopifyFields } from "./importMapModal.data";
import { BottomDangerText, DangerText, TableContainer } from "./importMapModal.styles";
import { MappedColumn } from "./importMapModal.types";

export const ImportMapModal = () => {
  const { state, onUploadFile, onModalClose, onSelectedAccountId, file, selectedAccountId, currentImportId } =
    useImportContext();

  const [name, setName] = useState("");

  const {
    userDetails: { accountId }
  } = useCurrentUser();

  const [opened, { close, open: onCreateCustomField }] = useDisclosure();

  const [headers, setHeaders] = useState<ImportHeaderType[]>([]);

  const onUpdateTransactionMeta = useUpdateTransactionMetaMutation({ importId: currentImportId as string });

  const handleReuse = useReuseTransactionMapping();

  useEffect(() => {
    if (file) {
      setName(file.name);
      parse<string[]>(file, {
        preview: 10,
        complete: results => {
          const newHeaders = results.data[0];

          const samples: string[][] = [];

          results.data.forEach((value, index) => {
            if (index > 0 && index <= 5) {
              samples.push(value);
              return;
            }
            return;
          });

          const extendNewHeadersWithValue = newHeaders.map((newHeader, idx) => ({
            csvHeaderKey: newHeader,
            kloopifyFieldKey: null,
            samples: samples.map(value => value[idx]),
            error: false
          }));
          setHeaders(extendNewHeadersWithValue);
        }
      });
    }
    return () => {
      setHeaders([]);
      setName("");
    };
  }, [file]);

  useEffect(() => {
    if (state.open === "mapTransactionFile") {
      handleReuse(headers, newHeaders =>
        confirmationModal({
          title: <Text c="klp">Reuse Previous Transaction Mapping?</Text>,
          message: `We found a Transaction Map that matches your upload file, do you want to re-use the same map?`,
          buttonConfig: {
            cancelText: "No, I will map manually",
            confirmText: "Yes, Reuse Mapping"
          },
          onConfirm: () => setHeaders(newHeaders)
        })
      );
    }
  }, [state.open]);

  const memoizedHeaders = useMemo(() => headers.map(header => header), [headers]);

  const onChange = (csvHeaderKey: string, kloopifyFieldKey: string) => {
    if (kloopifyFieldKey === "newCustomField") {
      onCreateCustomField();
      return;
    }

    setHeaders(state => {
      // First, find if the kloopifyFieldKey is assigned to the csvHeaderKey
      // If yes, remove value and assign to the currently selected
      // (One-to-one mapping)

      let currentState = [...state];

      const isCurrentlyAssignedIndex = state.findIndex(
        stateCsvHeader => kloopifyFieldKey === stateCsvHeader.kloopifyFieldKey
      );

      if (isCurrentlyAssignedIndex !== -1) {
        const removeValueFromMappedArray = currentState.map((s, idx) => {
          if (idx === isCurrentlyAssignedIndex) {
            return { ...s, kloopifyFieldKey: null };
          }
          return s;
        });

        currentState = removeValueFromMappedArray;
      }

      // Now, update the csvHeaderKey with a new value

      const fieldIndex = currentState.findIndex(stateCsvHeader => stateCsvHeader.csvHeaderKey === csvHeaderKey);

      if (fieldIndex !== -1) {
        return currentState.map((s, idx) => {
          if (idx === fieldIndex) {
            return { ...s, kloopifyFieldKey };
          }
          return s;
        });
      }
      return state;
    });
  };

  const onError = (kloopifyFieldKey: string, error: boolean) => {
    setHeaders(prev => {
      const currentState = [...prev];

      const fieldIndex = currentState.findIndex(stateCsvHeader => kloopifyFieldKey === stateCsvHeader.kloopifyFieldKey);

      if (fieldIndex !== -1) {
        return currentState.map((s, idx) => {
          if (idx === fieldIndex) {
            return { ...s, error };
          }
          return s;
        });
      }

      return prev;
    });
  };

  const table = useReactTable({
    data: memoizedHeaders,
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  const onClose = () => {
    onModalClose();
    onUploadFile(undefined);
    onSelectedAccountId(undefined);
  };

  const handleSubmit = async () => {
    const columns = headers
      .filter(({ kloopifyFieldKey }) => kloopifyFieldKey !== null)
      .map(({ kloopifyFieldKey, csvHeaderKey }) => ({
        target: kloopifyFieldKey,
        source: csvHeaderKey
      })) as MappedColumn[];

    if (currentImportId) {
      await onUpdateTransactionMeta.mutateAsync({ importId: currentImportId, payload: { columns } });
    }

    onClose();
  };

  const assignedFields = headers.map(({ kloopifyFieldKey }) => kloopifyFieldKey).filter(fieldKey => fieldKey);

  const errorMissingFields = requiredKloopifyFields
    .filter(requiredField => !assignedFields.includes(requiredField.field))
    .map(({ label }) => label)
    .join(", ");

  const invalidFields = headers.filter(({ error }) => error).map(({ kloopifyFieldKey }) => kloopifyFieldKey);

  const requiredFieldsErrors =
    requiredKloopifyFields
      .filter(requiredField => invalidFields.includes(requiredField.field))
      .map(({ label }) => label) || [];

  const requiredHeaders = requiredKloopifyFields.map(({ label }) => label);

  const { data: customFieldData } = useGetCustomFields({ accountId: selectedAccountId as string });

  const customInvalidFieldsErrors =
    invalidFields
      .filter(invalidField => invalidField && !requiredHeaders.includes(invalidField))
      .map(customFieldId => {
        const findCustomField = customFieldData?.data?.find(customField => customFieldId === customField.id);
        if (findCustomField) {
          return findCustomField.fieldLabel || findCustomField.field;
        }
        return null;
      })
      .filter(customFieldName => customFieldName) || [];

  const errorInvalidFields = [...requiredFieldsErrors, ...customInvalidFieldsErrors].join(", ");

  const disabled = !!errorInvalidFields || !!errorMissingFields;

  return (
    <Modal
      opened={state.open === "mapTransactionFile"}
      withCloseButton
      handleClose={onClose}
      mode="big"
      modalProps={{ closeOnClickOutside: false }}
    >
      <ModalTitle centered>
        {name || "Your Transaction File"} <br /> has been submitted!
      </ModalTitle>
      <ModalBodyMessage centered>
        Kloopify matches your transactions with five required fields: Transaction Date (compatible with all formats),
        Supplier Name, Commodity Name, Cost Center Name (i.e., Business Unit), and Total Cost. Simply match your CSV
        file's columns to these Kloopify fields below. You may also create or use existing Custom Fields to allow
        analytics on additional fields beyond the five above. View more info on the{" "}
        <Link to={`/account/${accountId}/settings`}>Account Settings</Link> page.
      </ModalBodyMessage>
      <ModalBodyMessage centered>
        <DangerText>
          {errorMissingFields &&
            `${errorMissingFields} remaining to be mapped. Please use
          the dropdown below and map it to a File Field.`}
        </DangerText>
      </ModalBodyMessage>
      <ImportMapModalContext.Provider value={{ headers, onChange, onCreateCustomField, onError }}>
        <TableContainer>
          <Table<ImportHeaderType> table={table} />
        </TableContainer>
      </ImportMapModalContext.Provider>
      <BottomDangerText>
        {errorInvalidFields && `*Invalid Format for ${errorInvalidFields}, Please fix to continue.`}
      </BottomDangerText>
      <ModalFooter centered>
        <Button size="lg" disabled={disabled} onClick={handleSubmit} loading={onUpdateTransactionMeta.isLoading}>
          Submit
        </Button>
      </ModalFooter>
      <Modal opened={opened} withCloseButton handleClose={close}>
        <ModalTitle centered>New Custom Field</ModalTitle>
        <CreateCustomFieldForm onClose={close} initialAccountId={selectedAccountId} />
      </Modal>
    </Modal>
  );
};
