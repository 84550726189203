import { Dispatch, FC, SetStateAction, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { Button, Select } from "@mantine/core";
import { groupBy } from "lodash";
import { dateFilterConfig, dateFilterMap, defaultDateFilter, ReportsIntervalModal } from "modules/analytics";
import { InsightsDataQualityCell } from "modules/analytics/components/InsightsDataQualityCell";
import { useGetClassificationListQueries } from "modules/classification";
import { useGetQuestionnaireRequestListQuery } from "modules/questionnaire";
import { ISupplier } from "modules/suppliers/suppliers.types";
import { IndustryClassification, TAnalyticsCustomInterval, TAnalyticsData } from "types";
import { BoxIcon, CalendarIcon, UserIcon } from "ui/icons";
import { fitElement, formatDateStringFull } from "utils";
import { getCountryByIso, getStateByIso } from "utils/input.utils";
import { stringifyQueryParams } from "utils/query.utils";

import { useSupplierDetailsContext } from "../SupplierDetails.context";
import { defaultAnalyticsFilters, QuestionnaireStatusMap } from "../supplierDetails.data";
import {
  HiddenBox,
  StyledDetail,
  StyledDetailsContainer,
  StyledDetailsWrapper,
  StyledDetailValue,
  StyledRedirectButtonsWrapper
} from "../supplierDetails.styles";
import { SupplierMethodDetail } from "./SupplierMethodInformation";

type SupplierInformationProps = {
  showTransactionButton?: boolean;
  summary: TAnalyticsData;
  supplierData: ISupplier;
};

export const SupplierInformation: FC<SupplierInformationProps> = ({ supplierData, summary }) => {
  const { region, city, country, createdAt, description, naicsCode } = supplierData;

  const { modal, modalController } = useSupplierDetailsContext();

  const [searchParams, setSearchParams] = useSearchParams();

  const codes = useGetClassificationListQueries({ codes: naicsCode ? [naicsCode] : [] });

  const questionnaireRequestListQuery = useGetQuestionnaireRequestListQuery({
    pathArgs: {
      accountId: supplierData.account.id,
      supplierId: supplierData.id
    },
    apiArgs: {
      page: 0,
      limit: 99999999
    }
  });

  const naicsCodes = codes
    .map(({ data }) => {
      const codeData = data?.data[0] as IndustryClassification;
      return codeData;
    })
    .filter(data => data);

  const transactionRedirectLink = useMemo(() => {
    const filters = stringifyQueryParams({ supplierIds: [supplierData.id] });
    return `/transactions/${supplierData.account.id}?${filters}`;
  }, [supplierData]);

  const commoditiesRedirectLink = useMemo(() => {
    const filters = stringifyQueryParams({ supplierIds: [supplierData.id] });
    return `/commodities/${supplierData.account.id}?${filters}`;
  }, [supplierData]);

  const renderClassifications = useMemo(() => {
    const renderCodes = naicsCodes.map(({ code, title }) => `${title} (${code})`).join(", ");
    return (
      <StyledDetail>
        <UserIcon /> Classifications:
        <StyledDetailValue>{renderCodes}</StyledDetailValue>
      </StyledDetail>
    );
  }, [naicsCodes]);

  const renderLocation = useMemo(() => {
    const fullCountry = getCountryByIso(country);
    const fullState = getStateByIso(region, country);
    const combinedLocation = [fullState, city, fullCountry].filter(i => i).join(", ");
    const location = combinedLocation.length ? combinedLocation : "Not Specified";
    return (
      <StyledDetail>
        <BoxIcon /> Location:
        <StyledDetailValue italic={!combinedLocation.length}>{location}</StyledDetailValue>
      </StyledDetail>
    );
  }, [region, city, country]);

  const renderLastUpdate = useMemo(
    () => (
      <StyledDetail>
        <CalendarIcon /> Last Update:
        <StyledDetailValue>{formatDateStringFull(createdAt)}</StyledDetailValue>
      </StyledDetail>
    ),
    [createdAt]
  );

  const renderSupplierCreated = useMemo(
    () => (
      <StyledDetail>
        Supplier Created:
        <StyledDetailValue>{formatDateStringFull(createdAt)}</StyledDetailValue>
      </StyledDetail>
    ),
    [createdAt]
  );

  const renderDescription = useMemo(() => {
    const showDescription = !!description;
    return (
      <StyledDetail>
        Description:
        <StyledDetailValue italic={!showDescription}>
          {showDescription ? description : "Not Specified"}
        </StyledDetailValue>
      </StyledDetail>
    );
  }, [description]);

  const renderDataSourceQuality = useMemo(
    () => (
      <StyledDetail>
        <HiddenBox>
          <CalendarIcon />
        </HiddenBox>{" "}
        Data Source Quality:
        <StyledDetailValue>
          <InsightsDataQualityCell {...summary} sm />
        </StyledDetailValue>
      </StyledDetail>
    ),
    [summary]
  );

  const renderQuestionnaire = useMemo(() => {
    const list = questionnaireRequestListQuery.data?.data || [];

    if (!list.length) return null;

    const normalizedList = list.map(i => ({ ...i, status: QuestionnaireStatusMap[i.status] }));

    const grouped = groupBy(normalizedList, "status");

    const info = Object.entries(grouped)
      .reduce((prev: string[], acc) => [...prev, acc[1].length + " " + acc[0]], [])
      .join(", ");

    return (
      <StyledDetail>
        <HiddenBox>
          <CalendarIcon />
        </HiddenBox>{" "}
        Questionnaire:
        <StyledDetailValue link onClick={() => fitElement("questionnaireTableHeader")}>
          {info} Requests
        </StyledDetailValue>
      </StyledDetail>
    );
  }, [questionnaireRequestListQuery]);

  const onChangeTimeWindow = (timeWindow: string) => {
    switch (timeWindow) {
      case "custom": {
        modalController({ customInterval: true });
        return;
      }
      case "mry": {
        setSearchParams(prev => ({ ...prev }));
        return;
      }
      default: {
        const selected = dateFilterMap.get(timeWindow) || defaultDateFilter;
        const { start, end } = selected;
        setSearchParams(prev => ({ ...prev, start, end, timeWindow }));
      }
    }
  };

  const updateCustomTimeRange: Dispatch<SetStateAction<TAnalyticsCustomInterval>> = updater => {
    const value = typeof updater === "function" ? updater({ start: null, end: null }) : updater;

    if (!value.start && !value.end) return setSearchParams(prev => ({ ...prev }));

    searchParams.set("timeWindow", "custom");

    Object.entries(value).forEach(i => {
      const [key, value] = i;
      if (value) {
        searchParams.set(key, value);
      } else {
        searchParams.delete(key);
      }
    });

    setSearchParams(searchParams);
  };

  return (
    <>
      <StyledDetailsContainer>
        <StyledDetailsWrapper>
          <div>
            {renderClassifications}
            {renderLocation}
            {renderLastUpdate}
            {renderQuestionnaire}
            {renderDataSourceQuality}
          </div>
          <div>
            {renderSupplierCreated}
            {renderDescription}
            <SupplierMethodDetail methodology={summary.methodology} />
          </div>
        </StyledDetailsWrapper>
        <StyledRedirectButtonsWrapper>
          <Select
            data={dateFilterConfig}
            value={searchParams.get("timeWindow") || defaultAnalyticsFilters.timeWindow}
            onChange={onChangeTimeWindow}
            size="lg"
          />
          <a href={transactionRedirectLink}>
            <Button variant="outline" size="lg" w="100%">
              Transactions
            </Button>
          </a>
          <a href={commoditiesRedirectLink}>
            <Button variant="outline" size="lg" w="100%">
              Commodities
            </Button>
          </a>
        </StyledRedirectButtonsWrapper>
      </StyledDetailsContainer>
      <ReportsIntervalModal
        opened={modal.customInterval}
        setModal={() => modalController({ customInterval: false })}
        interval={
          searchParams.get("timeWindow") === "custom"
            ? { start: searchParams.get("start"), end: searchParams.get("end") }
            : { start: null, end: null }
        }
        setCustomInterval={updateCustomTimeRange}
      />
    </>
  );
};
