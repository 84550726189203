import { useContext } from "react";

import { useQueries } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { CurrentUserContext } from "modules/login";
import { stringifyQueryParams } from "utils";

import { getImportTransactionMetaService } from "../api";
import { useImportContext } from "../components/ImportContext";
import { ImportHeaderType, ImportInterface } from "../types";
import { hasAllKeys } from "../utils";
import { useGetUserImportsQuery } from "./useGetUserImportsQuery";

export const useReuseTransactionMapping = () => {
  const { userDetails } = useContext(CurrentUserContext);

  const { selectedAccountId } = useImportContext();

  const importQuery = stringifyQueryParams({ accountId: [selectedAccountId] });

  const { data = { data: [] } } = useGetUserImportsQuery(0, 3, userDetails.userType, importQuery);

  const queries = useQueries({
    queries: data?.data?.map((d: ImportInterface, idx) => {
      return {
        queryKey: [QUERY_KEYS.import_transaction_meta, d.id, idx],
        queryFn: () => getImportTransactionMetaService(d.id),
        enabled: Boolean(selectedAccountId)
      };
    })
  });

  const findMatchingHeaders = (headers: ImportHeaderType[], onSuccess: (newHeaders: ImportHeaderType[]) => void) => {
    const metaColumns = queries.map(d => d.data?.data.columns);
    const metaSource = headers.map(header => header.csvHeaderKey);

    const reusableDataIndexes: number[] = [];

    metaColumns.forEach((metaColumn, index) => {
      const metaColumnKeys = metaColumn?.map(metaColumnKey => metaColumnKey.source) || [];

      const hasAllColumns = hasAllKeys(metaSource, metaColumnKeys);

      if (hasAllColumns) {
        reusableDataIndexes.push(index);
      }
    });

    const latest = Math.min(...reusableDataIndexes);

    if (reusableDataIndexes.length) {
      const previousData = metaColumns[latest];

      if (previousData) {
        const kloopifyMappedValues: Record<string, string> = previousData.reduce(
          (prev, curr) => ({ ...prev, [curr.source as string]: curr.target }),
          {}
        );

        const newHeaders = headers.map(header => ({
          ...header,
          kloopifyFieldKey: kloopifyMappedValues?.[header.csvHeaderKey as string] || null
        }));

        onSuccess(newHeaders);
      }
    }
  };

  return findMatchingHeaders;
};
