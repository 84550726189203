import { useContext, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";

import { LoadingOverlay } from "@mantine/core";
import { graphColorKey } from "constants/colorPalette";
import ErrorHandler from "modules/error/ErrorHandler";
import { CurrentUserContext } from "modules/login/CurrentUserContext";
import { UserAvatar } from "modules/myProfile/myProfile.styles";
import { useGetUserInfo } from "modules/users";
import { TUserAccount } from "types";
import Loader from "ui/feedback/Loader";
import { CancelIcon } from "ui/icons";

import {
  AccountLabel,
  AccountSideIcon,
  CenterContainer,
  DescriptionText,
  HeadBox,
  HeadBoxWrapper,
  HeaderText,
  IconLabelWrapper,
  IconLeft,
  ListingWrapper,
  LoaderWrapper,
  PolygonArrow,
  SelectAccountLabel,
  StyleAccountList,
  StyleUserProfileImageWrapper,
  ToolTipWrapper,
  UserEmail,
  UserEmailWithAccount,
  UserName,
  WelcomeMessage,
  Wrapper,
  WrapperStyle
} from "./AccountSelection.styles";

export const AccountSelection = () => {
  const navigate = useNavigate();

  const { userDetails, setUserDetails } = useContext(CurrentUserContext);

  const [accountList, setAccountList] = useState<TUserAccount[]>([]);

  const { data, isSuccess, isLoading, fetchStatus } = useGetUserInfo(userDetails.userId, false);

  /**
   * Selects user account by updating localStorage and app state with new account data
   */
  const selectAccount = (account: TUserAccount) => {
    const { accountId, accountName, role } = account;

    setUserDetails({ ...userDetails, accountName, accountId, userRole: role });

    localStorage.setItem("accountId", accountId);

    localStorage.setItem("accountName", accountName ?? "");

    navigate(`/analysis/${accountId}/insights`, { replace: true });
  };

  /**
   * Lets user to select account if there are more than 1 account
   */
  useEffect(() => {
    if (!isSuccess) return;

    if (data.data.accounts.length > 1) return setAccountList(data.data.accounts);

    selectAccount(data.data.accounts[0]);
  }, [isSuccess, data]);

  if (fetchStatus === "fetching") return <LoadingOverlay visible overlayBlur={2} loader={<Loader />} />;

  return (
    <CenterContainer>
      <Wrapper>
        <HeadBoxWrapper>
          <HeadBox>
            <WelcomeMessage>Hi {userDetails.firstName}</WelcomeMessage>
            <HeaderText>Welcome back to Kloopify! </HeaderText>
            <DescriptionText>
              Select which organization’s Kloopify account you would like to continue to below.
            </DescriptionText>
            <StyleUserProfileImageWrapper>
              <UserAvatar src={userDetails.image} />
            </StyleUserProfileImageWrapper>
            <UserName>
              {userDetails.firstName} {userDetails.lastName}
            </UserName>
            {accountList?.length === 0 && !isLoading ? (
              <UserEmail>
                <div>{userDetails.email}</div>
                <ToolTipWrapper>
                  <WrapperStyle>
                    <CancelIcon />
                    <PolygonArrow />
                  </WrapperStyle>
                </ToolTipWrapper>
              </UserEmail>
            ) : (
              <UserEmailWithAccount>{userDetails.email}</UserEmailWithAccount>
            )}
          </HeadBox>
          {accountList.length > 0 && (
            <>
              <SelectAccountLabel>Choose an account:</SelectAccountLabel>
              {accountList.length ? (
                <ErrorBoundary FallbackComponent={ErrorHandler}>
                  <ListingWrapper>
                    {accountList.map((account: TUserAccount, index: number) => (
                      <StyleAccountList key={index} onClick={() => selectAccount(account)}>
                        <IconLabelWrapper>
                          <IconLeft style={{ backgroundColor: graphColorKey[index] }} />
                          <AccountLabel>{account.accountName}</AccountLabel>
                        </IconLabelWrapper>
                        <AccountSideIcon />
                      </StyleAccountList>
                    ))}
                  </ListingWrapper>
                </ErrorBoundary>
              ) : (
                <LoaderWrapper>
                  <Loader />
                </LoaderWrapper>
              )}
            </>
          )}
        </HeadBoxWrapper>
      </Wrapper>
    </CenterContainer>
  );
};
