import { useMemo } from "react";

import { LoadingOverlay } from "@mantine/core";
import {
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
  SortingState,
  Updater,
  useReactTable
} from "@tanstack/react-table";
import { TAnalyticsFilters, TAnalyticsTableData, TReportsDataSource } from "types";
import { Table } from "ui";
import Loader from "ui/feedback/Loader";
import Pagination from "ui/navigation/Pagination";

import { limit } from "../analytics.data";
import { calculateRanks, calculateWeightedMethodologyValue } from "../analytics.utils";
import { NoResultsFound, ReportsTableContainer, TablePaginationContainer, TableWrapper } from "../styles";
import { columns } from "./columns";

type ReportsTableProps = {
  filters: TAnalyticsFilters;
  isLoading: boolean;
  page: number;
  selectedGroup: { from: "chart" | "table"; id: string | null };
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setSelectedGroup: React.Dispatch<React.SetStateAction<{ from: "chart" | "table"; id: string | null }>>;
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
  sorting: SortingState;
  tableData: TAnalyticsTableData;
};

export const ReportsTable = (props: ReportsTableProps) => {
  const { filters, isLoading, page, setPage, selectedGroup, setSelectedGroup, tableData, sorting, setSorting } = props;

  const reportsTableData = useMemo(
    () =>
      calculateRanks(
        tableData.standard?.map(tableItem => {
          const { CO2TonsPercent, id } = tableItem;
          const group = Number(CO2TonsPercent) < 1 ? "Tail Spend" : id;
          const _analysisValue = calculateWeightedMethodologyValue(tableItem.methodology);
          return { ...tableItem, group, _analysisValue };
        })
      ),
    [tableData]
  );

  const onSortingChange = (updater: Updater<SortingState>) => {
    setPage(0);
    setSorting(prevSorting => {
      if (typeof updater === "function") {
        const newSorting = updater(prevSorting);
        return newSorting;
      }
      return updater;
    });
  };

  const table = useReactTable({
    data: reportsTableData,
    columns: columns.map(column => ({
      ...column,
      accessorFn: column.id === "nameCell" ? row => ({ row, filters }) : undefined
    })),
    meta: {
      activeRow: selectedGroup.id
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    pageCount: Math.ceil(reportsTableData.length / limit),
    getRowId: row => row.id.toString(),
    autoResetPageIndex: true,
    onSortingChange,
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: limit
      },
      sorting: [
        {
          id: "CO2Tons",
          desc: true
        }
      ]
    },
    state: {
      sorting
    }
  });

  const onPageChange = (page: number) => {
    setPage(page);
    table.setPageIndex(page);
  };

  const onRowEnter = (row: Row<TReportsDataSource>) => {
    if (row.original.id) setSelectedGroup({ id: row.original.id.toString(), from: "table" });
  };

  const onRowLeave = () => setSelectedGroup({ id: null, from: "table" });

  return (
    <ReportsTableContainer>
      <LoadingOverlay visible={isLoading} overlayBlur={2} loader={<Loader />} zIndex={1} />
      {tableData.standard?.length ? (
        <>
          <TablePaginationContainer>
            <TableWrapper>
              <Table<TReportsDataSource>
                table={table}
                enableActiveRowDisplay
                onRow={{
                  onMouseOver: onRowEnter,
                  onMouseLeave: onRowLeave
                }}
              />
            </TableWrapper>
            <Pagination
              activePage={page + 1}
              setPage={(page: number) => onPageChange(page - 1)}
              totalRecords={tableData.standard.length}
              limit={limit}
              centered
            />
          </TablePaginationContainer>
        </>
      ) : (
        <NoResultsFound>No Result Found</NoResultsFound>
      )}
    </ReportsTableContainer>
  );
};
