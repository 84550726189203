import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { getMilestoneListService } from "services";

export const useGetMilestoneListQuery = ({ accountId }: { accountId: string }) =>
  useQuery({
    queryKey: [QUERY_KEYS.get_milestone_list, { accountId }],
    queryFn: () => getMilestoneListService({ accountId }),
    enabled: !!accountId,
    select: data => {
      return data.data.milestones || [];
    }
  });
