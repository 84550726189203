import styled from "@emotion/styled";
import { rem } from "@mantine/core";

export const ReportsCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: auto;
  grid-gap: 38px;
  padding: 29px 0 38px 0;

  grid-template-areas: "totals emissions chart";

  @media screen and (max-width: 1440px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-areas:
      "totals totals emissions emissions"
      "chart chart chart chart";
  }

  @media screen and (max-width: 950px) {
    grid-template-areas:
      "totals totals totals totals"
      "emissions emissions emissions emissions"
      "chart chart chart chart";
  }
`;

export const CardContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1 0;
  width: 100%;

  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 24px 35px;
  height: 238px;
`;

export const ReportsTotalsCardContainer = styled(CardContainer)`
  grid-area: totals;
`;

export const ReportsEmissionsChartContainer = styled(CardContainer)`
  flex-direction: column;
  grid-area: emissions;
  justify-content: space-between;
  padding-bottom: ${rem(12)};
`;

export const ReportsChartCardContainer = styled(CardContainer)`
  justify-content: space-evenly;
  grid-area: chart;

  @media screen and (max-width: 560px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: unset;
  }
`;

export const ReportsTotalGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ReportsTotalGroupLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: ${props => props.theme.colors.klp[4]};
`;

export const ReportsTableContainer = styled.div`
  display: flex;
  gap: 40px;
  height: calc(100% - 300px);
  position: relative;
  @media only screen and (max-width: 1366px) {
    display: block;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const TablePaginationContainer = styled.div`
  height: 100%;
  width: 100%;

  thead > tr {
    border-bottom: 1px solid #e5e5e5;
  }
`;

export const TableWrapper = styled.div`
  position: relative;
  width: 100%;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding-left: 18px;
  overflow: auto;

  th {
    background-color: #fff !important;
    border: 0 !important;
  }

  tbody {
    tr,
    td {
      height: auto;
      border: 0 !important;
    }
  }

  @media only screen and (max-width: 1366px) {
    width: 100%;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  @media only screen and (max-width: 1366px) {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  /* Scroll CSS */
  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(217, 217, 217, 0.2);
    border-radius: 10px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 60px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #d9d9d9;
  }
`;

export const NoResultsFound = styled.div`
  font-weight: 700;
  font-size: 2.34rem;
  line-height: 3.8125rem;
  margin-top: 1rem;
  color: #32a752;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyleDownloadButtonContainer = styled.div`
  @media only screen and (max-width: 1330px) {
    width: 100%;
  }
`;

export const StyleSelectAndButtonsOverview = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;

  @media only screen and (max-width: 1330px) {
    flex-direction: column;
    justify-content: space-around;
    gap: 12px;

    button {
      width: 100%;
    }
  }
`;

export const StyleSelectWrapperOverview = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;

  @media only screen and (max-width: 1330px) {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: repeat(2, 1fr);
  }

  @media screen and (max-width: 950px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
`;

export const StyleLabel = styled.div`
  font-weight: 700;
  color: #404040;
  font-size: 1rem;
  line-height: 1.375rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 7px;
  text-wrap: nowrap;

  svg {
    color: ${({ theme }) => theme.other.primaryColor};
  }

  @media (max-width: 768px) {
    position: sticky;
    left: 0;
  }
`;

export const StyleDonutLabel = styled(StyleLabel)`
  text-align: center;
  justify-content: center;
  margin-top: -0.4rem;
`;

export const RelativeContainer = styled.div`
  position: relative;
`;

export const ReportLayout = styled.div`
  position: relative;
  min-height: 30vh;
`;
