import { useQuery } from "@tanstack/react-query";
import { errorModal } from "modules/error/ErrorHandlingModals";
import axiosInstance from "services/AxiosInstance";
import { ErrorResponse } from "types";

import { TTransactionsResponse } from "../transactions.types";

const getTransactions = async (
  query: Record<string, number | string | string[]>,
  limit: number,
  accountId?: string
) => {
  const response = await axiosInstance.get(`/transactions`, {
    params: { limit, ...(accountId && { accountId }), ...query }
  });
  return response.data;
};

export const useTransactionsQuery = (
  query: Record<string, number | string | string[]>,
  limit: number,
  accountId?: string
): TTransactionsResponse =>
  useQuery({
    queryKey: ["transactions", { ...query }],
    queryFn: () => getTransactions(query, limit, accountId),
    onError: (error: ErrorResponse) => errorModal(error),
    useErrorBoundary: true
  });
