import { FC, useMemo } from "react";
import { useParams } from "react-router-dom";

import { Flex, Grid } from "@mantine/core";
import { MILLIONS_OF_DOLLARS } from "modules/analytics/analytics.utils";
import { renderIntensityStatus } from "modules/analytics/components/renderStatus.util";
import { TAnalyticsData, TReportsDataSource } from "types";
import Card from "ui/data-display/Card";
import { CashLogo, IndustryLogo, PriceTagIcon } from "ui/icons";
import { decimalFormatter, percentageFormatter, prettyMoney } from "utils/formatting.utils";

import { StyledCardsWrapper } from "../supplierDetails.styles";

type Props = {
  accountAnalyticsRanked: (TReportsDataSource & { mean: number; sDeviation: number })[];
  accountSummary: TAnalyticsData;
  supplierSummary: TAnalyticsData;
};

export const SupplierDetailsCards: FC<Props> = ({ supplierSummary, accountSummary, accountAnalyticsRanked }) => {
  const { id } = useParams();

  const { spend: supplierSpend, co2e: supplierCo2e } = supplierSummary;

  const { spend: accountSpend, co2e: accountCo2e } = accountSummary;

  const spendAlpha = accountSpend > 0 ? percentageFormatter(supplierSpend / accountSpend) : "0%";

  const co2eAlpha = accountCo2e > 0 ? percentageFormatter(supplierCo2e / accountCo2e) : "0%";

  const carbonIntensity =
    supplierSpend > 0 ? decimalFormatter(supplierCo2e / (supplierSpend / MILLIONS_OF_DOLLARS)) : "--";

  const carbonIntensityDescription = useMemo(() => {
    const rankedSupplierAnalytics = accountAnalyticsRanked.find(i => i.supplierId === id);

    if (!rankedSupplierAnalytics) return <div>--</div>;

    const rank = rankedSupplierAnalytics.order.byCarbonIntensityOrder;

    const supplierOverallCarbonIntensity =
      rankedSupplierAnalytics.spend === 0
        ? 0
        : rankedSupplierAnalytics.CO2Tons / (rankedSupplierAnalytics.spend / MILLIONS_OF_DOLLARS);

    const status = renderIntensityStatus(
      supplierOverallCarbonIntensity,
      rankedSupplierAnalytics.mean,
      rankedSupplierAnalytics.sDeviation
    );

    return (
      <Flex gap={3} align="center" justify="center">
        #{rank} in Rank, {status}
      </Flex>
    );
  }, [accountAnalyticsRanked]);

  return (
    <StyledCardsWrapper>
      <Grid gutter={16} align="stretch">
        <Grid.Col lg={4} md={12}>
          <Card
            showLabel={false}
            label=""
            labelIcon={null}
            cardName="Spend"
            cardUnit={prettyMoney(supplierSpend)}
            cardIcon={<CashLogo />}
            cardDescription={`${spendAlpha} Of Total`}
            backgroundColor="transparent"
          />
        </Grid.Col>
        <Grid.Col lg={4} md={12}>
          <Card
            showLabel={false}
            label=""
            labelIcon={null}
            cardName="CO₂e Emissions"
            cardUnit={decimalFormatter(supplierCo2e)}
            cardIcon={<IndustryLogo />}
            cardDescription={`${co2eAlpha} Of Total`}
            backgroundColor="transparent"
          />
        </Grid.Col>
        <Grid.Col lg={4} md={12}>
          <Card
            showLabel={false}
            label=""
            labelIcon={null}
            cardName="Carbon Intensity"
            cardUnit={carbonIntensity}
            cardIcon={<PriceTagIcon />}
            cardDescription={carbonIntensityDescription}
            backgroundColor="transparent"
          />
        </Grid.Col>
      </Grid>
    </StyledCardsWrapper>
  );
};
