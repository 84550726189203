import { useTheme } from "@emotion/react";
import { Box, Text } from "@mantine/core";

import { TabWrapper } from "./privateLayoutSubHeader.styles";

export const AnalyticsSubNavigation = ({ extra }: { extra: React.ReactNode }) => {
  const {
    other: { monochromeColor }
  } = useTheme();

  return (
    <TabWrapper>
      <Box w="100%">
        <Text size="lg" fw={700} c={monochromeColor[2]} mb="xs">
          Filters
        </Text>
        {extra}
      </Box>
    </TabWrapper>
  );
};
