import styled from "@emotion/styled";

export const HeaderContainer = styled.div`
  width: 100%;
  background-color: transparent;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1440px) {
    max-width: 75%;
  }

  @media only screen and (max-width: 1100px) {
    display: grid;
    justify-content: center;
  }
`;

export const HeaderWrapper = styled.div`
  max-width: 1366px;
  width: 100%;
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: ${props => props.theme.other.font.header2.fontWeight};
  padding-top: ${props => props.theme.other.font.header2.paddingTop};
  padding-bottom: ${props => props.theme.other.font.header2.paddingBottom};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 6px;
`;

export const AccountNameContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 13px;
  font-size: ${props => props.theme.other.font.header2.fontSize};
  line-height: ${props => props.theme.other.font.header2.lineHeight};
  color: ${props => props.theme.other.primaryColor};
  font-weight: ${props => props.theme.other.font.header2.fontWeight};
`;

export const PageDescription = styled.div`
  width: 100%;
  font-size: 1.5rem;
  line-height: ${props => props.theme.other.font.header3.lineHeight};
  color: ${props => props.theme.other.monochromeColor[2]};
  font-weight: 400;

  @media only screen and (max-width: 1100px) {
    text-align: center;
  }
`;

export const TabWrapper = styled.div`
  max-width: 1366px;
  min-height: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 1440px) {
    width: auto;
    flex-wrap: wrap;
    justify-content: flex-start;
    & > div:first-of-type {
      flex: 1;
      width: 100%;
    }
  }

  @media only screen and (max-width: 1000px) {
    & > div:nth-of-type(2) {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
`;
