import { useCallback, useContext, useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";

import { CurrentUserContext } from "modules/login";
import { dropdownRoutesConfig, routesConfig } from "router";
import { RoutesConfig } from "router/types";
import { Feature } from "types";

export const useRoutesNavigation = () => {
  const { id } = useParams();
  const { pathname } = useLocation();

  const { userDetails } = useContext(CurrentUserContext);

  const getAccountId = useCallback(() => {
    if (pathname.includes("commodity/") || pathname.includes("supplier/")) {
      return userDetails.accountId;
    }
    return id || userDetails.accountId;
  }, [id, userDetails.accountId, pathname]);

  const accountId = getAccountId();

  const nestedAccountRestrictedFeatureList = useMemo(
    () =>
      userDetails.featureFlags.map(i => {
        const accountPermissionList = i[accountId];
        if (!accountPermissionList) return [];
        return accountPermissionList
          .map(permission => {
            const [featureName, enabled] = Object.entries(permission)[0];
            return enabled ? null : featureName;
          })
          .filter(a => a) as NonNullable<Feature[]>;
      }),
    [userDetails.featureFlags]
  );

  const accountRestrictedFeatureList = useMemo(
    () => ([] as Feature[]).concat(...nestedAccountRestrictedFeatureList),
    [nestedAccountRestrictedFeatureList]
  );

  const transformRoutesToAllowed = (routes: RoutesConfig[]) =>
    routes.filter(({ allowRoles, restrictFeatures }) => {
      const isAllowedRole = allowRoles.includes(userDetails?.userRole);

      const isRestrictedRoute = accountRestrictedFeatureList.some((p: Feature) => restrictFeatures.includes(p));

      return isAllowedRole && !isRestrictedRoute;
    });

  const transformRoutesToAccount = (routes: RoutesConfig[]) =>
    routes.map(({ path, name, ...rest }) => ({
      ...rest,
      path: path.replace(":accountId", userDetails?.accountId),
      name: name.replace(":accountName", userDetails?.accountName ?? "Account")
    }));

  const routes = transformRoutesToAccount(transformRoutesToAllowed(routesConfig));

  const dropdownRoutes = dropdownRoutesConfig.map(({ name, routes }) => {
    const allowedRoutes = transformRoutesToAllowed(routes);

    const allowedAccountRoutes = transformRoutesToAccount(allowedRoutes);

    return { name, routes: allowedAccountRoutes };
  });

  return { tabs: routes, dropdowns: dropdownRoutes };
};
