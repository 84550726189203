import axiosInstance from "services/AxiosInstance";
import { ResponseType } from "types";

type PostUserAuthentificationServiceArgs = (payload: {
  accessToken: string;
  signWith: string;
}) => Promise<ResponseType<{ jwtToken: string }>>;

export const postUserAuthentificationService: PostUserAuthentificationServiceArgs = async payload => {
  const response = await axiosInstance.post(`auth/authenticate`, payload);
  return response.data;
};
