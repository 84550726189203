import { useMemo } from "react";

import { Flex, HoverCard, Rating, Text } from "@mantine/core";
import { monochromeColor, primaryColor, warningColor } from "constants/colorPalette";
import { StyledDetail, StyledDetailValue, StyledMethodWrapper } from "modules/supplierDetails/supplierDetails.styles";
import { TReportsDataSource } from "types";
import { formatPercentagePoints } from "utils";

import { SubValue } from "../styles";
import { ReportsMethodologyCell } from "./ReportsMethodologyCell";

const fallback = (
  <>
    <Text fw="bold">N/A</Text>
  </>
);

export const InsightsDataQualityCell = ({
  dataQuality,
  methodology,
  sm
}: {
  dataQuality: TReportsDataSource["dataQuality"];
  methodology: TReportsDataSource["methodology"];
  sm?: boolean;
}) => {
  const { sb, cb, ss } = methodology;

  const sbValue = useMemo(() => formatPercentagePoints(sb), [sb]);
  const cbValue = useMemo(() => formatPercentagePoints(cb), [cb]);
  const ssValue = useMemo(() => formatPercentagePoints(ss), [ss]);

  if (!dataQuality) return fallback;

  return (
    <HoverCard>
      <HoverCard.Target>
        <Flex justify="center" align="center" direction="column">
          <Rating value={dataQuality as number} count={5} color="black" fractions={10} readOnly />
          {dataQuality && <SubValue sm={sm}>{dataQuality.toFixed(1)} / 5</SubValue>}
        </Flex>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <StyledDetail style={{ padding: ".5rem", margin: 0 }}>
          <StyledMethodWrapper>
            <StyledDetailValue color={warningColor}>{sbValue}% Transaction Based</StyledDetailValue>
            <StyledDetailValue color={monochromeColor[2]}>{cbValue}% Commodity-Based</StyledDetailValue>
            <StyledDetailValue color={primaryColor}>{ssValue}% Supplier-Specific</StyledDetailValue>
            <ReportsMethodologyCell methodology={methodology} width="100%" />
          </StyledMethodWrapper>
        </StyledDetail>
      </HoverCard.Dropdown>
    </HoverCard>
  );
};
