import { useEffect, useRef } from "react";

import { Divider, Flex, Grid } from "@mantine/core";
import { TrashIcon } from "ui/icons";

import { QCFormInstance } from "../../questionnaire.types";
import { questionnaireOptions } from "../../questionnaireOptions";
import { QCPlaceholder } from "./QCPlaceholder";
import { QCQuestionMappingSelect } from "./QCQuestionMappingSelect";
import { QCSelect } from "./QCSelect";
import {
  QCButton,
  QCInput,
  QCPlaceholderContainer,
  QCSwitch,
  QuestionnaireCreatorCard
} from "./questionnaireCreator.styles";

type QCQuestionProps = {
  form: QCFormInstance;
  index: number;
  onRemove: () => void;
  scope: "productQuestionList" | "supplierQuestionList";
};

export const QCQuestion: React.FC<QCQuestionProps> = ({ onRemove, form, scope, index }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const _focused = form.values._focused;

  const focused = _focused.scope === scope && _focused.index === index;

  useEffect(() => {
    if (inputRef && _focused.index === index && _focused.scope === scope && _focused.autoFocus) {
      inputRef?.current?.focus();
    }
  }, [inputRef, _focused.index, _focused.scope, _focused.autoFocus]);

  const onFocus = () => {
    if (_focused.scope === scope && _focused.index === index) {
      return;
    }
    form.setFieldValue("_focused", { scope, index });
  };

  return (
    <QuestionnaireCreatorCard focused={focused}>
      <Grid gutter={5}>
        <Grid.Col span={7}>
          <QCInput
            size="xs"
            {...form.getInputProps(`${scope}.${index}.question`)}
            ref={inputRef}
            onFocus={onFocus}
            autoFocus
          />
        </Grid.Col>
        <Grid.Col span={5}>
          <QCSelect
            data={questionnaireOptions}
            size="xs"
            {...form.getInputProps(`${scope}.${index}.type`)}
            onDropdownOpen={onFocus}
          />
        </Grid.Col>
      </Grid>
      <QCPlaceholderContainer>
        <QCPlaceholder form={form} index={index} scope={scope} />
      </QCPlaceholderContainer>
      <Flex wrap="wrap" gap="xs" align="center">
        <Flex miw={225} style={{ flex: "1" }}>
          <QCQuestionMappingSelect form={form} scope={scope} index={index} />
        </Flex>
        <Flex gap="xs" justify="flex-end" style={{ flexGrow: "1" }}>
          <Divider orientation="vertical" h={20} style={{ alignSelf: "center" }} />
          <QCButton variant="subtle" color="dark" leftIcon={<TrashIcon />} size="xs" onClick={onRemove}>
            Delete Question
          </QCButton>
          <Divider orientation="vertical" h={20} style={{ alignSelf: "center" }} />
          <QCSwitch
            labelPosition="left"
            label="Required"
            color="klp"
            size="xs"
            style={{ fontFamily: "Inter", fontWeight: "bold" }}
            {...form.getInputProps(`${scope}.${index}.required`)}
            onChange={switched => {
              form.getInputProps(`${scope}.${index}.required`).onChange(switched);
              onFocus();
            }}
          />
        </Flex>
      </Flex>
    </QuestionnaireCreatorCard>
  );
};
