import { useTheme } from "@emotion/react";
import { flexRender, Row, Table as TableType } from "@tanstack/react-table";

import { SortIcon, TableHead, TableHeadCell, TableWrapper } from "./Table.styles";

export type TableProps<T> = {
  enableActiveRowDisplay?: boolean;
  hoverable?: boolean;
  onRow?: {
    onClick?: (rowData: Row<T>) => void;
    onMouseLeave?: () => void;
    onMouseOver?: (rowData: Row<T>) => void;
  };
  rowSize?: string;
  table: TableType<T>;
};

export const Table = <T,>({
  table,
  onRow = { onClick: () => {}, onMouseOver: () => {}, onMouseLeave: () => {} },
  hoverable,
  enableActiveRowDisplay,
  rowSize
}: TableProps<T>) => {
  const { onClick, onMouseOver, onMouseLeave } = onRow;

  const {
    other: { monochromeColor, primaryColor },
    colors: { klp }
  } = useTheme();

  const activeRow = table.options.meta?.activeRow;

  return (
    <TableWrapper hoverable={hoverable} rowSize={rowSize}>
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => {
              const isSortable = header.column.getCanSort();

              const showSortIcon = isSortable ? <SortIcon style={{ color: monochromeColor[4] }} /> : null;

              const align = header.column.columnDef.meta?.align;
              const reverseHeader = header.column.columnDef.meta?.reverseHeader ?? false;

              const tableHeaderProps = {
                style: {
                  width: header.getSize(),
                  textAlign: header.column.columnDef.meta?.align ?? "left"
                },
                onClick: header.column.getToggleSortingHandler()
              };

              const tableHeaderRender = header.isPlaceholder
                ? null
                : flexRender(header.column.columnDef.header, header.getContext());

              return (
                <TableHead key={header.id} {...tableHeaderProps}>
                  <TableHeadCell align={align ?? "left"} reverse={reverseHeader}>
                    {tableHeaderRender}
                    {isSortable && (
                      <div>
                        {{
                          asc: (
                            <SortIcon
                              style={{
                                transform: "rotate(180deg)",
                                color: primaryColor
                              }}
                            />
                          ),
                          desc: <SortIcon style={{ color: primaryColor }} />
                        }[header.column.getIsSorted() as string] ?? showSortIcon}
                      </div>
                    )}
                  </TableHeadCell>
                </TableHead>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row, index) => {
          const getBackgroundColor = () => {
            if (enableActiveRowDisplay && activeRow === row.id) {
              return klp[0];
            }
            return index % 2 !== 0 ? "#eff1f34d" : monochromeColor[6];
          };

          const backgroundColor = getBackgroundColor();

          return (
            <tr
              key={row.id}
              style={{
                cursor: "default",
                backgroundColor,
                transition: "0.3s ease-in-out"
              }}
              onMouseOver={() => onMouseOver?.(row)}
              onMouseLeave={() => onMouseLeave?.()}
              onClick={() => onClick?.(row)}
            >
              {row.getVisibleCells().map(cell => (
                <td key={cell.id} style={{ textAlign: cell.column.columnDef.meta?.align ?? "left" }}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </TableWrapper>
  );
};
