import { FC, KeyboardEvent, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { Button, Group } from "@mantine/core";
import { debounce } from "lodash";
import { CurrentUserContext } from "modules/login/CurrentUserContext";
import { UserRoles } from "modules/users/types/UserTypesEnum";
import { AutocompleteComponent } from "ui";
import { PlusIcon, SettingIcon } from "ui/icons";
import { TopHeader } from "ui/layouts/common";
import { parseQueryParams } from "utils";

import { useGetSupplierListQuery } from "../hooks";
import {
  ActionButtonsWrapper,
  AutocompleteWrapper,
  SearchContainer,
  StyleButtonSearchWrapper,
  TableHeaderOptionsWrapper
} from "../styles/suppliersTable.styles";
import { ISupplier, ISupplierFilters, ISupplierModals } from "../suppliers.types";

type SuppliersTableHeaderProps = {
  applyFilters: () => void;
  filters: ISupplierFilters;
  modalController: (updatedModal: Partial<ISupplierModals>) => void;
  onMergeModalOpen: () => void;
  rowSelection: ISupplier[];
  updateFilters: (updatedFilters?: Partial<ISupplierFilters>, hardUpdate?: boolean) => void;
};

export const SuppliersTableHeader: FC<SuppliersTableHeaderProps> = ({
  modalController,
  filters,
  applyFilters,
  rowSelection,
  onMergeModalOpen,
  updateFilters
}) => {
  const { userDetails } = useContext(CurrentUserContext);

  const { id } = useParams();

  const { search } = useLocation();

  const [searchValue, setSearchValue] = useState(filters.name);

  const { data: suppliersData } = useGetSupplierListQuery(
    id,
    { name: filters.name, limit: 5 },
    { enabled: !!id && filters.name.length >= 3 }
  );

  const autocompleteData: string[] = suppliersData?.data?.map((supplier: ISupplier) => supplier.name) || [];

  const showActionButtons = useMemo(
    () => userDetails.userRole !== UserRoles.ACCOUNT_CATEGORY_MANAGER.value,
    [userDetails]
  );

  const mergeDisabled = rowSelection.length === 0;

  const suppliersCount = mergeDisabled ? "" : `(${rowSelection.length})`;

  const handleFilterButton = () => modalController({ filters: true });

  const handleSupplierButton = () => modalController({ addSupplier: true });

  const handleSearch = (value: string) => setSearchValue(value);

  const applySearch = (value: string) => updateFilters({ name: value }, value.length > 3 || value.length === 0);

  const synchronizeSearch = () => {
    const params = parseQueryParams(search);
    if (!params?.name) return handleSearch("");
    if (params?.name !== searchValue) handleSearch(params.name as string);
  };

  const searchOnKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if (!filters.name) return;
    if (event.key === "Enter") applyFilters();
  };

  const debouncedApplySearch = useCallback(debounce(applySearch, 500), [search]);

  useEffect(() => {
    if (searchValue !== filters.name) debouncedApplySearch(searchValue);
  }, [searchValue]);

  useEffect(() => synchronizeSearch(), [search]);

  return (
    <>
      <TopHeader title="Suppliers" />
      <TableHeaderOptionsWrapper>
        <StyleButtonSearchWrapper>
          <SearchContainer>
            <Group noWrap>
              <Button size="lg" variant="default" onClick={handleFilterButton} leftIcon={<SettingIcon />}>
                Filter
              </Button>
              <AutocompleteWrapper>
                <AutocompleteComponent
                  size="lg"
                  data={autocompleteData}
                  value={searchValue}
                  onChange={handleSearch}
                  onKeyUp={searchOnKeyUp}
                  onItemSubmit={item => handleSearch(item.value)}
                  placeholder="Search"
                />
              </AutocompleteWrapper>
            </Group>
          </SearchContainer>

          {showActionButtons && (
            <ActionButtonsWrapper>
              {id && (
                <Button size="lg" disabled={mergeDisabled} onClick={onMergeModalOpen}>
                  Merge {suppliersCount} Suppliers
                </Button>
              )}
              <Button size="lg" onClick={handleSupplierButton} leftIcon={<PlusIcon />}>
                New Supplier
              </Button>
            </ActionButtonsWrapper>
          )}
        </StyleButtonSearchWrapper>
      </TableHeaderOptionsWrapper>
    </>
  );
};
