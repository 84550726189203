import { SampleText } from "./importMapModal.styles";
import { HeaderType } from "./importMapModal.types";

export const ImportMapFileFieldCell: React.FC<HeaderType> = props => {
  const { csvHeaderKey, samples } = props;

  return (
    <>
      <div>{csvHeaderKey}</div>
      <div>
        <SampleText>"{samples?.[0] || ""}"</SampleText>
      </div>
    </>
  );
};
