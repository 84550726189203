import { useContext } from "react";

import { Center, LoadingOverlay, Select } from "@mantine/core";
import { useGetAnalyticsGrouped } from "modules/analytics";
import Loader from "ui/feedback/Loader";

import { CommodityContext } from "../context/CommodityContext";
import { useCommodityQuery } from "../hooks";
import { CommodityMergeInformation } from "./CommodityMergeInformation";
import { CommodityMergeInformationWrapper, CommodityMergeModalContainer } from "./commodityMergeModalContent.styles";

type CommodityMergeModalContentProps = {
  onChange: (value: string | null) => void;
  value: string;
};

export const CommodityMergeModalContent: React.FC<CommodityMergeModalContentProps> = ({ onChange, value }) => {
  const commodity = useCommodityQuery(value);
  const { rowSelection } = useContext(CommodityContext);

  const options = rowSelection.map(row => ({ value: row.id, label: row.name }));

  const commodityAnalytics = useGetAnalyticsGrouped({
    item: value,
    accountId: commodity.data?.data.account.id,
    start: "1970-01-01T00:00:00Z",
    end: "2100-01-01T00:00:00Z",
    timeWindow: "alltime",
    interval: "month",
    group: "item",
    buyspaceId: null
  });

  const renderInformation = () => {
    if (!commodity.data || !commodityAnalytics.data || !value) {
      return <Center>Select a target commodity.</Center>;
    }

    return (
      <CommodityMergeInformation
        commodity={commodity.data.data}
        transactionCount={commodityAnalytics.data?.summary?.transactions}
      />
    );
  };

  return (
    <CommodityMergeModalContainer>
      <Select data={options} value={value} onChange={onChange} w={300} />
      <CommodityMergeInformationWrapper>
        <LoadingOverlay visible={commodity.isLoading && !!value} overlayBlur={2} loader={<Loader />} />
        {renderInformation()}
      </CommodityMergeInformationWrapper>
    </CommodityMergeModalContainer>
  );
};
