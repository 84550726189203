import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { errorModal } from "modules/error/ErrorHandlingModals";
import { ErrorResponse } from "types";

import { putQuestionnaireService } from "../api";

export const usePutQuestionnaireMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [QUERY_KEYS.put_questionnaire],
    mutationFn: putQuestionnaireService,
    onError: (error: ErrorResponse) => errorModal(error),
    onSuccess: () => queryClient.refetchQueries([QUERY_KEYS.get_questionnaire_list])
  });
};
