import { FC } from "react";

import { USER_TYPE } from "modules/users/types/UserTypesEnum";

import {
  AvatarLeadWrapper,
  AvatarWrapper,
  StyleMemoProfileLeadLogo,
  StyleUserProfileImage,
  TableTextWrapper
} from "./userTable.styles";
import { UserListingInterface } from "./userTable.types";

type Props = {
  element: UserListingInterface;
};

export const UserTableAvatar: FC<Props> = ({ element }) => {
  const user_role = element.userType ?? "";
  const isInternal = user_role === USER_TYPE.internal;

  return (
    <AvatarWrapper style={{ paddingLeft: isInternal ? "0px" : "10px" }}>
      {isInternal ? (
        <AvatarLeadWrapper>
          <StyleUserProfileImage src={element.image} />
          <StyleMemoProfileLeadLogo />
        </AvatarLeadWrapper>
      ) : (
        <StyleUserProfileImage src={element.image} />
      )}

      <TableTextWrapper>{element.firstName}</TableTextWrapper>
    </AvatarWrapper>
  );
};
