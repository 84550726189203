import { ResponseType, TMilestoneResponse } from "types";

import axiosInstance from "../AxiosInstance";

type GetMilestoneListServiceArgs = {
  accountId: string;
};

export const getMilestoneListService = async (
  params: GetMilestoneListServiceArgs
): Promise<ResponseType<TMilestoneResponse>> => {
  const { accountId } = params;
  const response = await axiosInstance.get(`milestones/${accountId}`);
  return response.data;
};
