import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";

import { getUserFeatureFlagListService } from "../api/getUserFeatureFlagListService";

type TUseGetAccountFeatureFlagListParameters = {
  queryEnabled: boolean;
  userId: string;
};

export const useGetUserFeatureFlagList = ({ queryEnabled, userId }: TUseGetAccountFeatureFlagListParameters) =>
  useQuery({
    queryKey: [QUERY_KEYS.get_feature_flag_list_user],
    queryFn: () => getUserFeatureFlagListService(userId),
    enabled: queryEnabled,
    staleTime: Infinity
  });
