import styled from "@emotion/styled";

export const LogoRow = styled.div`
  display: flex;
  padding: 0.5rem;
  align-items: center;
  column-gap: 0.25rem;
  overflow-x: hidden;
  & > div {
    flex: 1 1 0;
    height: 4rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: auto;
      max-width: 6rem;
      max-height: 3rem;
      height: auto;
      filter: grayscale(1);
    }
  }
`;

export const FooterTag = styled.footer<{ preview?: boolean }>`
  padding: 2rem 0;
  background-color: ${({ theme }) => theme.other.primaryColor};
  border-top: 1px solid ${({ theme }) => theme.other.monochromeColor[5]};
  margin-bottom: 0;
  ${({ preview }) => preview && `pointer-events: none;`}
`;

export const FooterInner = styled.div`
  max-width: 1366px;
  margin: 0 auto;

  @media only screen and (max-width: 1440px) {
    max-width: 75%;
  }
`;

export const StyleFooterLinks = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
  color: ${props => props.theme.other.monochromeColor[6]};
`;

export const FooterLinks = styled.a`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: 1rem;
  text-align: center;
  white-space: nowrap;
  color: ${props => props.theme.other.monochromeColor[6]};
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const StyleFooterContactUsEmail = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: ${props => props.theme.other.font.fontSize};
  line-height: ${props => props.theme.other.font.lineHeight};
  text-align: right;
  letter-spacing: 0.04em;
  color: ${props => props.theme.other.monochromeColor[6]};
`;
