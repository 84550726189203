import { ChangeEvent } from "react";

import { TextInput } from "@mantine/core";
import { QuestionnaireValidateReady } from "types";

type QuestionnaireLongTextProps = {
  data: QuestionnaireValidateReady;
  group: string;
  onChange: (response: string | string[], id: string, group: string, loading?: boolean, error?: string) => void;
  submitted?: boolean;
};

export const QuestionnaireLongText = ({ data, group, onChange, submitted }: QuestionnaireLongTextProps) => {
  return (
    <TextInput
      value={data?.response || ""}
      label={data.question}
      error={data.error}
      onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value, data.id, group)}
      required={data.required}
      readOnly={submitted}
      maxLength={500}
      maw="50%"
      placeholder="Enter your response"
    />
  );
};

export default QuestionnaireLongText;
