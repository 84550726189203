import moment from "moment";

import { useGetAnalyticsDatumQuery } from "./useGetAnalyticsDatumQuery";

export const useInsightDates = (accid: string) => {
  const { data, isLoading } = useGetAnalyticsDatumQuery(accid);

  const end = data.data.mostRecent;

  const start = moment(end)
    .subtract(12, "months")
    .utcOffset(0)
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .endOf("month")
    .toISOString();

  return { start: start, end, isLoading };
};
