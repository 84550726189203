import { useParams } from "react-router-dom";

import { Button, Divider, Select, Stack, TextInput } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { successModal } from "modules/error/ErrorHandlingModals";
import { QuestionnaireStatus, TQuestionnaire } from "types";

import { useGetQuestionnaireListQuery, usePostQuestionnaireRequestMutation } from "../../hooks";
import { questionnaireResponseListSchema } from "../../questionnaire.data";
import {
  QuestionnaireInputError,
  QuestionnaireRequestCalendar,
  QuestionnaireRequestModalContainer,
  QuestionnaireRequestModalRow,
  QuestionnaireRequestModalRowContent
} from "../../questionnaire.styles";
import { QuestionnaireRequestListSearchableSelect } from "./QuestionnaireRequestListSearchableSelect";

type QuestionnaireRequestModalProps = {
  onClose: () => void;
  opened: boolean;
};

type TQuestionnaireResponseForm = {
  questionnaireId: string;
  respondBy: string;
  sendTo: string;
  sendToFirstName: string;
  sendToLastName: string;
  supplierId: string;
};

export const QuestionnaireRequestListModal = ({ opened, onClose }: QuestionnaireRequestModalProps) => {
  const queryClient = useQueryClient();

  const { id } = useParams();

  const questionnaireReqMutation = usePostQuestionnaireRequestMutation();

  const questionnaireListQuery = useGetQuestionnaireListQuery({
    accountId: id as string,
    apiParams: { status: [QuestionnaireStatus.ACTIVE] }
  });

  const questionnaireResponseForm = useForm<TQuestionnaireResponseForm & { supplierName: string }>({
    initialValues: {
      supplierId: "",
      supplierName: "",
      questionnaireId: "",
      sendTo: "",
      sendToFirstName: "",
      sendToLastName: "",
      respondBy: ""
    },
    validate: yupResolver(questionnaireResponseListSchema)
  });

  const sendRequest = (payload: TQuestionnaireResponseForm & { supplierName: string }) => {
    if (id) {
      const { supplierName, ...restPayload } = payload;

      questionnaireReqMutation.mutate(
        {
          accountId: id,
          payload: restPayload
        },
        {
          onSuccess: () => {
            onClose();
            successModal(
              `Your questionnaire request for ${supplierName} was sent to ${questionnaireResponseForm.values.sendTo}.`
            );
            questionnaireResponseForm.reset();
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEYS.get_questionnaire_request_list_by_account]
            });
          }
        }
      );
    }
  };

  const normalizeSelectData = (questionnaireList?: TQuestionnaire[]) => {
    if (questionnaireList) {
      return questionnaireList.map(q => ({ label: q.title, value: q.id }));
    }
    return [];
  };

  return (
    <QuestionnaireRequestModalContainer opened={opened} handleClose={onClose} withCloseButton>
      <form onSubmit={questionnaireResponseForm.onSubmit(sendRequest)}>
        <QuestionnaireRequestModalRow>
          <h1>New Supplier Request</h1>
          <Stack spacing="sm" mt="md">
            <QuestionnaireRequestListSearchableSelect
              {...questionnaireResponseForm.getInputProps("supplierId")}
              onNameChange={value => questionnaireResponseForm.setFieldValue("supplierName", value)}
            />
            <Select
              placeholder="Select Questionnaire"
              data={normalizeSelectData(questionnaireListQuery?.data?.data)}
              {...questionnaireResponseForm.getInputProps("questionnaireId")}
            />
          </Stack>
        </QuestionnaireRequestModalRow>

        <QuestionnaireRequestModalRow>
          <h2>Due Date</h2>
          <Divider mb={30} />
          <QuestionnaireRequestModalRowContent alc>
            <div>
              <QuestionnaireRequestCalendar
                {...questionnaireResponseForm.getInputProps("respondBy")}
                error={questionnaireResponseForm.getInputProps("respondBy").error}
              />
              <QuestionnaireInputError>
                {questionnaireResponseForm.getInputProps("respondBy").error}
              </QuestionnaireInputError>
            </div>
          </QuestionnaireRequestModalRowContent>
        </QuestionnaireRequestModalRow>

        <QuestionnaireRequestModalRow>
          <h2>Contact Information</h2>
          <Divider mb={30} />
          <QuestionnaireRequestModalRowContent>
            <TextInput
              w={"100%"}
              withAsterisk
              label="Email Address"
              {...questionnaireResponseForm.getInputProps("sendTo")}
            />
            <TextInput
              w={"100%"}
              withAsterisk
              label="First Name"
              {...questionnaireResponseForm.getInputProps("sendToFirstName")}
            />
            <TextInput
              w={"100%"}
              withAsterisk
              label="Last Name"
              {...questionnaireResponseForm.getInputProps("sendToLastName")}
            />
          </QuestionnaireRequestModalRowContent>
        </QuestionnaireRequestModalRow>

        <QuestionnaireRequestModalRow>
          <QuestionnaireRequestModalRowContent alc>
            <Button type="submit">Send Request</Button>
          </QuestionnaireRequestModalRowContent>
        </QuestionnaireRequestModalRow>
      </form>
    </QuestionnaireRequestModalContainer>
  );
};
