import { useContext } from "react";

import { CurrentUserContext } from "modules/login";
import { USER_TYPE } from "modules/users";

import { ImportInterface } from "../../types";

export const UploadedByCell: React.FC<ImportInterface> = props => {
  const {
    userDetails: { userType }
  } = useContext(CurrentUserContext);

  if (userType === USER_TYPE.external && props.originatedBy?.userType === USER_TYPE.internal) {
    return <>Kloopify</>;
  }

  const name = `${props.originatedBy?.firstName ?? ""} ${props.originatedBy?.lastName ?? ""}`;

  return <>{name}</>;
};
