import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { errorModal } from "modules/error/ErrorHandlingModals";
import { ErrorResponse } from "types";

import { getQuestionnaireRequestListByAccountService, GetQuestionnaireRequestListByAccountServiceArgs } from "../api";

export const useGetQuestionnaireRequestListByAccountQuery = (
  payload: GetQuestionnaireRequestListByAccountServiceArgs
) =>
  useQuery({
    queryKey: [QUERY_KEYS.get_questionnaire_request_list_by_account, payload.apiArgs],
    queryFn: () => getQuestionnaireRequestListByAccountService(payload),
    onError: (error: ErrorResponse) => errorModal(error)
  });
