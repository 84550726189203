import { useParams } from "react-router-dom";

import { useCurrentUser } from "modules/login";
import { Feature } from "types";

type UsePermissionGuardProps = {
  excludeExternal?: boolean;
  module: Feature;
};

export const usePermissionGuard = ({ module, excludeExternal }: UsePermissionGuardProps) => {
  const { id: paramsId } = useParams();

  const {
    userDetails: { accountId, featureFlags },
    isInternal
  } = useCurrentUser();

  if (excludeExternal && isInternal) return false;

  const nestedAccountRestrictedFeatureList = featureFlags.map(i => {
    const accountPermissionList = i[paramsId as string] || i[accountId];
    if (!accountPermissionList) return [];
    return accountPermissionList
      .map(permission => {
        const [featureName, enabled] = Object.entries(permission)[0];
        return enabled ? null : featureName;
      })
      .filter(a => a) as NonNullable<Feature[]>;
  });

  const guardedAccountPermissions = ([] as Feature[]).concat(...nestedAccountRestrictedFeatureList);

  return guardedAccountPermissions.some((p: Feature) => p === module);
};
