import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { LoadingOverlay } from "@mantine/core";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { CurrentUserContext } from "modules/login/CurrentUserContext";
import { StyleLoginButton, StyleLoginButtonText } from "modules/login/styles/Login.styles";
import { useGetUserInfo } from "modules/users/hooks/useGetUserInfo";
import { JwtTokenInterface } from "modules/users/types/UserDto";
import { LOGIN_TYPE, USER_TYPE } from "modules/users/types/UserTypesEnum";
import { ResponseType } from "types";
import Loader from "ui/feedback/Loader";
import { GoogleLogo } from "ui/icons";

import { useAuthenticateUserMutation, useGetGoogleUserInfoQuery } from "../hooks";

const GoogleLoginComponent = () => {
  const navigate = useNavigate();

  const { userDetails, setUserDetails, setUserId, setJWT_Token } = useContext(CurrentUserContext);

  const [accessToken, setAccessToken] = useState("");

  const googleLogin = useGoogleLogin({ onSuccess: async tokenResponse => setAccessToken(tokenResponse.access_token) });

  const getGoogleLoginQuery = useGetGoogleUserInfoQuery(accessToken, Boolean(accessToken));

  const authenticateUserMutation = useAuthenticateUserMutation();

  const getUserInfoQuery = useGetUserInfo(userDetails.userId, Boolean(userDetails.userId));

  const isLoading =
    getGoogleLoginQuery.fetchStatus === "fetching" ||
    getUserInfoQuery.fetchStatus === "fetching" ||
    authenticateUserMutation.isLoading;

  /**
   * Gets users JWT token and runs user information update
   */
  const onSucessAuthQuery = (data: ResponseType<{ jwtToken: string }>) => {
    const jwtToken = data.data.jwtToken;
    const decodedJWT_token = jwt_decode<JwtTokenInterface>(jwtToken);
    localStorage.setItem("jwt_Token", jwtToken);
    setJWT_Token(jwtToken);
    setUserId(decodedJWT_token.sub);
  };

  /**
   * Runs internal authentification after google login succeded
   */
  const onSuccessGoogleQuery = () => {
    if (getGoogleLoginQuery.isSuccess) {
      localStorage.setItem("token", accessToken);
      authenticateUserMutation.mutate(
        { accessToken: accessToken, signWith: LOGIN_TYPE.google },
        { onSuccess: onSucessAuthQuery }
      );
    }
  };

  /**
   * User information update
   */
  const onSucessUserQuery = () => {
    const userData = getUserInfoQuery.data?.data;
    if (userData) {
      const { status, id, ...user } = userData;
      const { role, userId, ...account } = userData.accounts[0];
      setUserDetails(details => ({ ...details, ...user, ...account, userId: id, userRole: role }));

      if (userData.userType === USER_TYPE.internal) return navigate(`/accounts`);
      navigate("/account-selection");
    }
  };

  useEffect(() => onSuccessGoogleQuery(), [getGoogleLoginQuery.isSuccess]);

  useEffect(() => onSucessUserQuery(), [getUserInfoQuery.isSuccess]);

  if (isLoading) return <LoadingOverlay visible={true} overlayBlur={2} loader={<Loader />} />;

  return (
    <React.Fragment>
      <StyleLoginButton onClick={() => googleLogin()}>
        <GoogleLogo fontSize="17px" />
        <StyleLoginButtonText>Sign in with Google</StyleLoginButtonText>
      </StyleLoginButton>
    </React.Fragment>
  );
};

export const GoogleLogin = () => {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_APP_ID as string}>
      <GoogleLoginComponent />
    </GoogleOAuthProvider>
  );
};
