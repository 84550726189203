import { useSearchParams } from "react-router-dom";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { errorModal } from "modules/error/ErrorHandlingModals";
import { ErrorResponse, ResponseType, TQuestionnaireRequest } from "types";

import { deleteQuestionnaireRequestService } from "../api";

export const useDeleteQuestionnaireRequestMutation = ({ onSuccess }: { onSuccess?: () => void }) => {
  const queryClient = useQueryClient();

  const [searchParams] = useSearchParams();

  const { page = 0 } = Object.fromEntries(searchParams);

  const queryKey = [QUERY_KEYS.get_questionnaire_request_list, { page, limit: 10 }];

  const mutation = useMutation({
    mutationKey: [QUERY_KEYS.delete_questionnaire_request],
    mutationFn: deleteQuestionnaireRequestService,
    onMutate: async ({ questionnaireRequestId }) => {
      const queryKeyById = [QUERY_KEYS.get_questionnaire_request, questionnaireRequestId];

      await queryClient.cancelQueries({ queryKey });
      await queryClient.cancelQueries({ queryKey: queryKeyById });

      const previousData: ResponseType<TQuestionnaireRequest[]> | undefined = queryClient.getQueryData(queryKey);

      if (previousData) {
        const newQuestionnaireRequestList = previousData.data.filter(({ id }) => id !== questionnaireRequestId);

        const updatedValues = {
          data: newQuestionnaireRequestList,
          meta: previousData.meta
        };

        queryClient.setQueryData(queryKey, updatedValues);

        return { updatedValues };
      }

      return { previousData };
    },
    onSuccess: (_, { questionnaireRequestId }) => {
      queryClient.invalidateQueries({ queryKey });
      queryClient.removeQueries({ queryKey: [QUERY_KEYS.get_questionnaire_request, questionnaireRequestId] });
      onSuccess?.();
    },
    onError: (error: ErrorResponse) => errorModal(error)
  });

  return mutation;
};
