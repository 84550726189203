import { Stack } from "@mantine/core";
import { getHotkeyHandler } from "@mantine/hooks";
import { AddIcon } from "ui/icons";

import { QCFormInstance, QCFormValues } from "../../questionnaire.types";
import { QCQuestion } from "./QCQuestion";
import { qcNewQuestion } from "./questionnaireCreator.data";
import { QCButton, QCButtonContainer, QCSubtitle } from "./questionnaireCreator.styles";

export const QCSection: React.FC<{
  form: QCFormInstance<QCFormValues>;
  scope: "productQuestionList" | "supplierQuestionList";
  title: string;
}> = ({ form, scope, title }) => {
  const handleInsertNewQuestion = () => {
    const length = form.values[scope].length;
    form.insertListItem(scope, qcNewQuestion);
    form.setFieldValue("_focused", { scope, index: length, autoFocus: true });
  };

  const handleRemoveQuestion = (index: number) => {
    form.removeListItem(scope, index);
  };

  const values = form.values?.[scope] || [];
  const _focused = form.values._focused;

  const questions = values.map((_, index) => (
    <QCQuestion key={index} index={index} onRemove={() => handleRemoveQuestion(index)} form={form} scope={scope} />
  ));

  const onEnter = () => {
    if (_focused.index === values.length - 1) {
      handleInsertNewQuestion();
      return;
    }
    form.setFieldValue("_focused", { scope, index: _focused.index + 1, autoFocus: true });
  };

  return (
    <div onKeyDown={getHotkeyHandler([["Enter", onEnter]])}>
      <QCSubtitle>{title}</QCSubtitle>
      <Stack>{questions}</Stack>
      <QCButtonContainer>
        <QCButton variant="subtle" leftIcon={<AddIcon />} color="dark" radius="xl" onClick={handleInsertNewQuestion}>
          Add New Question
        </QCButton>
      </QCButtonContainer>
    </div>
  );
};
