import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { errorModal } from "modules/error/ErrorHandlingModals";
import { ErrorResponse } from "types";

import { getImportListService } from "../api";

export const useGetUserImportsQuery = (
  page = 0,
  limit = 25,
  userType: string,
  filterParams?: string,
  accountId?: string
) => {
  const query = useQuery({
    queryKey: [QUERY_KEYS.imports_listing, { page, filterParams }],
    queryFn: () => getImportListService(page, limit, userType, filterParams, accountId),
    staleTime: Infinity,
    enabled: Boolean(userType),
    onError: (error: ErrorResponse) => errorModal(error),
    useErrorBoundary: true
  });
  return query;
};
