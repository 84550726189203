import { useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useTheme } from "@emotion/react";
import { Grid, HoverCard } from "@mantine/core";
import { CellContext } from "@tanstack/react-table";
import { TReportsDataSource } from "types";
import { formatPercentagePoints, stringifyQueryParams } from "utils";

import { transactionRedirectConfig } from "../analytics.data";
import {
  ColorIndicator,
  NameCellContainer,
  NameCellParagraph,
  NameCellSpan,
  NameCellSubtitle,
  NameCellTitle,
  TableNameCell,
  TableNameCellWrapper
} from "../styles";
import { TCustomColumnAccessor } from "./columns";

export const ReportsNameCell = ({ row, table, getValue }: CellContext<TReportsDataSource, TCustomColumnAccessor>) => {
  const { id, colorCode, methodology, transactions, items, suppliers } = row.original;

  const { sb = 0, cb = 0, ss = 0 } = methodology;

  const navigate = useNavigate();

  const {
    other: { warningColor, primaryColor }
  } = useTheme();

  const tableCellRef = useRef<HTMLDivElement>(null);

  const sbValue = formatPercentagePoints(sb);

  const cbValue = formatPercentagePoints(cb);

  const ssValue = formatPercentagePoints(ss);

  const activeRow = table.options.meta?.activeRow;

  const name = getValue().row.description;

  const filters = getValue().filters;

  const onClick = () => {
    const redirectData = transactionRedirectConfig[filters.group];
    if (!redirectData) return;
    const { redirectKey, redirectIdKey, type } = redirectData;
    if (redirectIdKey !== ("carbonIntensity" as const)) {
      const id = row.original[redirectIdKey];
      if (!id) return;
      const paramsObject = { [redirectKey]: type === "array" ? [id] : id };
      navigate(`/transactions?${stringifyQueryParams(paramsObject)}`);
    }
  };

  return (
    <HoverCard shadow="md" position="top-start" withinPortal width="24rem">
      <TableNameCell>
        <HoverCard.Target>
          <TableNameCellWrapper active={id === activeRow} ref={tableCellRef} onClick={onClick}>
            <>
              <ColorIndicator style={{ background: colorCode }} />
              {name}
            </>
          </TableNameCellWrapper>
        </HoverCard.Target>
      </TableNameCell>
      <HoverCard.Dropdown
        style={{ padding: 21, border: 0, marginLeft: tableCellRef?.current?.clientWidth || 0, width: "100%" }}
        onClick={e => e.stopPropagation()}
      >
        <NameCellContainer>
          <NameCellTitle>{name}</NameCellTitle>
          <div>
            <Grid gutter="2rem">
              <Grid.Col span={6}>
                <NameCellSubtitle>Totals</NameCellSubtitle>
                <NameCellParagraph>Suppliers: {suppliers}</NameCellParagraph>
                <NameCellParagraph>
                  <NameCellSpan>Products: {items}</NameCellSpan>
                </NameCellParagraph>
                <NameCellParagraph>
                  <NameCellSpan>Transactions: {transactions}</NameCellSpan>
                </NameCellParagraph>
              </Grid.Col>
              <Grid.Col span={6}>
                <NameCellSubtitle>Quality</NameCellSubtitle>
                <NameCellParagraph>{sbValue}% Transaction Based</NameCellParagraph>
                <NameCellParagraph>
                  <NameCellSpan color={warningColor}>{cbValue}%</NameCellSpan> Commodity Based
                </NameCellParagraph>
                <NameCellParagraph>
                  <NameCellSpan color={primaryColor}>{ssValue}%</NameCellSpan> Supplier Specific
                </NameCellParagraph>
              </Grid.Col>
            </Grid>
          </div>
        </NameCellContainer>
      </HoverCard.Dropdown>
    </HoverCard>
  );
};
