import styled from "@emotion/styled";
import { ChevronDown } from "ui/icons";

export const SortIcon = styled(ChevronDown)`
  display: flex;
  width: 16px;
  min-width: 16px;
  height: 10px;
`;

export const TableHead = styled.th`
  background-color: ${props => props.theme.other.monochromeColor[6]};
  width: 100%;
  border-collapse: collapse;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
`;

export const TableHeadCell = styled.div<{ align: "center" | "left" | "right"; reverse?: boolean }>`
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  justify-content: ${({ align, reverse }) => {
    if (align === "center") {
      return "center";
    }
    if (align === "right" && !reverse) {
      return "flex-end";
    }
    return "flex-start";
  }};
  flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "initial")};
  align-items: center;
  column-gap: 0.5rem;
  font-size: 1rem !important;
`;

export const TableWrapper = styled.table<{ hoverable?: boolean; rowSize?: string }>`
  position: relative;
  width: 100%;
  border-collapse: collapse;
  text-align: left;

  thead {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  thead {
    tr {
      th {
        border-right: 1px solid ${props => props.theme.other.monochromeColor[3]};
        background-color: ${props => props.theme.other.monochromeColor[4]};
        font-weight: 700;
        color: ${props => props.theme.other.monochromeColor[2]} !important;
        border-top-width: 1px;
        padding: 16px;
      }
      th:last-child {
        border-right: none;
      }
    }
  }

  tbody {
    tr {
      ${({ hoverable, theme }) =>
        hoverable &&
        `
      :hover {
        background-color: ${theme.other.monochromeColor[5]} !important;
        cursor: pointer !important;
        transition: 0.1s ease-in-out;
      }
      `}

      td {
        box-sizing: border-box;
        text-align: left;
        border-right: 1px solid ${props => props.theme.other.monochromeColor[3]};
        font-size: 1rem;
        padding: 1rem;
        height: ${props => props.rowSize || "auto"};
      }
      td:last-child {
        border-right: none;
      }
    }
  }
`;
