import { MantineThemeOverride } from "@mantine/core";
import { monochromeColor } from "constants/colorPalette";
import { ChevronDown } from "ui/icons";

export const componentsOverride: MantineThemeOverride["components"] = {
  Input: {
    defaultProps: {
      input: {
        color: monochromeColor[2]
      }
    }
  },
  Textarea: {
    defaultProps: {
      input: {
        color: monochromeColor[2]
      }
    }
  },
  DatePicker: {
    defaultProps: {
      input: {
        color: monochromeColor[2]
      }
    }
  },
  Select: {
    defaultProps: theme => ({
      rightSection: <ChevronDown />,
      rightSectionProps: {
        style: {
          color: theme.colors.gray[4]
        }
      },
      input: {
        color: monochromeColor[2]
      }
    })
  },
  MultiSelect: {
    defaultProps: {
      rightSection: <ChevronDown />
    },
    styles: theme => ({
      rightSection: {
        color: theme.colors.gray[4]
      },
      input: {
        color: monochromeColor[2]
      }
    })
  },
  Radio: {
    styles: () => ({
      radio: {
        borderRadius: 2,
        background: monochromeColor[4],
        borderColor: monochromeColor[4]
      },
      icon: {
        display: "none"
      },
      label: {
        color: monochromeColor[2]
      }
    })
  },
  Checkbox: {
    styles: () => ({
      input: {
        borderRadius: 2,
        background: monochromeColor[4],
        borderColor: monochromeColor[4]
      },
      icon: {
        display: "none"
      },
      label: {
        color: monochromeColor[2]
      }
    })
  }
};

export const headingsOverride: MantineThemeOverride["headings"] = {
  fontWeight: 400,
  fontFamily: "Inter",
  sizes: {
    h1: { fontWeight: 400, fontSize: "2.6rem", lineHeight: "3rem" },
    h2: { fontSize: "2.3rem", lineHeight: "2.6rem", fontWeight: "700" },
    h3: {
      fontSize: "2rem",
      lineHeight: "2.3rem"
    }
  }
};
