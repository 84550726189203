import { HeaderType } from "./importMapModal.types";

const keyToSample = {
  date: "1/1/2021",
  supplierName: "GOTHAM CITY PUBLIC SAFETY SUPPLY, INC.",
  totalCost: "$152.49",
  itemName: "Office Supplies",
  costCenter: "MOBILITY AND INFRASTRUCTURE",
  reference: "12345"
};

export const ImportMapSampleCell: React.FC<HeaderType> = ({ kloopifyFieldKey }) => {
  if (kloopifyFieldKey && kloopifyFieldKey in keyToSample) {
    const sample = keyToSample[kloopifyFieldKey as keyof typeof keyToSample];
    return <>{sample}</>;
  }

  return null;
};
