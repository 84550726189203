import { useEffect, useMemo, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import { LoadingOverlay } from "@mantine/core";
import { useQueryClient } from "@tanstack/react-query";
import jwt_decode from "jwt-decode";
import { JwtTokenInterface, useGetUserFeatureFlagList, useGetUserInfo, USER_TYPE, UserRoles } from "modules/users";
import { TUser } from "types";
import Loader from "ui/feedback/Loader";

import Router from "./AppRouter";
import { CurrentUserContext, TUserDetails, userDetailsDefaultValue } from "./modules/login/CurrentUserContext";

import "./App.css";

const App = () => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const [activeTab, setActiveTab] = useState<string | null>(null);

  const [JWT_Token, setJWT_Token] = useState<string | null>(null);

  const [userDetails, setUserDetails] = useState<TUserDetails>(userDetailsDefaultValue);

  const [userId, setUserId] = useState("");

  const getUserInfoQuery = useGetUserInfo(userId, false);

  const getAccountFeatureFlagsQuery = useGetUserFeatureFlagList({
    userId,
    queryEnabled: false
  });

  const isFetching = getUserInfoQuery.isFetching || getAccountFeatureFlagsQuery.isFetching;

  const isInternal = useMemo(() => userDetails.userType === USER_TYPE.internal, [userDetails]);

  const logout = () => {
    queryClient.removeQueries();
    setJWT_Token(null);
    setUserId("");
    setActiveTab(null);
    localStorage.clear();
    sessionStorage.clear();
    setUserDetails(userDetailsDefaultValue);
    navigate("/");
  };

  const verifyUserAuthentificated = () => {
    const jwtToken = localStorage.getItem("jwt_Token") ?? null;
    if (jwtToken !== null) {
      const decodedJWT_token = jwt_decode<JwtTokenInterface>(jwtToken);
      const today = Date.now();
      const exp = decodedJWT_token.exp * 1000;

      if (today >= exp) {
        return logout();
      }
      setJWT_Token(jwtToken);
      setUserId(decodedJWT_token.sub);
    }
  };

  const determineUserAccount = (data: TUser) => {
    if (data.userType === USER_TYPE.external) {
      const currentAccount = data.accounts.find(i => i.accountId === localStorage.getItem("accountId"));
      if (currentAccount) return currentAccount;
    }
    return data.accounts[0] || {};
  };

  const updateUserInformation = () => {
    const userData = getUserInfoQuery.data?.data;
    if (userData) {
      const { status, id, ...user } = userData;
      const { role, userId, ...account } = determineUserAccount(userData);
      setUserDetails(details => ({ ...details, ...user, ...account, userId: id, userRole: role }));
    }
  };

  useEffect(
    () => setUserDetails(details => ({ ...details, featureFlags: getAccountFeatureFlagsQuery.data?.data || [] })),
    [getAccountFeatureFlagsQuery.isSuccess]
  );

  useEffect(() => {
    if (userDetails.userRole && userDetails.userRole !== UserRoles.INTERNAL_ADMIN.value)
      getAccountFeatureFlagsQuery.refetch();
  }, [userDetails]);

  useEffect(() => updateUserInformation(), [getUserInfoQuery.isSuccess, getUserInfoQuery.data]);

  useEffect(() => verifyUserAuthentificated(), []);

  useEffect(() => {
    if (JWT_Token && userId) {
      const decodedJWT_token = jwt_decode<JwtTokenInterface>(JWT_Token);
      const today = Date.now();
      const exp = decodedJWT_token.exp * 1000;

      if (today >= exp) {
        return logout();
      }

      getUserInfoQuery.refetch();
    }
  }, [JWT_Token, userId]);

  if (isFetching) return <LoadingOverlay visible overlayBlur={2} loader={<Loader />} />;

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Kloopify</title>
      </Helmet>
      <CurrentUserContext.Provider
        value={{
          userDetails,
          isInternal,
          setUserDetails,
          setUserId,
          activeTab,
          setActiveTab,
          JWT_Token,
          setJWT_Token,
          logout
        }}
      >
        <Router />
      </CurrentUserContext.Provider>
    </HelmetProvider>
  );
};

export default App;
