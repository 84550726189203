import { useTheme } from "@emotion/react";
import { Progress } from "@mantine/core";
import { TMethodologyObject } from "types";
import { formatPercentagePoints } from "utils/common.utils";

export type ReportsMethodologyCellProps = {
  methodology: TMethodologyObject;
  width: number | string;
};

export const ReportsMethodologyCell = ({ methodology, width }: ReportsMethodologyCellProps) => {
  const { sb = 0, cb = 0, ss = 0 } = methodology;

  const sbValue = formatPercentagePoints(sb);
  const cbValue = formatPercentagePoints(cb);
  const ssValue = formatPercentagePoints(ss);

  const {
    other: { primaryColor, monochromeColor, warningColor }
  } = useTheme();

  return (
    <Progress
      radius={0}
      h={12}
      w={width}
      sections={[
        { value: sbValue, color: warningColor },
        { value: cbValue, color: monochromeColor[2] },
        { value: ssValue, color: primaryColor }
      ]}
    />
  );
};
