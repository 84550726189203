import { useSearchParams } from "react-router-dom";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { errorModal } from "modules/error/ErrorHandlingModals";
import { ErrorResponse, ResponseType } from "types";

import { putRejectTransactionService } from "../api";
import { ImportInterface } from "../types";

type UseRejectTransactionImportMutationOptions = {
  limit?: number;
};

export const useRejectTransactionImportMutation = (
  options: UseRejectTransactionImportMutationOptions = { limit: 10 }
) => {
  const queryClient = useQueryClient();

  const { limit } = options;

  const [params] = useSearchParams();

  const page = Number(params.get("page"));

  return useMutation({
    mutationFn: putRejectTransactionService,
    mutationKey: [QUERY_KEYS.reject_transaction],
    onSuccess: (response: ResponseType<ImportInterface>) => {
      const queryKey = [QUERY_KEYS.imports_listing, { page: page + 1, limit }];
      const previousImportData: ResponseType<ImportInterface[]> | undefined = queryClient.getQueryData(queryKey);

      if (!previousImportData) return;

      const updatedImportData = [...previousImportData.data];

      const index = updatedImportData.findIndex(importData => importData.id === response.data.id);

      if (index !== -1) {
        updatedImportData[index] = {
          ...updatedImportData[index],
          ...response.data
        };
        queryClient.setQueryData(queryKey, { ...previousImportData, data: updatedImportData });
      }
    },
    onError: (error: ErrorResponse) => errorModal(error)
  });
};
