import styled from "@emotion/styled";

export const StyledSupplierDetailsContainer = styled.div`
  width: 100%;
`;

const StyledText = styled.div`
  font-style: ${props => props.theme.other.font.fontStyle};
  font-family: ${props => props.theme.other.font.fontFamily};
`;

export const StyledLink = styled.a`
  text-decoration: none;
`;

export const StyledAccountName = styled(StyledText)`
  color: ${props => props.theme.other.tertiaryColor};
  padding-top: ${props => props.theme.other.font.header2.paddingTop};
  font-weight: ${props => props.theme.other.font.header2.fontWeight};
  font-size: ${props => props.theme.other.font.header3.fontSize};
  line-height: ${props => props.theme.other.font.header3.lineHeight};
  text-align: start;
`;

export const StyledSupplierName = styled(StyledText)`
  color: ${props => props.theme.other.primaryColor};
  font-size: ${props => props.theme.other.font.header2.fontSize};
  font-weight: ${props => props.theme.other.font.header2.fontWeight};
  line-height: ${props => props.theme.other.font.header2.lineHeight};
  text-align: start;
`;

export const StyledSupplierNameWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${props => props.theme.other.font.header2.paddingBottom};
  padding-top: 0.5rem;

  @media screen and (max-width: ${props => props.theme.other.breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
  }
`;

export const StyledEditSupplierButton = styled.div`
  @media screen and (max-width: ${props => props.theme.other.breakpoints.md}) {
    display: flex;
    align-self: center;
  }
`;

export const StyledDetailsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 2.5rem;

  @media screen and (max-width: ${props => props.theme.other.breakpoints.md}) {
    flex-direction: column-reverse;
    gap: 2rem;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledDetailsWrapper = styled.div`
  display: flex;
  gap: 2rem;

  @media screen and (max-width: ${props => props.theme.other.breakpoints.xl}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0rem;
  }
`;

export const StyledRedirectButtonsWrapper = styled.div`
  ${({ theme }) => `
display: flex;
flex-direction: column;
gap: 1rem;

.mantine-Select-input {
  border: 0.0625rem solid ${theme.other.primaryColor};
  color: ${theme.other.primaryColor};
  font-weight: 700;
}
`}
`;

export const StyledDetail = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-size: ${props => props.theme.other.font.fontSize};
  line-height: ${props => props.theme.other.font.lineHeight};
  color: ${props => props.theme.other.monochromeColor[2]};
  white-space: nowrap;
  font-weight: 700;
  display: flex;
  gap: 7px;
  align-items: center;
  margin-bottom: 15px;
  padding-right: 1rem;
  align-items: flex-start;

  svg {
    color: ${({ theme }) => theme.other.primaryColor};
  }
`;

export const StyledMethodWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const StyledDetailValue = styled.span<{ color?: string; italic?: boolean; link?: boolean }>`
  color: ${props => props.color};
  font-style: ${props => (props.italic ? "italic" : "normal")};
  cursor: ${props => (props.link ? "pointer" : "inherit")};
  font-weight: 400;
  text-align: start;
  white-space: normal;
  max-width: 300px;
`;

export const HiddenBox = styled.div`
  opacity: 0;
  user-select: none;
  pointer-events: none;
`;

export const StyledCardsWrapper = styled.div`
  margin: 40px 0 0 0;
`;

export const StyledLabelWrapper = styled.div`
  width: 100%;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    min-height: 680px;
    overflow-x: auto;
    overflow-y: hidden;
  }
`;

export const StyledGraphLabel = styled.div`
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.375rem;
  text-align: center;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 7px;
  @media (max-width: 768px) {
    position: sticky;
    left: 0;
  }
`;
