import { NavLink } from "react-router-dom";

import styled from "@emotion/styled";
import { Avatar, AvatarProps, Container } from "@mantine/core";
import { KloopifyLogo } from "ui/icons";

export const StyleTabBackgroundWrapper = styled.div`
  background: ${props => props.theme.other.monochromeColor[5]};
  position: relative;
  z-index: 1;
`;

export const StyleMenuWrapper = styled(Container)`
  max-width: 1366px;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  @media only screen and (max-width: 1440px) {
    max-width: none;
  }
`;
export const StyledNavWrapper = styled(Container)`
  max-width: 1366px;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  @media only screen and (max-width: 1440px) {
    max-width: 75%;
  }
`;

export const StyleUserProfileName = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: ${props => props.theme.other.font.fontSize};
  line-height: ${props => props.theme.other.font.lineHeight};
  text-align: center;
  color: ${props => props.theme.other.primaryColor};
  max-width: 800px;
`;

export const StyledKloopifyLogo = styled(KloopifyLogo)`
  height: 45px;
  width: 142px;
`;

export const StyleUserProfileImage = styled(Avatar)<AvatarProps>`
  border: 4px solid ${props => props.theme.other.secondaryColor};
  border-radius: 50%;
  width: 52px;
  height: 52px;
  cursor: pointer;
`;
export const StyleLogoutButton = styled.div`
  width: 131px;
  height: 57.67px;
  background: #ffffff;
  border-radius: 41.195px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 11.86px;
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: ${props => props.theme.other.font.fontSize} !important;
  line-height: ${props => props.theme.other.font.lineHeight};
  color: #5c5c5c;
`;

export const NavigationTabsContainer = styled.nav`
  display: flex;
  gap: 45px;

  & > a {
    text-decoration: none !important;
  }

  @media (max-width: 1100px) {
    display: none;
  }
`;

export const NavigationTab = styled.span<{ isActive: boolean; tabContent: string }>`
  position: relative;
  cursor: pointer;
  padding: 0;
  font-size: 1rem;
  background-color: transparent;
  text-align: left;
  box-sizing: border-box;
  position: relative;
  white-space: nowrap;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: ${props => props.theme.other.font.navigation.lineHeight};
  height: 62px;
  border: 0;
  font-family: Inter;
  font-style: normal;
  font-size: ${props => props.theme.other.font.navigation.fontSize};
  padding-top: ${({ isActive }) => (isActive ? "4px" : "0")};
  color: ${({ isActive, theme }) => (isActive ? theme.other.monochromeColor[5] : theme.other.monochromeColor[1])};
  border-bottom: ${({ isActive, theme }) => (isActive ? `4px solid ${theme.other.primaryColor}` : "none")};
  width: auto;

  &::before {
    position: absolute;
    font-weight: 700;
    visibility: ${({ isActive }) => (isActive ? "visible" : "hidden")};
    color: ${({ isActive, theme }) => (isActive ? theme.other.primaryColor : theme.other.monochromeColor[1])};
    content: "${({ tabContent }) => tabContent}";
  }
`;

export const StyledNavLink = styled(NavLink)`
  text-decoration: none;
`;
