import styled from "@emotion/styled";
import { Avatar, AvatarProps } from "@mantine/core";

export const Wrapper = styled.div`
  width: 100%;
  @media only screen and (max-width: 1366px) {
    width: calc(100% - 20px);
    margin-left: auto;
    margin-right: auto;
  }
`;
export const StyledHeader = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: ${props => props.theme.other.font.header2.fontWeight};
  padding-top: ${props => props.theme.other.font.header2.paddingTop};
  font-size: ${props => props.theme.other.font.header2.fontSize};
  line-height: ${props => props.theme.other.font.header2.lineHeight};
  color: ${props => props.theme.other.primaryColor};
  padding-bottom: ${props => props.theme.other.font.header2.paddingBottom};
  width: 100%;
  text-align: left;
  height: fit-content;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.xl}) {
    text-align: center;
  }
`;
export const ErrorText = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 3rem;
  color: ${props => props.theme.other.primaryColor};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
export const BodyWrapper = styled.div`
  width: 100%;
  height: calc(100% - 240px);
  display: flex;
  gap: 67px;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.xl}) {
    display: block;
  }
`;
export const StyleUserProfileImageWrapper = styled.div`
  position: relative;
  width: fit-content;
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 31px;
`;
export const UserAvatar = styled(Avatar)<AvatarProps>`
  border-radius: 50%;
  width: 10.125rem;
  height: 10.125rem;
  border: 2px solid ${props => props.theme.other.primaryColor};
`;
export const EditProfile = styled.div`
  background-color: ${props => props.theme.other.primaryColor};
  color: ${props => props.theme.other.monochromeColor[6]};
  border: 1px solid ${props => props.theme.other.monochromeColor[6]};
  width: 38px;
  height: 38px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 0;
`;
export const FormWrapper = styled.div`
  max-width: 358px;
  min-height: fit-content;
  width: 100%;
  height: fit-content;
  background: ${props => props.theme.other.monochromeColor[6]};
  border: 1px solid ${props => props.theme.other.monochromeColor[3]};
  border-radius: 4px;
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.xl}) {
    margin-bottom: 3.125rem;
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 1.25rem);
  }
`;
export const InputWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
  margin-bottom: 14px;
  width: 100%;
`;
export const SaveButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: auto;
  margin-top: 6px;
  margin-bottom: 23px;
  max-width: 300px;
  width: 100%;
`;
export const HeaderText = styled.div<{ noMarginTop?: boolean }>`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: ${props => props.theme.other.font.header3.fontSize};
  line-height: ${props => props.theme.other.font.header3.lineHeight};
  color: ${props => props.theme.other.primaryColor};
  text-align: left;
  margin-bottom: 1.2rem;
  margin-top: ${props => (props.noMarginTop ? "0" : "2")}rem;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.xl}) {
    text-align: center;
  }
`;

export const HeaderTextInline = styled(HeaderText)`
  display: flex;
  align-content: center;
  gap: 21px;

  @media only screen and (max-width: ${props => props.theme.other.breakpoints.xl}) {
    justify-content: space-between;
  }
`;

export const ContactDetails = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: ${props => props.theme.other.font.fontSize};
  line-height: ${props => props.theme.other.font.lineHeight};
  color: ${props => props.theme.other.monochromeColor[2]};
  text-align: left;
  margin-bottom: 2rem;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.xl}) {
    text-align: center;
  }
`;
export const MyProfileInfoAndNotificationsWrapper = styled.div`
  margin-bottom: 30px;
  width: 100%;
`;
export const Container = styled.div`
  border-bottom: 1px solid ${props => props.theme.other.monochromeColor[3]};
  min-width: 100%;

  @media only screen and (max-width: ${props => props.theme.other.breakpoints.xl}) {
    max-width: 358px;
    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 1.25rem);
    min-width: unset;
  }
`;
export const NumberOfAccounts = styled.span`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: ${props => props.theme.other.font.fontSize};
  line-height: ${props => props.theme.other.font.lineHeight};
  color: ${props => props.theme.other.monochromeColor[2]};
  margin-left: 12px;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.xl}) {
    display: none;
  }
`;
export const NumberOfAccountsMobileView = styled.div`
  display: none;
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: ${props => props.theme.other.font.fontSize} !important;
  line-height: ${props => props.theme.other.font.lineHeight};
  color: ${props => props.theme.other.monochromeColor[2]};
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.xl}) {
    display: block;
  }
`;

export const AccountTabWrapper = styled.div<{ centered: boolean }>`
  width: 100%;
  text-align: justify;
  padding-bottom: 2rem;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.xl}) {
    text-align: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: ${props => (props.centered ? "center" : "unset")};
  }
`;

export const NotificationDate = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: ${props => props.theme.other.font.paragraph.fontSize};
  line-height: ${props => props.theme.other.font.paragraph.lineHeight};
  color: ${props => props.theme.other.monochromeColor[2]};
`;
export const NotificationMessage = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: ${props => props.theme.other.font.paragraph.fontSize};
  line-height: ${props => props.theme.other.font.paragraph.lineHeight};
  color: ${props => props.theme.other.monochromeColor[1]};
`;
export const NotificationContainer = styled.div<{ striped: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  text-align: left;
  padding: 10px 40px 10px 10px;
  position: relative;
  background-color: ${props =>
    props.striped ? props.theme.other.otherColorRGBA[0] : props.theme.other.monochromeColor[6]};
`;

export const NotificationWrapper = styled.div`
  overflow: auto;
  max-height: 400px;
`;

export const NoNotification = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: ${props => props.theme.other.font.fontSize};
  line-height: ${props => props.theme.other.font.lineHeight};
  color: ${props => props.theme.other.monochromeColor[2]};
`;
