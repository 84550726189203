import { ColumnDef } from "@tanstack/react-table";
import { LinkComponent } from "components";
import { TCommodity } from "types";
import Checkbox from "ui/inputs/Checkbox";
import Tooltip from "ui/tooltip/commonTooltip/Tooltip";
import NaicsTooltipContent from "ui/tooltipContent/naicsTooltipContent/NaicsTooltipContent";

import { CommodityActionCell } from "./CommodityActionCell";

export const columns: ColumnDef<TCommodity>[] = [
  {
    id: "select",
    cell: ({ row }) => (
      <div>
        <Checkbox
          checked={row.getIsSelected()}
          onChange={row.getToggleSelectedHandler()}
          disabled={!row.getCanSelect()}
        />
      </div>
    ),
    size: 20
  },
  {
    header: "Commodity Name",
    accessorKey: "name",
    enableSorting: false,
    size: 600,
    cell: props => <LinkComponent to={`/commodity/${props.row.original.id}`}>{props.row.original.name}</LinkComponent>
  },
  {
    header: "Supplier Name",
    accessorKey: "supplier.name",
    enableSorting: false,
    size: 500,
    cell: props => (
      <LinkComponent to={`/supplier/${props.row.original.supplier.id}`}>
        {props.row.original.supplier.name}
      </LinkComponent>
    )
  },
  {
    header: "Cost Center",
    accessorKey: "costCenter.name",
    enableSorting: false,
    size: 300
  },
  {
    header: () => {
      return (
        <Tooltip header="NAICS Code">
          <NaicsTooltipContent type="detail" />
        </Tooltip>
      );
    },
    accessorKey: "naicsCode",
    enableSorting: false
  },
  {
    header: () => {
      return (
        <Tooltip header="NAICS Industry">
          <NaicsTooltipContent type="industry" />
        </Tooltip>
      );
    },
    accessorKey: "naicsIndustry",
    enableSorting: false,
    cell: props => {
      const { naicsIndustry } = props.row.original;
      return <>{naicsIndustry || "N/A"}</>;
    }
  },
  {
    header: () => {
      return (
        <Tooltip header="NAICS Sector">
          <NaicsTooltipContent type="sector" />
        </Tooltip>
      );
    },
    accessorKey: "naicsSector",
    enableSorting: false,
    cell: props => {
      const { naicsSector } = props.row.original;
      return <>{naicsSector || "N/A"}</>;
    }
  },
  {
    header: "Action",
    enableSorting: false,
    cell: props => <CommodityActionCell commodity={props.row.original} />,
    meta: {
      align: "center"
    },
    size: 50
  }
];
