import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { Strings } from "constants/translations";
import { errorModal, successModal } from "modules/error/ErrorHandlingModals";
import { ErrorResponse } from "types";

import { putUserDetailsService } from "../api";

export const useUpdateUserMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: putUserDetailsService,
    onSuccess: () => {
      successModal(Strings.user__updated__success);
      queryClient.refetchQueries({ queryKey: [QUERY_KEYS.user_list] });
    },
    onError: (error: ErrorResponse) => errorModal(error)
  });
};
