import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { errorModal } from "modules/error/ErrorHandlingModals";
import { ErrorResponse, TQuestionnaireRequestListPayload } from "types";

import { getQuestionnaireRequestListService } from "../api";

export const useGetQuestionnaireRequestListQuery = (payload: TQuestionnaireRequestListPayload) =>
  useQuery({
    queryKey: [QUERY_KEYS.get_questionnaire_request_list, payload.apiArgs, payload.pathArgs],
    queryFn: () => getQuestionnaireRequestListService(payload),
    useErrorBoundary: true,
    onError: (error: ErrorResponse) => errorModal(error)
  });
