import styled from "@emotion/styled";

export const TooltipContainer = styled.div`
  color: ${({ theme }) => theme.other.monochromeColor[0]};

  h2 {
    color: ${({ theme }) => theme.other.primaryColor};
    font-size: 1.25rem;
    margin: 0;
  }

  p,
  a {
    font-size: ${props => props.theme.other.font.fontSize};
    line-height: ${props => props.theme.other.font.lineHeight};
    color: #292e2a !important;
    font-weight: 400 !important;
  }

  ul {
    padding-left: 0;
    margin-top: 0;

    p {
      margin: 0;
    }
  }

  div {
    margin-top: 1rem;
    p {
      margin: 0 !important;
    }
  }
`;
