import { TAnalyticsGroup } from "types";

export type initialReportsFilters = {
  buyspaceId: null;
  end: string;
  group: TAnalyticsGroup;
  interval?: string | undefined;
  start: string;
  timeWindow: string;
};

export type MapNamings = {
  [key: string]: string | undefined;
};

export enum InsightsCardTypeEnum {
  co2e = "co2e",
  intensity = "intensity",
  spend = "spend"
}
