import styled from "@emotion/styled";
import { Stack } from "@mantine/core";

export const InsightsCardsContainer = styled.div`
  padding-top: 0.625rem;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: auto;
  grid-gap: 80px;

  @media screen and (max-width: 1400px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }
`;

export const InsightTableWrapper = styled(Stack)`
  margin-top: 4rem;
  table {
    width: calc(100% + 4rem);
    margin-left: -2rem;
  }
`;

export const InsightsLayout = styled.div`
  position: relative;
  min-height: 30vh;
`;
