import { Text } from "@mantine/core";

export const renderIntensityStatus = (intensity: number, mean: number, sd: number) => {
  if (intensity >= mean + sd * 2) {
    return (
      <Text color="#B20B33" fw="bold">
        Very High
      </Text>
    );
  }

  if (mean + sd < intensity && intensity < mean + 2 * sd) {
    return (
      <Text c="#B20B33" fw="bold">
        High
      </Text>
    );
  }

  if (mean - sd <= intensity && intensity <= mean + sd) {
    return <Text fw="bold">Average</Text>;
  }

  if (intensity < mean - sd) {
    return <Text fw="bold">Low</Text>;
  }

  return <Text fw="bold">No Data</Text>;
};

export const getIntensityStatus = (intensity: number, mean: number, sd: number) => {
  if (intensity >= mean + sd * 2) {
    return { color: "#B20B33", title: "Very High" };
  }

  if (mean + sd < intensity && intensity < mean + 2 * sd) {
    return { color: "#B20B33", title: "High" };
  }

  if (mean - sd < intensity && intensity < mean + sd) {
    return { title: "Average" };
  }

  if (intensity <= mean - sd) {
    return { title: "Low" };
  }

  return { title: "No Data" };
};
