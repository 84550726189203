import axiosInstance from "services/AxiosInstance";
import { ResponseType, TQuestionnaireRequest, TQuestionnaireRequestListPayload } from "types";

export type GetQuestionnaireRequestListByAccountServiceArgs = Omit<TQuestionnaireRequestListPayload, "pathArgs"> & {
  pathArgs: {
    accountId: string;
  };
};

export const getQuestionnaireRequestListByAccountService = async (
  payload: GetQuestionnaireRequestListByAccountServiceArgs
): Promise<ResponseType<TQuestionnaireRequest[], { totalCount: number }>> => {
  const { pathArgs, apiArgs } = payload;
  const response = await axiosInstance.get(`questionnaire/request/account/${pathArgs.accountId}/`, {
    params: apiArgs
  });
  return response.data;
};
