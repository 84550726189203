import styled from "@emotion/styled";

export const NameCellContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.875rem;
`;

export const NameCellTitle = styled.h1`
  color: ${({ theme }) => theme.other.monochromeColor[2]};
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
  max-width: 300px;
`;

export const NameCellSubtitle = styled.h2`
  color: ${({ theme }) => theme.other.monochromeColor[2]};
  font-size: 0.875rem;
  font-weight: 700;
  margin: 0;
  max-width: 300px;
`;

export const NameCellParagraph = styled.p`
  color: ${({ theme }) => theme.other.monochromeColor[2]};
  font-size: 0.875rem;
  margin: 0;
  white-space: nowrap;
`;

export const NameCellSpan = styled.span<{ color?: string }>`
  color: ${({ color, theme }) => {
    if (color) {
      return color;
    }
    return theme.primaryColor;
  }};
`;
