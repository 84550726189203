import ReactApexChart from "react-apexcharts";

import { useTheme } from "@emotion/react";
import { ApexOptions } from "apexcharts";
import { decimalFormatter, prettyMoney } from "utils/formatting.utils";

import { LineChartProps } from "./LineChartTypes";

const LineChartView = ({ series, xAxis }: LineChartProps) => {
  const {
    other: { primaryColor, secondaryColor, warningColor }
  } = useTheme();

  const chartData: ApexOptions = {
    chart: {
      height: "auto",
      width: "auto",
      type: "line",
      stacked: false,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    responsive: [
      {
        breakpoint: 768,
        options: {
          chart: {
            width: 1200,
            height: 600
          },
          dataLabels: {
            enabled: false
          },
          legend: {
            position: "bottom"
          },
          plotOptions: {
            bar: {
              columnWidth: "50%"
            }
          }
        }
      }
    ],
    markers: { size: [4, 4, 4] },
    colors: [primaryColor, secondaryColor, warningColor],
    series,
    stroke: {
      curve: "smooth",
      width: [4, 4, 4]
    },
    plotOptions: {
      bar: {
        columnWidth: "20%"
      }
    },
    xaxis: {
      categories: xAxis,
      labels: {
        show: true,
        rotate: -90,
        rotateAlways: true
      }
    },
    yaxis: [
      {
        axisTicks: {
          show: true
        },
        axisBorder: {
          show: true,
          color: primaryColor
        },
        labels: {
          style: {
            colors: primaryColor
          },
          formatter: (val: number) => prettyMoney(val)
        },
        title: {
          text: "Spend (USD)",
          style: {
            color: primaryColor
          }
        }
      },
      {
        opposite: true,
        axisTicks: {
          show: true
        },
        axisBorder: {
          show: true,
          color: secondaryColor
        },
        labels: {
          style: {
            colors: secondaryColor
          },
          formatter: val => decimalFormatter(val) + " tons"
        },
        title: {
          text: "CO₂ equivalent emissions",
          style: {
            color: secondaryColor
          }
        }
      },
      {
        show: false,
        labels: {
          formatter: val => decimalFormatter(val) + " CO₂e tons per $1M"
        }
      }
    ],
    tooltip: {
      shared: true,
      intersect: false,
      x: {
        show: true
      }
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "left",
      offsetX: 40
    }
  };

  return <ReactApexChart options={chartData} series={chartData.series} />;
};

export const ChartLine = ({ series, xAxis }: LineChartProps) => {
  return <LineChartView series={series} xAxis={xAxis} />;
};
