import { memo } from "react";
import { useParams } from "react-router-dom";

import { LinkComponent } from "components";
import { prettyNumber } from "utils";

import { SubValue } from "../styles";

export const InsightsSupplierCell: React.FC<{ items: number; naicsCodeId: string; suppliers: number }> = memo(
  ({ suppliers, items, naicsCodeId }) => {
    const { id } = useParams();
    return (
      <div>
        <LinkComponent
          to={`/analysis/${id}/reports?group=supplier&naicsCodes[]=${naicsCodeId}`}
          style={{ textDecoration: "underline" }}
        >
          {suppliers}
        </LinkComponent>
        <SubValue>{prettyNumber(items)} Products</SubValue>
      </div>
    );
  }
);
