import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { LoadingOverlay, Text } from "@mantine/core";
import { getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from "@tanstack/react-table";
import { TQuestionnaireRequest } from "types";
import { Table } from "ui";
import Loader from "ui/feedback/Loader";
import Pagination from "ui/navigation/Pagination";
import { stringifySorting } from "utils";

import { useGetQuestionnaireRequestListByAccountQuery } from "../../hooks";
import { QuestionnaireTableWrapper } from "../../questionnaire.styles";
import { questionnaireRequestListColumns } from "./columns";

type QuestionnaireRequestListTableProps = {
  setSorting: Dispatch<SetStateAction<SortingState>>;
  sorting: SortingState;
};

const limit = 10;

export const QuestionnaireRequestListTable = ({ sorting, setSorting }: QuestionnaireRequestListTableProps) => {
  const { id } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const page = searchParams.get("page") || 0;

  const queryParams = {
    supplierIds: searchParams.getAll("supplierIds") || [],
    statuses: searchParams.getAll("statuses") || [],
    maxRespondBy: searchParams.get("maxRespondBy") || null,
    sortBy: searchParams.get("sortBy") || undefined
  };

  const { data, isLoading } = useGetQuestionnaireRequestListByAccountQuery({
    pathArgs: { accountId: id as string },
    apiArgs: { limit, page: +page, ...queryParams }
  });

  const onPageChange = (value: number) =>
    setSearchParams(params => {
      if (value === 1) {
        params.delete("page");
        return params;
      }
      params.set("page", (value - 1).toString());
      return params;
    });

  const handleSorting = () => {
    const sortBy = stringifySorting(sorting);
    setSearchParams(params => {
      if (sortBy) {
        params.set("sortBy", sortBy);
      } else {
        params.delete("sortBy");
      }
      return params;
    });
  };

  const memoizedData = useMemo(() => data?.data || [], [data?.data]);

  const table = useReactTable({
    data: memoizedData,
    columns: questionnaireRequestListColumns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    sortDescFirst: true,
    state: {
      sorting
    }
  });

  useEffect(() => handleSorting(), [sorting]);

  if (data?.meta.totalCount === 0)
    return (
      <QuestionnaireTableWrapper>
        <Text size="xs" ta="center">
          No Questionnaire Requests Found
        </Text>
      </QuestionnaireTableWrapper>
    );

  return (
    <QuestionnaireTableWrapper>
      <Table<TQuestionnaireRequest> table={table} />
      <Pagination setPage={onPageChange} totalRecords={data?.meta?.totalCount} activePage={+page + 1} limit={limit} />
      <LoadingOverlay visible={isLoading} overlayBlur={2} loader={<Loader />} />
    </QuestionnaireTableWrapper>
  );
};
