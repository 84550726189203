import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { IBuyspace, IBuyspaceFilters } from "types";
import { Table } from "ui";
import Loader from "ui/feedback/Loader";
import { TopHeader } from "ui/layouts/common";
import Pagination from "ui/navigation/Pagination";
import { parseQueryParams, stringifyQueryParams } from "utils/query.utils";

import { initialBuyspaceFilters } from "./buyspaces.data";
import { BuyspaceModal, columns } from "./components";
import { useGetBuyspacesQuery } from "./hooks";
import { Container, Header, TableWrapper } from "./styles";

export const Buyspaces = () => {
  const navigate = useNavigate();

  const { id } = useParams() as { id: string };

  const { search } = useLocation();

  const initialQueryParams = stringifyQueryParams(parseQueryParams(search));

  const initialFitlers = { ...initialBuyspaceFilters, ...parseQueryParams(search) };

  const [buyspaceFilters, setBuyspaceFilters] = useState(initialFitlers);

  const [queryParams, setQueryParams] = useState(initialQueryParams);

  const { data, isLoading, isInitialLoading, refetch } = useGetBuyspacesQuery({ id, ...buyspaceFilters });

  const table = useReactTable({
    data: data?.data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  const updateBuyspaceFilters = (updatedFilters?: Partial<IBuyspaceFilters>) => {
    const newFilters = { ...buyspaceFilters, ...updatedFilters };
    const newQueryParams = stringifyQueryParams(newFilters);
    setBuyspaceFilters(newFilters);
    setQueryParams(newQueryParams);
  };

  const handlePage = (page: number) => {
    updateBuyspaceFilters({ page: page - 1 });
  };

  const onPageUpdate = () => {
    if (search !== `?${queryParams}`) navigate(`/buyspaces/${id}?${queryParams}`);
  };

  useEffect(() => {
    onPageUpdate();
  }, [buyspaceFilters.page]);

  useEffect(() => {
    if (!isInitialLoading) refetch();
  }, [search]);

  if (isLoading) return <Loader />;

  return (
    <Container>
      <Header>
        <TopHeader title="Buyspaces" description={`${data?.meta.itemsCount || 0} Buyspaces`} noPadding />
        <BuyspaceModal />
      </Header>
      <TableWrapper>
        <Table<IBuyspace> table={table} />
      </TableWrapper>
      <Pagination
        activePage={Number(buyspaceFilters.page + 1)}
        setPage={handlePage as Dispatch<SetStateAction<number>>}
        totalRecords={data?.meta.totalCount}
        limit={buyspaceFilters.limit}
      />
    </Container>
  );
};
