import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { errorModal } from "modules/error/ErrorHandlingModals";
import { ErrorResponse } from "types";

import { getSupplierListService } from "../api";

export const useGetSupplierListQuery = (
  accountId: string | undefined,
  params: Record<string, number | string | null>,
  options?: { enabled: boolean }
) =>
  useQuery({
    queryKey: [QUERY_KEYS.suppliers, { ...params }, accountId],
    queryFn: () => getSupplierListService(accountId, params),
    onError: (error: ErrorResponse) => errorModal(error),
    useErrorBoundary: true,
    staleTime: Infinity,
    enabled: options?.enabled
  });
