import { useState } from "react";
import { useParams } from "react-router-dom";

import { QuestionnaireStatus } from "types";
import Modal from "ui/overlays/Modal";

import { DynamicQuestionnaire, QCForm, QCPreviewLayout, QuestionnaireCreatorLayout } from "./components";
import { qcSelectProductQuestion } from "./components/QuestionnaireCreator/questionnaireCreator.data";
import { usePostQuestionnaireMutation } from "./hooks";
import { QCFormValues, QCPreviewValues } from "./questionnaire.types";
import { questionnaireRequestDataInitialValues, transformValuesToQuestionnaire } from "./questionnaire.utils";

export const QuestionnaireCreator = () => {
  const { mutate: onQuestionnaireSave, isLoading } = usePostQuestionnaireMutation();

  const { id } = useParams() as { id: string };

  const [previewData, setPreviewData] = useState<QCPreviewValues | undefined>();
  const [submitData, setSubmitData] = useState<QCFormValues | undefined>();

  const onPreviewOpen = (previewData: QCPreviewValues, data: QCFormValues) => {
    setPreviewData(previewData);
    setSubmitData(data);
  };

  const onPreviewClose = () => {
    setPreviewData(undefined);
    setSubmitData(undefined);
  };

  const onSave = (values: QCFormValues) => {
    const payload = transformValuesToQuestionnaire(
      values.productQuestionList.length
        ? { ...values, productQuestionList: [qcSelectProductQuestion, ...values.productQuestionList] }
        : values
    );

    onQuestionnaireSave({ payload: { ...payload, status: QuestionnaireStatus.ACTIVE }, apiArgs: { accountId: id } });
  };

  return (
    <QuestionnaireCreatorLayout>
      <QCForm onSubmit={onSave} onPreview={onPreviewOpen} isLoading={isLoading} />
      <Modal
        opened={!!previewData}
        handleClose={onPreviewClose}
        modalProps={{
          fullScreen: true,
          transitionProps: { transition: "fade", timingFunction: "ease", duration: 100 }
        }}
      >
        {previewData && submitData && (
          <QCPreviewLayout
            title={previewData.title}
            description={previewData.description}
            onClickReturn={onPreviewClose}
            onSave={() => onSave(submitData)}
            isLoading={isLoading}
          >
            <DynamicQuestionnaire
              questionnaireData={{
                ...previewData,
                status: QuestionnaireStatus.DRAFT,
                accountId: id,
                id: ""
              }}
              questionnaireRequestData={questionnaireRequestDataInitialValues}
              submitEnabled={false}
              readOnly
              approveEnabled={false}
            />
          </QCPreviewLayout>
        )}
      </Modal>
    </QuestionnaireCreatorLayout>
  );
};
