import { Link, useParams } from "react-router-dom";

import { Button, Divider, Flex } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { QuestionnaireTableHeaderContainer } from "modules/questionnaire/questionnaire.styles";
import { PlusIcon } from "ui/icons";
import { TopHeader } from "ui/layouts/common";

import { QuestionnaireRequestFilterModal } from "./QuestionnaireRequestFilterModal";
import { QuestionnaireRequestListModal } from "./QuestionnaireRequestListModal";

export const QuestionnaireRequestListHeader = () => {
  const { id } = useParams();

  const to = `${window.location.origin}/questionnaires/${id}`;

  const [opened, { close, open }] = useDisclosure();

  return (
    <>
      <QuestionnaireTableHeaderContainer>
        <TopHeader title="Questionnaire Requests" />
        <Button size="lg" variant="outline" component={Link} to={to}>
          View Questionnaires
        </Button>
      </QuestionnaireTableHeaderContainer>
      <Divider />
      <Flex justify="space-between" mt="xl">
        <QuestionnaireRequestFilterModal />
        <Button size="lg" leftIcon={<PlusIcon />} onClick={open}>
          New Request
        </Button>
      </Flex>
      <QuestionnaireRequestListModal opened={opened} onClose={close} />
    </>
  );
};
