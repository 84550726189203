import { useMemo } from "react";

import { monochromeColor, primaryColor, warningColor } from "constants/colorPalette";
import { ReportsMethodologyCell } from "modules/analytics/components/ReportsMethodologyCell";
import { TMethodologyObject } from "types";
import { formatPercentagePoints } from "utils/common.utils";

import { StyledDetail, StyledDetailValue, StyledMethodWrapper } from "../supplierDetails.styles";

export const SupplierMethodDetail = ({ methodology }: { methodology: TMethodologyObject }) => {
  const { sb, cb, ss } = methodology;

  const sbValue = useMemo(() => formatPercentagePoints(sb), [sb]);
  const cbValue = useMemo(() => formatPercentagePoints(cb), [cb]);
  const ssValue = useMemo(() => formatPercentagePoints(ss), [ss]);

  return (
    <StyledDetail>
      Method:
      <StyledMethodWrapper>
        <StyledDetailValue color={warningColor}>{sbValue}% Transaction Based</StyledDetailValue>
        <StyledDetailValue color={monochromeColor[2]}>{cbValue}% Commodity-Based</StyledDetailValue>
        <StyledDetailValue color={primaryColor}>{ssValue}% Supplier-Specific</StyledDetailValue>
        <ReportsMethodologyCell methodology={methodology} width="100%" />
      </StyledMethodWrapper>
    </StyledDetail>
  );
};
