import axiosInstance from "services/AxiosInstance";
import { ResponseType } from "types";

export const postImportSignedUrlService = async (
  importId: string,
  type: "analysis" | "transactions"
): Promise<ResponseType<string>> => {
  const requestUrl = `imports/${importId}/upload-url/${type}`;
  const response = await axiosInstance.post(requestUrl);
  return response.data;
};
