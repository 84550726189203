import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter } from "react-router-dom";

import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthGuard } from "components/AuthGuard";
import { theme } from "constants/theme";
import ErrorHandler from "modules/error/ErrorHandler";
import { createRoot } from "react-dom/client";
import LocalStorageDisabledError from "ui/errors/LocalStorageDisabledError";
import { isLocalStorageEnabled } from "utils/common.utils";

import App from "./App";
import { componentsOverride, headingsOverride } from "./mantine.config";
import reportWebVitals from "./reportWebVitals";

import "./index.css";

const root = createRoot(document.getElementById("root") as HTMLElement);
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <MantineProvider
          theme={{ ...theme, components: componentsOverride, headings: headingsOverride, fontFamily: "Inter" }}
          withNormalizeCSS
          withGlobalStyles
        >
          <Notifications position="top-right" />
          <ErrorBoundary FallbackComponent={ErrorHandler}>
            <ModalsProvider>
              {!isLocalStorageEnabled() ? (
                <LocalStorageDisabledError />
              ) : (
                <AuthGuard>
                  <App />
                </AuthGuard>
              )}
            </ModalsProvider>
          </ErrorBoundary>
        </MantineProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
