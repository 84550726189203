import { useEffect, useMemo } from "react";

import { useForm } from "@mantine/form";
import { useGetAccountFeatureFlagListQuery } from "modules/account";
import { errorModal, successModal } from "modules/error/ErrorHandlingModals";
import {
  TUserFeatureFlagModalForm,
  useGetFeatureFlagsQuery,
  usePutFeatureFlagMutation,
  UserFeatureFlagsModalFooter
} from "modules/users";
import {
  UserFeatureFlagsModalContainer,
  UserFeatureFlagsModalDescription,
  UserFeatureFlagsModalHeader
} from "modules/users/components/styles";
import { TAccount, TFeatureFlag } from "types";
import { mergeFeaturesFlags } from "utils";

import { AccountFeatureFlagModalBody } from "./AccountFeatureFlagModalBody";

type AccountFeatureFlagModalProps = {
  account: TAccount;
  close: () => void;
  opened: boolean;
};

export const AccountFeatureFlagModal = ({ close, opened, account }: AccountFeatureFlagModalProps) => {
  const { name } = account;

  const form = useForm<TUserFeatureFlagModalForm>({
    initialValues: { features: [], accountId: account.id }
  });

  const { getInputProps, onSubmit, setFieldValue } = form;

  const accountId = getInputProps("accountId").value;

  const updatedFeatures = getInputProps("features").value as TFeatureFlag[];

  const { data: featureFlags, isSuccess: featureFlagsSuccess } = useGetFeatureFlagsQuery();

  const { data: accountFeatureFlags, isSuccess: accountFeatureFlagsSuccess } = useGetAccountFeatureFlagListQuery({
    accountId
  });

  const features = useMemo(() => {
    if (!accountFeatureFlagsSuccess || !featureFlagsSuccess) return [];

    const base = featureFlags.data;

    const extension = accountFeatureFlags.data;

    return mergeFeaturesFlags(base, extension);
  }, [accountFeatureFlags?.data]);

  const onSuccess = () => {
    close();
    successModal("Feature Flags have been successfully updated.");
  };

  const onError = () => {
    close();
    errorModal("Feature Flags could not be updated right now. Please try again later.");
  };

  const { mutate } = usePutFeatureFlagMutation({ onSuccess, onError });

  const submit = () => mutate({ accountId, features: updatedFeatures, previousData: features });

  useEffect(() => setFieldValue("features", features), [features]);

  return (
    <UserFeatureFlagsModalContainer
      opened={opened}
      handleClose={close}
      withCloseButton
      title={
        <div>
          <UserFeatureFlagsModalHeader>Feature Flags</UserFeatureFlagsModalHeader>
          <UserFeatureFlagsModalDescription>
            Select Features to be turned on for <i>{name}</i>
          </UserFeatureFlagsModalDescription>
        </div>
      }
    >
      <form onSubmit={onSubmit(submit)}>
        <AccountFeatureFlagModalBody formInstance={form} />
        <UserFeatureFlagsModalFooter close={close} />
      </form>
    </UserFeatureFlagsModalContainer>
  );
};
