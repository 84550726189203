import { Button } from "@mantine/core";

import { DeleteNotificationFooter } from "./deleteNotification.styles";

export const DeleteNotification: React.FC<{ onUndo: () => void }> = ({ onUndo }) => (
  <div>
    <p>You can reverse deletion within 5 seconds.</p>
    <DeleteNotificationFooter>
      <Button size="xs" onClick={onUndo} color="klp">
        Undo
      </Button>
    </DeleteNotificationFooter>
  </div>
);
