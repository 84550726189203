import React from "react";

import {
  QuestionnaireCreatorLayout as Layout,
  QuestionnaireCreatorBody,
  QuestionnaireCreatorContainer,
  QuestionnaireCreatorTop,
  QuestionnaireCreatorTopDescription
} from "./questionnaireCreator.styles";

type QuestionnaireCreatorLayoutProps = {
  children: React.ReactNode;
};

export const QuestionnaireCreatorLayout: React.FC<QuestionnaireCreatorLayoutProps> = ({ children }) => {
  return (
    <Layout>
      <QuestionnaireCreatorContainer>
        <QuestionnaireCreatorTop>
          <h1>Create New Questionnaire</h1>
        </QuestionnaireCreatorTop>
        <QuestionnaireCreatorTopDescription>
          <p>
            Questionnaires consist of Supplier-level and Product-level questions. Supplier Questions are general
            questions about a supplier's organization. Product questions can have multiple answers provided that
            correspond with different products from the supplier. For example, you may ask a supplier to upload a PCF
            (Product Carbon Footprint) information file for each of their products in your organization's import data.
          </p>
          <p>
            For Product Questions, you have the option of mapping a questionnaire's responses to attributes of
            commodities in our system. For example, you might ask a supplier to provide a description and item SKU for
            each of their products in your organization's import data. Once the supplier's responses are approved, the
            product's records in our system will be updated.
          </p>
        </QuestionnaireCreatorTopDescription>
        <QuestionnaireCreatorBody>{children}</QuestionnaireCreatorBody>
      </QuestionnaireCreatorContainer>
    </Layout>
  );
};
