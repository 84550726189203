import { memo } from "react";

import { Text } from "@mantine/core";
import moment from "moment";
import { QuestionnaireRequestStatus, TQuestionnaireRequest } from "types";

import { isOverdue } from "../analytics.utils";
import { SubValue } from "../styles";

export const InsightsDueCell = memo(
  ({ questionnaireRequestList }: { questionnaireRequestList: TQuestionnaireRequest[] }) => {
    const overdueRequests = questionnaireRequestList.filter(i => isOverdue(i));

    const latestQuestionnaireRequest =
      overdueRequests.length > 0
        ? overdueRequests[overdueRequests.length - 1]
        : questionnaireRequestList.sort((a, b) => moment(b.updatedAt).valueOf() - moment(a.updatedAt).valueOf())[0];

    const updatedAtDate = moment(latestQuestionnaireRequest.updatedAt).format("MM/DD/YYYY");

    const respondByDate = moment(latestQuestionnaireRequest.respondBy).format("MM/DD/YYYY");

    const color = isOverdue(latestQuestionnaireRequest) ? "#B20B33" : undefined;

    const subText =
      latestQuestionnaireRequest.status === QuestionnaireRequestStatus.IN_PROGRESS
        ? `Last Update ${updatedAtDate}`
        : `Sent ${updatedAtDate}`;

    return (
      <>
        <Text color={color} fw="bold">
          {respondByDate}
        </Text>
        <SubValue>{subText}</SubValue>
      </>
    );
  }
);
