import { createContext, useContext } from "react";

import { initialSupplierDetailsModal } from "./supplierDetails.data";
import { ISupplierDetailsModal } from "./supplierDetails.types";

type SupplierDetailsContextOptions = {
  modal: ISupplierDetailsModal;
  modalController: (modal: Partial<ISupplierDetailsModal>) => void;
};

const initialSupplierDetailsContext: SupplierDetailsContextOptions = {
  modalController: () => {},
  modal: initialSupplierDetailsModal
};

export const SupplierDetailsContext = createContext<SupplierDetailsContextOptions>(initialSupplierDetailsContext);

export const useSupplierDetailsContext = () => {
  const ctx = useContext(SupplierDetailsContext);

  if (!ctx) {
    throw new Error("useSuppliersContext must be used within a SuppliersContext.Provider");
  }

  return ctx;
};
