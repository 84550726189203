import { useEffect, useState } from "react";

import { Button, LoadingOverlay } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useGetCustomFields } from "modules/customFields";
import { CreateCustomFieldForm } from "modules/customFields/components/CreateCustomFieldForm";
import {
  useGetImportTransactionMetaQuery,
  useHandleMapping,
  useUpdateTransactionMetaMutation
} from "modules/imports/hooks";
import { ModalBodyMessage, ModalFooter, ModalTitle, Table } from "ui";
import Loader from "ui/feedback/Loader";
import Modal, { ModalProps } from "ui/overlays/Modal";

import { ImportHeaderType } from "../../types";
import { useImportContext } from "../ImportContext";
import { ImportMapModalContext } from "./ImportMapModalContext";
import { columns } from "./columns";
import { requiredKloopifyFields } from "./importMapModal.data";
import { BottomDangerText, DangerText, TableContainer } from "./importMapModal.styles";
import { MappedColumn } from "./importMapModal.types";

export const EditMapModal = (props: ModalProps) => {
  const [opened, { close, open: onCreateCustomField }] = useDisclosure();

  const { state, onModalClose, onSelectedAccountId } = useImportContext();

  const [headers, setHeaders] = useState<ImportHeaderType[]>([]);

  const {
    data: columnData,
    isLoading: isColumnDataLoading,
    refetch: columnRefetch
  } = useGetImportTransactionMetaQuery({ importId: state?.active?.id as string }, { enabled: !!state?.active?.id });

  const { isLoading, dataUpdatedAt } = useHandleMapping({
    importId: state?.active?.id as string,
    isStatusCreate: state?.active?.status === "CREATED",
    onSuccess: values => {
      setHeaders(values);
      columnRefetch();
    }
  });

  useEffect(() => {
    if (headers.length > 0 && columnData) {
      setHeaders(state => {
        const newState = state.map(value => {
          const findColumnValue = columnData.data.columns.find(v => v.source === value.csvHeaderKey);
          if (findColumnValue) {
            return {
              ...value,
              kloopifyFieldKey: findColumnValue.target,
              csvHeaderKey: findColumnValue.source
            };
          }
          return value;
        });

        return newState;
      });
    }
  }, [headers.length, dataUpdatedAt]);

  const onUpdateTransactionMeta = useUpdateTransactionMetaMutation({ importId: state?.active?.id as string });

  const onChange = (csvHeaderKey: string, kloopifyFieldKey: string) => {
    if (kloopifyFieldKey === "newCustomField") {
      onCreateCustomField();
      return;
    }

    setHeaders(state => {
      // First, find if the kloopifyFieldKey is assigned to the csvHeaderKey
      // If yes, remove value and assign to the currently selected
      // (One-to-one mapping)

      let currentState = [...state];

      const isCurrentlyAssignedIndex = state.findIndex(
        stateCsvHeader => kloopifyFieldKey === stateCsvHeader.kloopifyFieldKey
      );

      if (isCurrentlyAssignedIndex !== -1) {
        const removeValueFromMappedArray = currentState.map((s, idx) => {
          if (idx === isCurrentlyAssignedIndex) {
            return { ...s, kloopifyFieldKey: null };
          }
          return s;
        });

        currentState = removeValueFromMappedArray;
      }

      // Now, update the csvHeaderKey with a new value

      const fieldIndex = currentState.findIndex(stateCsvHeader => stateCsvHeader.csvHeaderKey === csvHeaderKey);

      if (fieldIndex !== -1) {
        return currentState.map((s, idx) => {
          if (idx === fieldIndex) {
            return { ...s, kloopifyFieldKey };
          }
          return s;
        });
      }
      return state;
    });
  };

  const onError = (kloopifyFieldKey: string, error: boolean) => {
    setHeaders(prev => {
      const currentState = [...prev];

      const fieldIndex = currentState.findIndex(stateCsvHeader => kloopifyFieldKey === stateCsvHeader.kloopifyFieldKey);

      if (fieldIndex !== -1) {
        return currentState.map((s, idx) => {
          if (idx === fieldIndex) {
            return { ...s, error };
          }
          return s;
        });
      }

      return prev;
    });
  };

  const table = useReactTable({
    data: headers,
    columns,
    getCoreRowModel: getCoreRowModel()
  });

  const onClose = () => {
    onModalClose();
    onSelectedAccountId(undefined);
  };

  const handleSubmit = async () => {
    const columns = headers
      .filter(({ kloopifyFieldKey }) => kloopifyFieldKey !== null)
      .map(({ kloopifyFieldKey, csvHeaderKey }) => ({
        target: kloopifyFieldKey,
        source: csvHeaderKey
      })) as MappedColumn[];

    if (state?.active?.id) {
      await onUpdateTransactionMeta.mutateAsync({ importId: state.active.id, payload: { columns } });
    }

    onClose();
  };

  const assignedFields = headers.map(({ kloopifyFieldKey }) => kloopifyFieldKey).filter(fieldKey => fieldKey);

  const errorMissingFields = requiredKloopifyFields
    .filter(requiredField => !assignedFields.includes(requiredField.field))
    .map(({ label }) => label)
    .join(", ");

  const invalidFields = headers.filter(({ error }) => error).map(({ kloopifyFieldKey }) => kloopifyFieldKey);

  const requiredFieldsErrors =
    requiredKloopifyFields
      .filter(requiredField => invalidFields.includes(requiredField.field))
      .map(({ label }) => label) || [];

  const requiredHeaders = requiredKloopifyFields.map(({ label }) => label);

  const { data: customFieldData } = useGetCustomFields({ accountId: state?.active?.accountId as string });

  const customInvalidFieldsErrors =
    invalidFields
      .filter(invalidField => invalidField && !requiredHeaders.includes(invalidField))
      .map(customFieldId => {
        const findCustomField = customFieldData?.data?.find(customField => customFieldId === customField.id);
        if (findCustomField) {
          return findCustomField.fieldLabel || findCustomField.field;
        }
        return null;
      })
      .filter(customFieldName => customFieldName) || [];

  const errorInvalidFields = [...requiredFieldsErrors, ...customInvalidFieldsErrors].join(", ");

  const disabled = !!errorInvalidFields || !!errorMissingFields;
  return (
    <Modal {...props} withCloseButton mode="big">
      <ModalTitle centered>Edit the Column Map</ModalTitle>
      <ModalBodyMessage centered>
        Kloopify matches your transactions with 5 required fields: Transaction Date (compatible with <br /> all
        formats), Transaction Cost, Item Description, Cost Center (your business unit), and Supplier <br /> Name. Simply
        align your CSV file's fields with these Kloopify columns.
      </ModalBodyMessage>
      <ModalBodyMessage centered>
        <DangerText>
          {errorMissingFields &&
            `${errorMissingFields} remaining to be mapped. Please use
          the dropdown below and map it to a File Field.`}
        </DangerText>
      </ModalBodyMessage>
      <ImportMapModalContext.Provider value={{ headers, onChange, onCreateCustomField, onError }}>
        <TableContainer>
          <LoadingOverlay loader={<Loader />} visible={isLoading || isColumnDataLoading} />
          <Table<ImportHeaderType> table={table} />
        </TableContainer>
      </ImportMapModalContext.Provider>
      <BottomDangerText>
        {errorInvalidFields && `*Invalid Format for ${errorInvalidFields}, Please fix to continue.`}
      </BottomDangerText>
      <ModalFooter centered>
        <Button size="lg" disabled={disabled} onClick={handleSubmit} loading={false}>
          Submit
        </Button>
      </ModalFooter>
      <Modal opened={opened} withCloseButton handleClose={close}>
        <ModalTitle centered>New Custom Field</ModalTitle>
        <CreateCustomFieldForm onClose={close} initialAccountId={state?.active?.accountId} />
      </Modal>
    </Modal>
  );
};
