import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { errorModal } from "modules/error/ErrorHandlingModals";
import { postSupplierService } from "modules/supplierDetails/api";
import { ErrorResponse } from "types";

export const useCreateNewSupplierMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postSupplierService,
    mutationKey: [QUERY_KEYS.create_new_supplier],
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [QUERY_KEYS.suppliers] });
    },
    useErrorBoundary: true,
    onError: (error: ErrorResponse) => errorModal(error)
  });
};
