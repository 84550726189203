import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { errorModal } from "modules/error/ErrorHandlingModals";
import { ErrorResponse } from "types";

import { getCommodityListService } from "../api/getCommodityListService";

export const useGetCommodityListQuery = (
  query: Record<string, number | string | string[] | null>,
  limit: number,
  accountId?: string,
  options?: { enabled: boolean }
) =>
  useQuery({
    queryKey: [QUERY_KEYS.get_items, { ...query }],
    queryFn: () => getCommodityListService(query, limit, accountId),
    onError: (error: ErrorResponse) => errorModal(error),
    useErrorBoundary: true,
    enabled: options?.enabled
  });
