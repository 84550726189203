import styled from "@emotion/styled";
import Modal from "ui/overlays/Modal";

export const AdditionalFilterModal = styled(Modal)`
  .mantine-Modal-content {
    padding: 1rem;
  }
`;

export const AdditionalFilterHeader = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 3.8125rem;
  text-align: left;
  color: ${props => props.theme.other.primaryColor};
  margin-bottom: 1.5rem;
`;

export const AdditionalFilterTitle = styled.div<{ inline?: boolean }>`
  padding-bottom: ${props => (props.inline ? "" : "0.5rem")};
  font-size: ${props => props.theme.other.font.modalGroupHeader.fontSize};
  line-height: ${props => props.theme.other.font.modalGroupHeader.lineHeight};
  font-weight: ${props => props.theme.other.font.modalGroupHeader.fontWeight};
  color: ${({ theme }) => theme.other.monochromeColor[2]};
`;

export const AdditionalFilterInputWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 1rem;
`;

export const AdditionalFilterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @media screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    gap: 2rem;
  }
`;

export const AdditionalFilterTopSpacer = styled.div`
  padding-top: 1rem;
`;
