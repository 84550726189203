import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";

import { Button } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { TAnalyticsCustomInterval, TOverviewDashboardModal } from "types";
import Checkbox from "ui/inputs/Checkbox";
import { getDateNoTime } from "utils";

import { customIntervalInitial } from "../analytics.data";
import {
  StyledCustomIntervalContainer,
  StyledCustomIntervalHeader,
  StyledCustomIntervalModal,
  StyledCustomIntervalRow,
  StyledCustomIntervalRowTitle,
  StyledCustomIntevalFooter,
  StyledCustomIntevalInputWrapper
} from "../styles";

type ReportsIntervalModalProps = {
  applyChanges?: (updatedState: TIntervalUpdateState) => void;
  interval: TAnalyticsCustomInterval;
  opened: boolean;
  setCustomInterval: Dispatch<SetStateAction<TAnalyticsCustomInterval>>;
  setModal: Dispatch<SetStateAction<TOverviewDashboardModal>>;
};

export type TIntervalUpdateState = {
  end?: string;
  start?: string;
};

export const ReportsIntervalModal: FC<ReportsIntervalModalProps> = ({
  interval,
  opened,
  applyChanges,
  setModal,
  setCustomInterval
}) => {
  const [customInterval, setCustomInteval] = useState<TAnalyticsCustomInterval>(interval);

  const updateCustomInterval = (changes: Partial<TAnalyticsCustomInterval>) => {
    setCustomInteval(prevChanges => ({ ...prevChanges, ...changes }));
  };

  const handleApplyRange = () => {
    setCustomInterval(prevInterval => ({ ...prevInterval, ...customInterval }));
    if (applyChanges) {
      applyChanges({
        start: customInterval.start || undefined,
        end: customInterval.end || undefined
      });
    }
    setModal(prev => ({ ...prev, customInterval: false }));
  };

  const handleReset = () => updateCustomInterval(customIntervalInitial);

  useEffect(() => {
    if (opened) setCustomInteval(interval);
  }, [opened]);

  return (
    <StyledCustomIntervalModal
      opened={opened}
      setOpened={() => setModal(prev => ({ ...prev, customInterval: false }))}
      withCloseButton
      handleClose={() => setModal(prev => ({ ...prev, customInterval: false }))}
    >
      <StyledCustomIntervalHeader>Select Custom Data Range</StyledCustomIntervalHeader>
      <StyledCustomIntervalContainer>
        <StyledCustomIntervalRow>
          <div>
            <StyledCustomIntervalRowTitle>Start Date</StyledCustomIntervalRowTitle>
            <StyledCustomIntevalInputWrapper>
              <Checkbox checked={!!customInterval.start} onChange={() => updateCustomInterval({ start: null })} />
              <DateInput
                placeholder="Start Date"
                valueFormat="MM/DD/YYYY"
                onChange={value => updateCustomInterval({ start: getDateNoTime(value as Date) })}
                value={customInterval.start ? new Date(customInterval.start) : null}
                popoverProps={{
                  withinPortal: true
                }}
              />
            </StyledCustomIntevalInputWrapper>
          </div>
          <div>
            <StyledCustomIntervalRowTitle>End Date</StyledCustomIntervalRowTitle>
            <StyledCustomIntevalInputWrapper>
              <Checkbox checked={!!customInterval.end} onChange={() => updateCustomInterval({ end: null })} />
              <DateInput
                placeholder="End Date"
                valueFormat="MM/DD/YYYY"
                onChange={value => updateCustomInterval({ end: getDateNoTime(value as Date) })}
                value={customInterval.end ? new Date(customInterval.end) : null}
                popoverProps={{
                  withinPortal: true
                }}
              />
            </StyledCustomIntevalInputWrapper>
          </div>
        </StyledCustomIntervalRow>
      </StyledCustomIntervalContainer>
      <StyledCustomIntevalFooter>
        <Button variant="subtle" onClick={handleReset}>
          Reset Range
        </Button>
        <Button onClick={handleApplyRange}>Apply Range</Button>
      </StyledCustomIntevalFooter>
    </StyledCustomIntervalModal>
  );
};
