import { useGetClassificationListQueries } from "modules/classification";
import { IndustryClassification, TAnalyticsFilters } from "types";
import FilterTile from "ui/tiles/FilterTile/FilterTile";

import { initialReportsFilters } from "../analytics.types";
import { mapFilterNamings, mapFilterValues } from "../analytics.utils";
import { ReportsFilterTilesContainer } from "../styles";

type ReportsFilterTilesProps = {
  filters: TAnalyticsFilters;
  onFilterChange: (filters: keyof initialReportsFilters, value?: string | null) => void;
};

const hiddenFilters = ["accountId", "start", "end", "group", "timeWindow", "buyspaceId"];

export const ReportsFilterTiles: React.FC<ReportsFilterTilesProps> = ({ onFilterChange, filters }) => {
  const tiles = Object.entries(filters)
    .filter(param => (typeof param[1] === "string" && param[1]) || (Array.isArray(param[1]) && param[1].length > 0))
    .filter(param => !hiddenFilters.includes(param[0]))
    .map(param => {
      const name = param[0];
      const value = param[1];
      if (Array.isArray(value)) {
        return value.map(v => ({
          name,
          value: v
        }));
      }

      return {
        name,
        value
      };
    })
    .flatMap(tile => tile);

  const codes = [...(filters.naicsCodes || []), ...(filters.naicsIndustries || []), ...(filters.naicsSectors || [])];

  const codeQueries = useGetClassificationListQueries({ codes });

  const naicsCodes = codeQueries
    .map(({ data }) => {
      const codeData = data?.data[0] as IndustryClassification;
      return codeData;
    })
    .filter(data => data);

  const mapNaicsCodes = naicsCodes.reduce((acc, code) => ({ ...acc, [code.code]: code.title }), {});

  const extendValuesMappingWithBuyspaces: { [key: string]: string | undefined } = {
    ...mapFilterValues,
    ...mapNaicsCodes
  };

  if (!tiles.length) return null;

  return (
    <ReportsFilterTilesContainer>
      {tiles.map(tile => (
        <FilterTile
          key={`${tile.name}-${tile.value}`}
          text={`${mapFilterNamings?.[tile.name] || [tile.name]}: ${
            typeof tile.value === "string" ? extendValuesMappingWithBuyspaces?.[tile.value] || tile.value : tile.value
          }`}
          onClick={() => onFilterChange(tile.name as keyof initialReportsFilters, tile.value)}
        />
      ))}
    </ReportsFilterTilesContainer>
  );
};
