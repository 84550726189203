import { ChangeEvent, useEffect, useState } from "react";

import styled from "@emotion/styled";
import { Button, Textarea } from "@mantine/core";
import Modal from "ui/overlays/Modal";
import { ModalFooter, ModalTitle } from "ui/styles";

import { ConfirmationDeleteModal } from "../components";
import { useRejectAnalysisMutation } from "../hooks";
import { SuccessModal } from "./SuccessModal";

const WrapperModalBody = styled.div`
  position: relative;
`;

const Description = styled.div`
  margin-top: 5px;
  margin-bottom: 11px;
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5625rem;
  color: #5c5c5c;
  max-width: 368px;
  width: 100%;
`;

const TextInputWrapper = styled.div`
  margin-top: 5.5px;
  gap: 10px;

  .mantine-Checkbox-label {
    text-wrap: nowrap;
  }
  @media only screen and (max-width: 430px) {
    display: grid;
  }
`;

type RejectAnalysisModalProps = {
  handleClose: () => void;
  importId: string | null;
  openModal: boolean;
};

export const RejectAnalysisModal = ({ openModal, handleClose, importId }: RejectAnalysisModalProps) => {
  const { mutate, isError, isSuccess, data } = useRejectAnalysisMutation();

  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const [rejectionReason, setRejectionReason] = useState("");
  const [reason, setReason] = useState("");

  const handleRejectAnalysis = () => {
    if (reason) setOpenDeleteConfirmationModal(true);
  };

  const handleConfirmAction = () => {
    if (importId) mutate({ importId, reason });
  };

  useEffect(() => {
    if (isSuccess) {
      setRejectionReason(data?.data?.rejectedReasonNote);
      handleClose();
      setOpenDeleteConfirmationModal(false);
      setOpenSuccessModal(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      setOpenDeleteConfirmationModal(false);
      setOpenSuccessModal(false);
    }
  }, [isError]);

  useEffect(() => {
    if (openModal === false) setReason("");
  }, [openModal]);

  return (
    <>
      <Modal
        opened={openModal}
        handleClose={handleClose}
        withCloseButton
        title={<ModalTitle $type="danger">Reject Analysis File</ModalTitle>}
        mode="message"
      >
        <WrapperModalBody>
          <form
            onSubmit={e => {
              e.preventDefault();
            }}
          >
            <Description>Please specify the reason this Transactions File Analysis was rejected below:</Description>
            <TextInputWrapper>
              <Textarea
                value={reason}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => setReason(e.currentTarget.value)}
                placeholder="Please specify..."
                minRows={6}
              />
            </TextInputWrapper>
            <ModalFooter>
              <Button
                variant="subtle"
                onClick={() => {
                  handleClose();
                }}
                type="button"
              >
                Cancel Rejection
              </Button>
              <Button
                onClick={() => {
                  handleRejectAnalysis();
                }}
              >
                Submit
              </Button>
            </ModalFooter>
          </form>
        </WrapperModalBody>
      </Modal>
      <ConfirmationDeleteModal
        openModal={openDeleteConfirmationModal}
        handleClose={() => setOpenDeleteConfirmationModal(false)}
        handleConfirm={() => handleConfirmAction()}
        cancelBtnText={"Cancel"}
        confirmBtnText={"Reject"}
        titleText="Reject Analysis File?"
        descriptionText="Please confirm that you would like to reject this analysis file. Afterwards, a new analysis file will need to be uploaded to complete the import."
      />
      <SuccessModal
        titleText="Analysis File Rejected"
        descriptionText={`This analysis file was rejected because ${rejectionReason}`}
        openModal={openSuccessModal}
        setOpenModal={setOpenSuccessModal}
      />
    </>
  );
};
