import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { errorModal } from "modules/error/ErrorHandlingModals";
import { ErrorResponse } from "types";

import { postQuestionnaireRequestRejectService } from "../api";

export const usePostQuestionnaireRequestRejectMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [QUERY_KEYS.post_questionnaire_request_reject],
    mutationFn: postQuestionnaireRequestRejectService,
    useErrorBoundary: true,
    onError: (error: ErrorResponse) => errorModal(error),
    onSuccess: payload => {
      queryClient.refetchQueries([QUERY_KEYS.get_questionnaire_request_list]);
      queryClient.refetchQueries([QUERY_KEYS.get_questionnaire_request, payload.data.id]);
    }
  });
};
