import { createContext, useContext } from "react";

type InsightContextOptions = {
  onRangeChange: (dates: string) => void;
};

const initialInsightContext: InsightContextOptions = {
  onRangeChange: () => {}
};

export const InsightContext = createContext<InsightContextOptions>(initialInsightContext);

export const useInsightContext = () => {
  const context = useContext(InsightContext);

  if (!context) {
    throw new Error("useInsightContext must be used within a InsightContext.Provider");
  }

  return context;
};
