import { useMutation } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { errorModal } from "modules/error/ErrorHandlingModals";
import { ErrorResponse } from "types";

import { postQuestionnaireRequestService } from "../api/postQuestionnaireRequestService";

export const usePostQuestionnaireRequestMutation = () => {
  return useMutation({
    mutationKey: [QUERY_KEYS.post_questionnaire_request],
    mutationFn: postQuestionnaireRequestService,
    onError: (error: ErrorResponse) => errorModal(error)
  });
};
