import { useTheme } from "@emotion/react";
import { Button } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { DeleteNotificationFooter } from "modules/customFields/components/deleteNotification.styles";
import { errorModal } from "modules/error/ErrorHandlingModals";
import { ErrorResponse } from "types";
import { CheckCircleIcon } from "ui/icons";

import { postQuestionnaireService } from "../api";

export const usePostQuestionnaireMutation = () => {
  const queryClient = useQueryClient();

  const {
    other: { primaryColor }
  } = useTheme();

  return useMutation({
    mutationKey: [QUERY_KEYS.post_questionnaire],
    mutationFn: postQuestionnaireService,
    onError: (error: ErrorResponse) => errorModal(error),
    onSuccess: payload => {
      queryClient.refetchQueries([QUERY_KEYS.get_questionnaire_request_list]);
      queryClient.refetchQueries([QUERY_KEYS.get_questionnaire_request, payload.data.id]);
      notifications.show({
        title: "Success",
        message: (
          <div>
            <p>Your questionnaire has been created! This view will be automatically closed in the 5 seconds.</p>
            <DeleteNotificationFooter>
              <Button size="xs" onClick={() => window.close()}>
                Close immediately
              </Button>
            </DeleteNotificationFooter>
          </div>
        ),
        autoClose: 5000,
        icon: <CheckCircleIcon />,
        color: primaryColor,
        onClose: () => window.close(),
        styles: () => ({
          icon: {
            backgroundColor: `${primaryColor} !important`
          }
        })
      });
    }
  });
};
