import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import { SortingState } from "@tanstack/react-table";
import { parseSorting } from "utils";

import {
  QuestionnaireRequestListFilterTiles,
  QuestionnaireRequestListHeader,
  QuestionnaireRequestListTable
} from "./components";
import { QuestionnairesContainer } from "./questionnaire.styles";

export const QuestionnaireRequestList = () => {
  const [searchParams] = useSearchParams();

  const [sorting, setSorting] = useState<SortingState>(parseSorting(searchParams.get("sortBy") || ""));

  return (
    <QuestionnairesContainer>
      <QuestionnaireRequestListHeader />
      <QuestionnaireRequestListFilterTiles setSorting={setSorting} />
      <QuestionnaireRequestListTable sorting={sorting} setSorting={setSorting} />
    </QuestionnairesContainer>
  );
};
