import { useParams } from "react-router-dom";

import { Text } from "@mantine/core";
import { LinkComponent } from "components";
import { TReportsDataSource } from "types";

export const InsightCommodityLink: React.FC<
  TReportsDataSource & { items: number; mean: number; sDeviation: number; suppliers: number }
> = ({ description, naicsCodeId }) => {
  const { id } = useParams();

  return (
    <LinkComponent to={`/analysis/${id}/reports?group=item&naicsCodes[]=${naicsCodeId}`}>
      <Text c="#313c34" fw="bold" td="underline">
        {description}
      </Text>
    </LinkComponent>
  );
};
