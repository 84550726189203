import styled from "@emotion/styled";
import { Divider } from "@mantine/core";
import { Grid } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import Modal from "ui/overlays/Modal";

export const QuestionnaireResponseCenterer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const QuestionnaireResponseContainer = styled.main`
  width: 80%;
  box-shadow: 0px 3px 24px rgba(28, 50, 79, 0.1);
  padding: 70px 70px 50px 70px;
  margin: 5% auto;
  background: ${({ theme }) => theme.other.monochromeColor[6]};
`;

export const QuestionnaireResponseHeader = styled.header`
  h1 {
    color: ${({ theme }) => theme.other.primaryColor};
    font-size: ${({ theme }) => theme.other.font.modalGroupHeader.fontSize};
    line-height: ${({ theme }) => theme.other.font.modalGroupHeader.lineHeight};
    padding-bottom: 13px;
    margin: 0;
  }

  h2 {
    color: ${({ theme }) => theme.other.primaryColor};
    font-weight: 400;
    font-size: ${({ theme }) => theme.other.font.modalGroupHeader.fontSize};
    line-height: ${({ theme }) => theme.other.font.modalGroupHeader.lineHeight};
    padding-bottom: 20px;
    margin: 0;
  }

  p {
    color: ${({ theme }) => theme.other.monochromeColor[2]};
    font-size: ${({ theme }) => theme.other.font.fontSize};
    line-height: ${({ theme }) => theme.other.font.lineHeight};

    span {
      font-weight: 700;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const QuestionnaireResponseContent = styled(Grid)`
  padding-bottom: 45px;
  h1 {
    color: ${({ theme }) => theme.other.tertiaryColor};
    font-size: ${({ theme }) => theme.other.font.modalGroupHeader.fontSize};
    line-height: ${({ theme }) => theme.other.font.modalGroupHeader.lineHeight};
    margin: 0;
  }
`;

export const QuestionnaireQuestion = styled.div`
  padding-top: 20px;
`;

export const QuestionnaireResponseFooter = styled.footer`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 22px;
  padding-top: 45px;
`;

export const QuestionnaireTableHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-style: ${props => props.theme.other.font.fontStyle};
  font-family: ${props => props.theme.other.font.fontFamily};
`;

export const QuestionnaireTableHeaderTitle = styled.div`
  color: ${props => props.theme.other.tertiaryColor};
  font-size: ${props => props.theme.other.font.header3.fontSize};
  line-height: ${props => props.theme.other.font.header3.lineHeight};
  font-weight: ${props => props.theme.other.font.header2.fontWeight};
`;

export const QuestionnaireNewRequestButton = styled.div`
  color: ${props => props.theme.other.primaryColor};
  font-size: ${props => props.theme.other.font.fontSize};
  line-height: ${props => props.theme.other.font.lineHeight};
  font-weight: 700;
  cursor: pointer;
  user-select: none;

  &:hover {
    filter: brightness(1.1);
  }
`;

export const QuestionnaireRequestModalContainer = styled(Modal)`
font-size: ${props => props.theme.other.font.fontSize}
font-family: ${props => props.theme.other.font.fontFamily};
font-style: ${props => props.theme.other.font.fontStyle};

h1 {
  margin: 0;
  color: ${props => props.theme.other.primaryColor};
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.6rem
  text-align: left;
}

h2 {
  margin: 0;
  color: ${({ theme }) => theme.other.tertiaryColor};
  padding-bottom: 12px;
}

p {
  margin: 0;
  padding-bottom: 11px;
  color: ${({ theme }) => theme.other.monochromeColor[2]};
  font-size: ${({ theme }) => theme.other.font.fontSize};
  line-height: ${({ theme }) => theme.other.font.lineHeight};

  span {
    font-weight: 700;
  }
}

.mantine-Modal-content {
  max-width: 471px;
  min-height: 41px;
  padding: 1rem;
}`;

export const QuestionnaireRequestModalRow = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 29px;
`;

export const QuestionnaireRequestModalRowContent = styled.div<{ alc?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${props => (props.alc ? "center" : "flex-start")};
  gap: 20px;
`;

export const QuestionnaireRequestCalendar = styled(DatePicker)<{ error: string }>`
  border: ${props => (props.error ? "1px solid red" : "")};

  .mantine-Calendar-day[data-selected] {
    background-color: ${({ theme }) => theme.other.primaryColor};
  }
`;

export const QuestionnaireInputError = styled.div`
  color: rgb(250, 82, 82);
  font-size: ${({ theme }) => theme.other.font.paragraph.fontSize};
  font-weight: ${({ theme }) => theme.other.font.paragraph.fontWeight};
  font-family: ${({ theme }) => theme.other.font.fontFamily};
  align-self: flex-start;
  padding-top: 6px;
`;

export const QuestionnaireTableWrapper = styled.div`
  overflow-x: auto;
  position: relative;
  margin-top: ${props => props.theme.other.table.marginTop};
  margin-bottom: ${props => props.theme.other.table.marginBottom};
`;

export const QuestionnaireActionCellMenuTarget = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const QuestionnaireStatusCell = styled.div`
  text-transform: capitalize;
`;

export const QuestionnaireDetailsModal = styled(Modal)`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};

  h1 {
    margin: 0;
    color: ${props => props.theme.other.primaryColor};
    font-weight: 700;
    text-align: left;
    font-size: 18px;
    padding-bottom: 26px;
  }

  h2 {
    margin: 0;
    color: ${({ theme }) => theme.other.tertiaryColor};
    padding-bottom: 12px;
    font-size: 16px;
  }

  .mantine-Modal-content {
    width: 924px;
    min-height: 41px;
    padding: 1rem;
  }
`;

export const QuestionnaireDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;
`;

export const QuestionnaireDetailsItem = styled.p`
  margin: 0;
  padding-bottom: 11px;
  color: ${({ theme }) => theme.other.monochromeColor[2]};
  font-size: ${({ theme }) => theme.other.font.fontSize};
  line-height: ${({ theme }) => theme.other.font.lineHeight};
  font-weight: 700;
`;

export const QuestionnaireDetailsItemSpan = styled.span<{ italic?: boolean }>`
  font-style: ${props => (props.italic ? "italic" : "inherit")};
  font-weight: 400 !important;
`;

export const QuestionnaireDetailsProductContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  gap: 30px;
`;

export const QuestionnaireDetailsProductItem = styled.div`
  grid-column: auto / span 4;
  min-width: 200px;

  @media only screen and (max-width: ${({ theme }) => theme.other.breakpoints.md}) {
    grid-column: auto / span 8;
  }
`;

export const VerticalDivider = styled(Divider)`
  grid-column: auto / span 1;
  justify-self: center;

  @media only screen and (max-width: ${({ theme }) => theme.other.breakpoints.md}) {
    display: none;
  }
`;

export const QuestionnaireDetailsActionButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 22px;
`;

export const QuestionnaireFactoryContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;

  p {
    color: ${({ theme }) => theme.other.primaryColor};
    cursor: pointer;
    margin: 0;
  }
`;

export const QuestionnaireContentHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;

  svg {
    cursor: pointer;
  }
`;
export const QuestionnaireFileWrapper = styled.div`
  width: 300px;
  max-width: 100%;
`;

export const QuestionnairesContainer = styled.section`
  width: 100%;
  z-index: 0;
  position: relative;
`;

export const QuestionnaireQuestionRequiredSpan = styled.span`
  color: ${({ theme }) => theme.other.dangerColor};
`;

export const QuestionnaireDetailCard = styled.main`
  width: 80%;
  border: 1px solid ${({ theme }) => theme.other.monochromeColor[4]};
  padding: 70px 70px 50px 70px;
  margin: 5% auto;
  background: ${({ theme }) => theme.other.monochromeColor[6]};
  position: relative;
`;
