import { TooltipConteiner } from "./customFieldTooltipContent.styles";

export const CustomFieldTooptipContent = () => (
  <TooltipConteiner>
    <h2>Custom Fields</h2>
    <p>
      Custom fields let you group data in our app beyond Kloopify's static categories like Supplier, Commodity, and
      NAICS classification. Creating custom fields and mapping htem to columns in your Transactions File uploads during
      the import process lets you group your data by those values later in the Analytics view.
    </p>
    <p>To create a custom field, you need to specify three things:</p>
    <div>
      <p>
        <b>Field ID</b> - This represents the identifier for the column in your data files.
      </p>
      <p>
        <b>Data Type</b> - We currently support grouping by Text and Numerical data types.
      </p>
      <p>
        <b>Field Label</b> - In the app, this will be displayed as the user-friendly label for the Custom Field.
      </p>
    </div>
  </TooltipConteiner>
);
