import ReactApexChart from "react-apexcharts";

import { useTheme } from "@emotion/react";
import { Box } from "@mantine/core";
import { ApexOptions } from "apexcharts";
import moment from "moment";
import { LineChartProps } from "ui";
import { decimalFormatter, prettyMoney } from "utils/formatting.utils";

const CompactLineChartView = ({ series, xAxis }: LineChartProps) => {
  const {
    other: { primaryColor, secondaryColor, warningColor, tertiaryColor }
  } = useTheme();

  const chartData: ApexOptions = {
    grid: {
      show: false
    },
    chart: {
      type: "line",
      stacked: false,
      sparkline: {
        enabled: true
      },
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    markers: { size: 0 },
    colors: [primaryColor, secondaryColor, warningColor],
    series,
    stroke: {
      curve: "smooth"
    },
    xaxis: {
      categories: xAxis,
      axisBorder: {
        show: true,
        offsetY: 0
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false
      },
      crosshairs: {
        show: false
      },
      tooltip: {
        enabled: false
      }
    },
    yaxis: [
      {
        axisTicks: {
          show: false
        },
        axisBorder: {
          show: true,
          offsetX: -15
        },
        labels: {
          formatter: (val: number) => prettyMoney(val),
          show: false
        }
      },
      {
        show: false,
        labels: {
          formatter: val => decimalFormatter(val) + " CO₂e tons per $1M"
        }
      }
    ],
    tooltip: {
      custom: ({ dataPointIndex, w }) => {
        const spend = w.globals.initialSeries[0].data[dataPointIndex];

        const co2e = w.globals.initialSeries[1].data[dataPointIndex];

        const intensity = w.globals.initialSeries[2].data[dataPointIndex];

        const range = w.globals.categoryLabels[dataPointIndex];

        const date = moment(range, "MMM-YYYY").format("MMMM YYYY");

        return (
          '<div style="display: flex; flex-direction: column; padding: 8.5px 24px 8.5px 8.5px;">' +
          `<p style="font-size: 12px; color: ${tertiaryColor}; margin: 0; font-weight: 700;">` +
          prettyMoney(spend) +
          " Spend</p>" +
          `<p style="font-size: 12px; color: ${secondaryColor}; margin: 0; font-weight: 700;">` +
          decimalFormatter(co2e) +
          " CO₂e</p>" +
          '<p style="font-size: 12px; color: orange; margin: 0; font-weight: 700;">' +
          decimalFormatter(intensity) +
          " Intensity</p>" +
          '<p style="font-size: 10px; color: black; margin: 0;">' +
          date +
          "</p>" +
          "</div>"
        );
      },
      shared: true,
      intersect: false,
      x: {
        show: true
      }
    },
    legend: {
      show: false
    }
  };

  return <ReactApexChart options={chartData} series={chartData.series} height={120} width="100%" type="line" />;
};

export const CompactLineChart = ({ series, xAxis }: LineChartProps) => {
  return (
    <Box>
      <CompactLineChartView series={series} xAxis={xAxis} />
    </Box>
  );
};
