import { QuestionnaireRequestStatus, TAnalyticsGroup } from "types";

import { ISupplierDetailsModal } from "./supplierDetails.types";

interface IDefaultAnalyticsFilters {
  start: string;
  end: string;
  group: TAnalyticsGroup;
  timeWindow: string;
  buyspaceId: string | null;
}

export const initialSupplierDetailsModal: ISupplierDetailsModal = {
  updateSupplier: false,
  customInterval: false,
  questionnaireRequest: false,
  questionnaireDetails: false
};

export const defaultAnalyticsFilters: IDefaultAnalyticsFilters = {
  start: "1970-01-01T00:00:00Z",
  end: "2100-01-01T00:00:00Z",
  timeWindow: "mry",
  group: "supplier",
  buyspaceId: null
};

export const QuestionnaireStatusMap = {
  [QuestionnaireRequestStatus.APPROVED]: "Approved",
  [QuestionnaireRequestStatus.SUBMITTED]: "Submitted",
  [QuestionnaireRequestStatus.IN_PROGRESS]: "In progress",
  [QuestionnaireRequestStatus.REJECTED]: "Rejected",
  [QuestionnaireRequestStatus.SENT]: "Sent"
};
