import React, { ReactNode } from "react";

import styled from "@emotion/styled";

const StyleCard = styled.div`
  min-height: calc(168px - 20px);
  height: fit-content;
  border: 1px solid ${props => props.theme.other.monochromeColor[3]};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  padding: 10px;

  @media (max-width: 480px) {
    width: auto;
  }
`;
const StyleLabel = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.375rem;
  text-align: left;
  color: ${props => props.theme.other.monochromeColor[2]};
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  gap: 10px;
`;
const StyleCardNameWrapper = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.375rem;
  text-align: center;
  color: ${props => props.theme.other.monochromeColor[2]};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 48px;
`;
const StyleCardUnitWrapper = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: 3.125rem;
  line-height: 3.8125rem;
  text-align: center;
  color: ${props => props.theme.other.monochromeColor[2]};
  margin-bottom: 5px;

  @media (max-width: 1440px) {
    font-size: 2.5rem;
  }

  @media (max-width: 1200px) {
    font-size: 3.125rem;
  }

  @media (max-width: 480px) {
    font-size: 2.5rem;
  }
`;
const StyledCardDescriptionWrapper = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.1875rem;
  height: 40px;
  text-align: center;
  color: ${props => props.theme.other.monochromeColor[2]};
  max-width: 275px;
`;

interface CardProps {
  label?: string;
  labelIcon?: ReactNode;
  cardName: string;
  cardIcon: ReactNode;
  cardUnit: number | string;
  cardDescription: JSX.Element | string;
  backgroundColor: string;
  style?: React.CSSProperties;
  showLabel?: boolean;
}

const Card: React.FC<CardProps> = ({
  label,
  labelIcon,
  cardName,
  cardIcon,
  cardUnit,
  cardDescription,
  backgroundColor,
  style,
  showLabel = true
}) => {
  // const theme = useMantineTheme()
  const labelComponent = showLabel ? (
    <StyleLabel>
      {labelIcon}
      {label}
    </StyleLabel>
  ) : (
    ""
  );
  return (
    <div>
      {labelComponent}
      <StyleCard style={{ ...style, backgroundColor: backgroundColor }}>
        <div>
          <StyleCardNameWrapper>
            {cardIcon}
            {cardName}
          </StyleCardNameWrapper>
          <StyleCardUnitWrapper>{cardUnit}</StyleCardUnitWrapper>
          <StyledCardDescriptionWrapper>{cardDescription}</StyledCardDescriptionWrapper>
        </div>
      </StyleCard>
    </div>
  );
};
export default Card;
