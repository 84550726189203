import { useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { Divider } from "@mantine/core";
import { UserPermissionGuard } from "components";
import { pick } from "lodash";
import { useGetAnalyticsGrouped, useInsightDates } from "modules/analytics";
import {
  calculateRanks,
  calculateStandardDeviation,
  convertToTabular,
  convertToTimeSeriesData
} from "modules/analytics/analytics.utils";
import { QuestionnaireRequestModal, QuestionnaireTable, QuestionnaireTableHeader } from "modules/questionnaire";
import { ISupplier, UpdateSupplierModal } from "modules/suppliers";
import { Feature, TReportsDataSource } from "types";
import { ChartLine } from "ui";
import Loader from "ui/feedback/Loader";

import { SupplierDetailsContext } from "./SupplierDetails.context";
import { SupplierDetailsCards, SupplierDetailsHeader, SupplierInformation } from "./components";
import { useGetSupplierAnalytics } from "./hooks";
import { defaultAnalyticsFilters, initialSupplierDetailsModal } from "./supplierDetails.data";
import { StyledGraphLabel, StyledLabelWrapper, StyledSupplierDetailsContainer } from "./supplierDetails.styles";
import { ISupplierDetailsModal } from "./supplierDetails.types";

export const SupplierDetails = ({ supplierData }: { supplierData: ISupplier }) => {
  const { id } = useParams();

  const [searchParams] = useSearchParams();

  const [modal, setModal] = useState(initialSupplierDetailsModal);

  const { start, end } = useInsightDates(supplierData.account.id);

  const analyticsParams = {
    ...defaultAnalyticsFilters,
    timeWindow: searchParams.get("timeWindow") || defaultAnalyticsFilters.timeWindow,
    start: searchParams.get("start") || start,
    end: searchParams.get("end") || end
  };

  const supplierParams = pick(analyticsParams, ["start", "end"]);

  const { data: supplierAnalytics, isLoading: isSupplierAnalyticsLoading } = useGetAnalyticsGrouped({
    supplier: id,
    accountId: supplierData?.account.id,
    interval: "month",
    ...analyticsParams
  });

  const { data: accountAnalytics, isLoading: isAccountAnalyticsLoading } = useGetAnalyticsGrouped({
    accountId: supplierData?.account.id,
    ...analyticsParams
  });

  const { data: lineGraphData, isLoading: isLineGraphDataLoading } = useGetSupplierAnalytics({
    id: supplierData?.account.id,
    interval: "month",
    includePending: false,
    supplier: id,
    ...supplierParams
  });

  const isLoading = isSupplierAnalyticsLoading || isLineGraphDataLoading || isAccountAnalyticsLoading;

  const isNoData = !supplierData || !supplierAnalytics || !lineGraphData || !accountAnalytics;

  const seriesData = useMemo(() => convertToTimeSeriesData(lineGraphData?.data || null), [lineGraphData]);

  const accountAnalyticsRanked: (TReportsDataSource & { mean: number; sDeviation: number })[] = useMemo(() => {
    if (!accountAnalytics?.data) return [];
    const tabular = convertToTabular(accountAnalytics.data, accountAnalytics.summary, []);
    const addRanks = calculateRanks(tabular);
    const addIntensityAndSD = calculateStandardDeviation(addRanks.map(({ carbonIntensity }) => carbonIntensity));
    return addRanks.map(data => ({ ...data, ...addIntensityAndSD }));
  }, [accountAnalytics]);

  const modalController = (updatedModal: Partial<ISupplierDetailsModal>) =>
    setModal(modal => ({ ...modal, ...updatedModal }));

  const onEditSupplier = () => modalController({ updateSupplier: true });

  const closeSupplierModal = () => modalController({ updateSupplier: false });

  const closeQuestionnaireRequestModal = () => modalController({ questionnaireRequest: false });

  if (isLoading) return <Loader />;

  if (isNoData) return <div>No suppliers data available</div>;

  return (
    <SupplierDetailsContext.Provider value={{ modalController, modal }}>
      <StyledSupplierDetailsContainer>
        <SupplierDetailsHeader
          onEdit={onEditSupplier}
          accountAnalytics={accountAnalytics.data}
          supplier={supplierData}
        />
        <Divider />
        <SupplierInformation supplierData={supplierData} summary={supplierAnalytics.summary} />
        <SupplierDetailsCards
          supplierSummary={supplierAnalytics.summary}
          accountSummary={accountAnalytics.summary}
          accountAnalyticsRanked={accountAnalyticsRanked}
        />
        <Divider my="3.125rem" />
        <StyledLabelWrapper>
          <StyledGraphLabel>Supplier Activity Over Time</StyledGraphLabel>
          <ChartLine series={seriesData.series} xAxis={seriesData.xAxis} />
        </StyledLabelWrapper>
        <UserPermissionGuard module={Feature.SUPPLIER_QUESTIONNAIRES} excludeExternal>
          <Divider pb={15} />
          <QuestionnaireTableHeader modalController={modalController} />
          <QuestionnaireTable supplier={supplierData} />
        </UserPermissionGuard>
      </StyledSupplierDetailsContainer>
      <UpdateSupplierModal supplier={supplierData} opened={modal.updateSupplier} setOpened={closeSupplierModal} />
      <UserPermissionGuard module={Feature.SUPPLIER_QUESTIONNAIRES} excludeExternal>
        <QuestionnaireRequestModal
          supplier={supplierData}
          opened={modal.questionnaireRequest}
          setOpened={closeQuestionnaireRequestModal}
        />
      </UserPermissionGuard>
    </SupplierDetailsContext.Provider>
  );
};
