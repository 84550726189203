import styled from "@emotion/styled";
import Modal from "ui/overlays/Modal";

export const Wrapper = styled.div`
  width: 100%;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    display: block;
    width: 100%;
  }
`;
export const StyleWrapper = styled.div``;

export const ModalHeader = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: ${props => props.theme.other.font.header2.fontSize};
  line-height: ${props => props.theme.other.font.header2.lineHeight};
  letter-spacing: 0em;
  text-align: center;
  color: ${props => props.theme.other.primaryColor};
`;
export const StyleModalHeaderTitle = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: ${props => props.theme.other.font.fontSize};
  line-height: ${props => props.theme.other.font.lineHeight};
  text-align: center;
  color: ${props => props.theme.other.monochromeColor[1]};
  margin-top: 2rem;
`;
export const StyleModalHeaderDescription = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: ${props => props.theme.other.font.fontSize};
  line-height: ${props => props.theme.other.font.lineHeight};
  color: ${props => props.theme.other.monochromeColor[2]};
  margin-top: 0.4375rem;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 1rem;
`;
export const FormRowWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 19px;
  width: 100%;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    display: block;
    width: 100%;
  }
`;
export const StyleSubmitButton = styled.div<{ maxWidth: string }>`
  max-width: ${props => props?.maxWidth};
  width: 100%;
`;
export const StyleCancelButton = styled.div<{ maxWidth: string }>`
  max-width: ${props => props?.maxWidth};
  width: 100%;
`;
export const ButtonWrapper = styled.div<{ marginTop: string }>`
  display: flex;
  justify-content: space-between;
  align-items: end;
  width: 100%;
  margin-top: ${props => props?.marginTop};
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    gap: 5px;
  }
`;
export const StyleFNameWrapper = styled.div`
  width: 100%;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    max-width: 100%;
  }
`;
export const StyleLNameWrapper = styled.div`
  width: 100%;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    max-width: 100%;
  }
`;
export const StyleEmailWrapper = styled.div`
  width: 100%;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    max-width: 100%;
  }
`;
export const StyleEmailInfoMsg = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 8px;
  color: ${props => props.theme.other.monochromeColor[2]};
`;
export const StyleAccountWrapper = styled.div`
  width: 100%;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    max-width: 100%;
    min-width: 100%;
    margin-top: 1.5625rem;
  }
`;
export const StyleUserRoleWrapper = styled.div`
  width: 100%;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    max-width: 100%;
    min-width: 100%;
    margin-top: 25px;
  }
`;
export const StyleRoleSelectItem = styled.div`
  clear: both;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
`;
export const StyleRoleColorText = styled.span<{ color?: string }>`
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.0625rem;
  color: ${theme => theme.color};
`;

export const UserFeatureFlagsModalContainer = styled(Modal)`
  .mantine-Modal-content {
    padding: 1rem;
  }
`;

export const UserFeatureFlagsModalHeader = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: ${props => props.theme.other.font.header1.fontSize};
  line-height: ${props => props.theme.other.font.header2.lineHeight};
  letter-spacing: 0em;
  text-align: center;
  color: ${props => props.theme.other.primaryColor};
  padding-top: 46px;
`;

export const UserFeatureFlagsModalDescription = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: ${props => props.theme.other.font.fontSize};
  line-height: ${props => props.theme.other.font.lineHeight};
  color: ${props => props.theme.other.monochromeColor[2]};
  margin-top: 0.4375rem;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 1rem;
`;

export const UserFeatureFlagsModalFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
`;

export const UserFeatureFlagsRowWrapper = styled.div<{
  accountFlags?: boolean;
  alignLeft?: boolean;
  withMargin?: boolean;
}>`
  display: flex;
  justify-content: ${props => (props.alignLeft ? "flex-start" : "center")};
  align-items: flex-start;
  gap: 1.2rem;
  width: 100%;
  margin-bottom: 1.2rem;
  margin-top: ${props => (props.withMargin ? "1.2rem" : "")};
  &:first-of-type {
    margin-top: ${props => (props.accountFlags ? "" : "4.375rem")};
  }
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    flex-direction: column;
    width: 100%;
  }
`;

export const UserFeatureFlagsAccountWrapper = styled.div`
  max-width: 48%;
  flex: 1 0;
  margin-top: 10px;

  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    max-width: 100%;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.other.monochromeColor[1]};
    margin-bottom: 7px;
    margin-left: 10px;
  }
`;

export const UserFeatureFlagListContainer = styled.div`
  padding-top: 30px;
`;

export const UserFeatureFlagListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 50px;
  justify-content: space-between;

  p {
    font-weight: 400;
    font-family: ${({ theme }) => theme.other.font.fontFamily};
    color: ${({ theme }) => theme.other.monochromeColor[1]};
    font-size: ${({ theme }) => theme.other.font.fontSize};
    line-height: ${({ theme }) => theme.other.font.lineHeight};
  }
`;
