import { memo } from "react";
import { useParams } from "react-router-dom";

import { LinkComponent } from "components";
import { useTransactionsQuery } from "modules/transactions";
import { TAnalyticsResponse, TQuestionnaireRequest } from "types";

import { useGetAnalyticsGrouped } from "../hooks";
import { SubValue } from "../styles";

export const InsightTransactionCell = memo(({ id }: TQuestionnaireRequest["supplier"]) => {
  const { id: accountId } = useParams();

  const { data } = useTransactionsQuery({ supplierIds: [id] }, 999999, accountId);

  const { data: commoditiesData } = useGetAnalyticsGrouped(
    { accountId, group: "naicsCode", supplier: id },
    { optionsExcludeCounts: false, optionsCache: true }
  );

  const transactions = data?.data.length;

  const top = ((commoditiesData?.data as TAnalyticsResponse[]) || []).sort(
    (a, b) => b.data[0].co2e - a.data[0].co2e
  )[0];

  return (
    <>
      <LinkComponent
        to={`/analysis/${accountId}/reports?group=naicsCode&supplier=${id}`}
        style={{ textDecoration: "underline" }}
      >
        {transactions}
      </LinkComponent>
      <SubValue>Top: {top?.group._name}</SubValue>
    </>
  );
});
