import { FC, Fragment } from "react";

import {
  StyledAccountName,
  StyledSupplierName,
  StyledSupplierNameWrapper
} from "modules/supplierDetails/supplierDetails.styles";

type QuestionnaireResponseContainerHeaderProps = {
  accountName: string;
  name: string;
};

export const QuestionnaireResponseContainerHeader: FC<QuestionnaireResponseContainerHeaderProps> = ({
  name,
  accountName
}) => (
  <Fragment>
    <StyledAccountName>{accountName}</StyledAccountName>
    <StyledSupplierNameWrapper>
      <StyledSupplierName>{name}</StyledSupplierName>
    </StyledSupplierNameWrapper>
  </Fragment>
);
