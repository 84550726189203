import styled from "@emotion/styled";
import { Table } from "@mantine/core";
import Modal from "ui/overlays/Modal";

export const TableWrapper = styled.div`
  margin-top: 2.5rem;
  overflow-x: auto;
`;

export const StyledTableHeader = styled.thead`
  position: sticky;
  top: -1px;
  z-index: 2;

  th:last-child {
    width: 1px;
  }
`;

export const StyledTable = styled(Table)`
  border-collapse: collapse;
  table-layout: auto;
  width: 100%;
  td:last-child,
  th:last-child {
    border-right: none !important;
  }
`;

export const StyledTR = styled.tr`
  td:last-child {
    display: inline-flex;
    align-items: center;
  }
`;

export const StyleTH = styled.th`
  border-right: 1px solid ${props => props.theme.other.monochromeColor[3]} !important;
  background: ${props => props.theme.other.monochromeColor[4]};
  height: 39.5px;
  padding: 5px 15px !important;
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: ${props => props.theme.other.font.fontSize} !important;
  line-height: ${props => props.theme.other.font.lineHeight};
  color: ${props => props.theme.other.monochromeColor[1]} !important;
  border-top-width: 1px;
`;
export const StyleTD = styled.td`
  border-right: 1px solid ${props => props.theme.other.monochromeColor[3]} !important;
  border-top: none !important;
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: ${props => props.theme.other.font.fontSize} !important;
  line-height: ${props => props.theme.other.font.lineHeight};
  color: ${props => props.theme.other.monochromeColor[1]};
  text-align: left;
  height: auto;
  position: relative;
  padding-top: 13px !important;
  padding-bottom: 13px !important;
  padding-left: 0px !important;
`;
export const TableTextWrapper = styled.div`
  margin-left: 10px;
`;

export const TableHeaderOptionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 705px) {
    display: grid;
    gap: 12px;
    justify-content: center;
    width: 100%;
  }
`;
export const StyleButtonSearchWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  @media only screen and (max-width: 705px) {
    display: grid;
    gap: 12px;
    justify-content: center;
    align-items: center;
  }
`;
export const ButtonWrapper = styled.div`
  max-width: 106px;
  width: 100%;
  @media only screen and (max-width: 705px) {
    max-width: 100%;
  }
`;

export const StyleMessage = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-size: 1.5625rem;
  font-weight: 700;
  line-height: 1.875rem;
  letter-spacing: 0em;
  text-align: left;
  color: ${props => props.theme.other.primaryColor};
`;
export const StyleHead = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.1875rem;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 1.2rem;
  color: ${props => props.theme.other.monochromeColor[2]};
`;
export const StyleHeadDetail = styled.div<{ link?: boolean }>`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.1875rem;
  letter-spacing: 0em;
  text-align: left;
  color: ${props => props.theme.other.monochromeColor[2]};
  &:hover {
    text-decoration: ${({ link }) => (link ? "underline" : "none")};
    cursor: ${({ link }) => (link ? "pointer" : "default")};
  }
`;
export const StyledImportDetailModal = styled(Modal)`
  .mantine-Modal-content {
    max-width: 469px;
    min-height: 442px;
    padding: 1rem;
  }
`;

export const StyledTransactionDetailsWrapper = styled.div`
  padding-bottom: 1.2rem;
`;

export const StyledFilterTilesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding-top: 1.375rem;
`;
