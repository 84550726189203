import { Button, ModalProps } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { ModalTitle } from "ui";
import { PlusIcon } from "ui/icons";
import Modal from "ui/overlays/Modal";

import { CreateCustomFieldForm } from "./CreateCustomFieldForm";

type CreateCustomFielModalProps = Partial<
  Pick<ModalProps, "onClose" | "opened"> & { accountId: string; independent: boolean }
>;

export const CreateCustomFieldModal = ({
  opened,
  independent = true,
  accountId,
  onClose
}: CreateCustomFielModalProps) => {
  const [openedDisclosure, { close: closeDisclosure, open: openDisclosure }] = useDisclosure();

  const isModalOpened = opened ?? openedDisclosure;

  const close = onClose ?? closeDisclosure;

  return (
    <>
      {independent && (
        <Button size="xs" rightIcon={<PlusIcon />} onClick={openDisclosure}>
          Add Custom Field
        </Button>
      )}
      <Modal opened={isModalOpened} withCloseButton handleClose={close}>
        <ModalTitle centered>New Custom Field</ModalTitle>
        <CreateCustomFieldForm onClose={close} initialAccountId={accountId} />
      </Modal>
    </>
  );
};
