import React from "react";

import styled from "@emotion/styled";
import { Button } from "@mantine/core";
import Modal from "ui/overlays/Modal";

const StyleMessage = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: 3.125rem;
  line-height: 3.8125rem;
  text-align: center;
  color: ${props => props.theme.other.primaryColor};
  margin-bottom: 19px;
`;
const StyleDescription = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.6875rem;
  text-align: center;
  color: ${props => props.theme.other.monochromeColor[2]};
`;
const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    display: grid;
    justify-content: center;
    gap: 15px;
    align-items: center;
  }
`;

interface ActivateUserModalProps {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleConfirm(status: string): void;
}

const StyledActivateUserModal = styled(Modal)`
  && .mantine-Modal-content {
    max-width: 618px;
    min-height: 300px;
    padding: 1rem;
  }
`;
export const ActivateUserModal: React.FC<ActivateUserModalProps> = ({ openModal, setOpenModal, handleConfirm }) => {
  return (
    <StyledActivateUserModal
      opened={openModal}
      setOpened={setOpenModal}
      onClose={true}
      withCloseButton={true}
      title={""}
    >
      <StyleMessage>Reactivate User?</StyleMessage>
      <StyleDescription>
        Are you sure that you want to reactivate this user? The user’s access will be completely restored and they will
        have access to all data permitted by their role.
      </StyleDescription>
      <ButtonWrapper>
        <Button
          variant="subtle"
          onClick={() => {
            setOpenModal(false);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleConfirm("ACTIVE");
          }}
        >
          Activate User
        </Button>
      </ButtonWrapper>
    </StyledActivateUserModal>
  );
};
