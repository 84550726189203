import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { errorModal } from "modules/error/ErrorHandlingModals";
import { ErrorResponse } from "types";

import { postCommodityMergeService } from "../api/postCommodityMergeService";

export const useCommodityMergeMutation = ({ options }: { options?: { onSuccess: () => void } }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [QUERY_KEYS.post_commodity_merge],
    mutationFn: postCommodityMergeService,
    onError: (error: ErrorResponse) => errorModal(error),
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.get_items]);
      options?.onSuccess();
    }
  });
};
