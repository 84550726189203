import { URLSearchParamsInit, useSearchParams } from "react-router-dom";

import { Button, Grid } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { useForm } from "@mantine/form";
import { SupplierMultiSelect } from "components";
import { useGetSupplierListQueries } from "hooks";
import { ISupplier } from "modules/suppliers";
import moment from "moment";
import { MultiSelect } from "ui";
import { ModalFooter } from "ui";

import { questionnaireRequestOptions } from "../../questionnaire.data";

type Values = {
  maxRespondBy: Date | null;
  statuses: string[];
  supplierIds: string[];
};

type QuestionnaireRequestFormProps = {
  onClose: () => void;
};

export const QuestionnaireRequestForm: React.FC<QuestionnaireRequestFormProps> = ({ onClose }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const maxRespondBy = moment(searchParams.get("maxRespondBy"));

  const maxRespondByValue = maxRespondBy.isValid() ? maxRespondBy.toDate() : null;

  const initialValues = {
    supplierIds: searchParams.getAll("supplierIds") || [],
    statuses: searchParams.getAll("statuses") || [],
    maxRespondBy: maxRespondByValue
  };

  const form = useForm<Values>({
    initialValues
  });

  const supplierListData = useGetSupplierListQueries({ supplierIds: searchParams.getAll("supplierIds") || [] });

  const initialSupliers = supplierListData
    ?.map(supplierData => supplierData.data)
    .filter((data): data is ISupplier => data !== undefined);

  const onReset = () => {
    form.reset();
    form.setValues({
      supplierIds: [],
      statuses: [],
      maxRespondBy: null
    });
    setSearchParams({});
  };

  const onSubmit = (values: Values) => {
    const { maxRespondBy, ...payload } = values;

    const maxRespondByDate = moment(values.maxRespondBy).isValid()
      ? moment(values.maxRespondBy).format("YYYY-MM-DD")
      : false;

    setSearchParams({ ...payload, ...(maxRespondBy && { maxRespondBy: maxRespondByDate }) } as URLSearchParamsInit);
    onClose();
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Grid gutter="lg">
        <Grid.Col>
          <SupplierMultiSelect
            placeholder="Select suppliers"
            label="Suppliers"
            initialData={initialSupliers}
            {...form.getInputProps("supplierIds")}
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <MultiSelect
            dataSource={questionnaireRequestOptions}
            transformData={data => data}
            {...form.getInputProps("statuses")}
            label="Statuses"
            placeholder="Select statuses"
            withinPortal
          />
        </Grid.Col>
        <Grid.Col span={6}>
          <DateInput
            {...form.getInputProps("maxRespondBy")}
            label="Deadline"
            popoverProps={{ withinPortal: true, position: "bottom-start" }}
            valueFormat="YYYY-MM-DD"
            placeholder="Select date YYYY-MM-DD"
            clearable
          />
        </Grid.Col>
      </Grid>
      <ModalFooter>
        <Button variant="subtle" type="reset" onClick={onReset}>
          Reset Filter
        </Button>
        <Button type="submit">Show Results</Button>
      </ModalFooter>
    </form>
  );
};
