import { useEffect } from "react";

import { Button, Text } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { ViewIcon } from "ui/icons";

import { questionnaireFormValidationSchema } from "../../questionnaire.data";
import { QCFormValues, QCPreviewValues } from "../../questionnaire.types";
import { createQuestionInitialDataValues, transformValuesToQuestionnaire } from "../../questionnaire.utils";
import { QCMainForm } from "./QCMainForm";
import { QCSection } from "./QCSection";
import { initialValues as questionnaireCreatorInitialValues } from "./questionnaireCreator.data";
import { QCFooter } from "./questionnaireCreator.styles";

type QCFormProps = {
  initialValues: QCFormValues;
  isLoading: boolean;
  onPreview: (previewValues: QCPreviewValues, payload: QCFormValues) => void;
  onSubmit: (values: QCFormValues) => void;
};

export const QCForm: React.FC<Partial<QCFormProps>> = ({ initialValues, onSubmit, isLoading, onPreview }) => {
  const form = useForm<QCFormValues>({
    initialValues: questionnaireCreatorInitialValues,
    validate: yupResolver(questionnaireFormValidationSchema)
  });

  const handlePreview = () => {
    const values = transformValuesToQuestionnaire(form.values);
    // To fulfil type TQuestionniareQuestion needs for DynamicQuestionnaire component
    const questionnaireValues = {
      ...values,
      questions: createQuestionInitialDataValues(values.questions)
    };

    form.validate();

    if (form.isValid()) onPreview?.(questionnaireValues, form.values);
  };

  const handleSubmit = () => {
    form.validate();

    if (form.isValid()) onSubmit?.(form.values);
  };

  useEffect(() => {
    if (initialValues) {
      form.setValues({ ...initialValues });
    }
  }, [initialValues]);

  return (
    <form>
      <QCMainForm form={form} />
      <QCSection form={form} scope="supplierQuestionList" title="Supplier Questions" />
      {form.errors?.supplierQuestionList && (
        <Text align="center" c="red" fz="xs">
          {form.errors.supplierQuestionList}
        </Text>
      )}
      <QCSection form={form} scope="productQuestionList" title="Product Questions" />
      {form.errors?.productQuestionList && (
        <Text align="center" c="red" fz="xs">
          {form.errors.productQuestionList}
        </Text>
      )}
      <QCFooter>
        <Button type="button" onClick={handlePreview} leftIcon={<ViewIcon />}>
          View Draft
        </Button>
        <Button type="button" loading={isLoading} onClick={handleSubmit}>
          Save
        </Button>
      </QCFooter>
    </form>
  );
};
