import styled from "@emotion/styled";
import { monochromeColor } from "constants/colorPalette";
import Modal from "ui/overlays/Modal";

export const StyledTransactionsFilterModal = styled(Modal)`
  .mantine-Modal-content {
    padding: 1rem;
  }
`;
export const StyledTransactionsFiltersHeader = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 3.8125rem;
  text-align: left;
  color: ${props => props.theme.other.primaryColor};
  margin-bottom: 1.5rem;
`;

export const StyledFilterTitle = styled.div<{ inline?: boolean }>`
  padding-bottom: ${props => (props.inline ? "" : "0.5rem")};
  font-size: ${props => props.theme.other.font.modalGroupHeader.fontSize};
  line-height: ${props => props.theme.other.font.modalGroupHeader.lineHeight};
  font-weight: ${props => props.theme.other.font.modalGroupHeader.fontWeight};
  color: ${monochromeColor[2]};
`;

export const StyledInputWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 1rem;
`;

export const StyledTopSpacer = styled.div`
  padding-top: 1rem;
`;

export const StyledInputBoundariesSetter = styled.div`
  max-width: 139px;

  @media screen and (max-width: ${props => props.theme.other.breakpoints.sm}) {
    max-width: unset;
    width: 100%;
  }
`;

export const StyledRow = styled.div`
  width: 100%;
  gap: 4rem;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    flex-direction: column;
    gap: 2rem;
  }
`;

export const StyledRangeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  @media screen and (max-width: ${props => props.theme.other.breakpoints.sm}) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

export const StyledFiltersContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @media screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    gap: 2rem;
  }
`;

export const Footer = styled.div`
  width: 100%;
  padding-top: 36px;
  margin-top: 2.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
`;
