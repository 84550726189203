//NOTE: pass "suspense: true" when using

import { QueryKey, QueryObserverSuccessResult, useQuery, UseQueryOptions } from "@tanstack/react-query";

type SuspenseQueryObserverResult<TData = unknown, TError = unknown> = QueryObserverSuccessResult<TData, TError>;

type UseSuspenseBaseQueryResult<TData, TError> = SuspenseQueryObserverResult<TData, TError>;

type UseSuspenseQueryResult<TData = unknown, TError = unknown> = UseSuspenseBaseQueryResult<TData, TError>;

export const useSuspenseQuery = <
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>
): UseSuspenseQueryResult<TData, TError> => useQuery(options) as UseSuspenseQueryResult<TData, TError>;
