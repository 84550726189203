import { Grid, MediaQuery, Text } from "@mantine/core";
import { decimalFormatter, percentageFormatter, prettyMoney } from "utils";

import { MILLIONS_OF_DOLLARS } from "../analytics.utils";
import { ReportsTotalGroup, ReportsTotalGroupLabel, ReportsTotalsCardContainer } from "../styles";

type ReportsTotalsCardProps = {
  buyspaceCo2eFraction: number;
  buyspaceSpendFraction: number;
  co2Cost: number;
  co2e: number;
  spend: number;
  statistics: {
    commodities: number;
    mean: number;
    sDeviation: number;
    suppliers: number;
    transactions: number;
  };
};

export const ReportsTotalsCard = (summary: ReportsTotalsCardProps) => {
  const intensity = summary ? summary.co2e / (summary.spend / MILLIONS_OF_DOLLARS) : 0;

  const { transactions, suppliers, commodities } = summary.statistics;

  const { buyspaceCo2eFraction, buyspaceSpendFraction } = summary;

  const spend = prettyMoney(summary.spend);
  const spendPercentage = buyspaceSpendFraction ? ` (${percentageFormatter(buyspaceSpendFraction)}) Total` : "";

  const co2e = decimalFormatter(summary.co2e);
  const co2ePercentage = buyspaceCo2eFraction ? ` (${percentageFormatter(buyspaceCo2eFraction)}) Total` : "";

  const config = [
    { t: "CO₂e", v: co2e, bv: co2ePercentage },
    { t: "Spend", v: spend, bv: spendPercentage },
    {
      t: "Intensity",
      v: decimalFormatter(intensity),
      bv: ""
    }
  ];

  const items = config.map((item, idx) => (
    <Grid.Col lg={12} key={idx}>
      <ReportsTotalGroup>
        <ReportsTotalGroupLabel>
          <Text size="lg" fw={700}>
            {item.t}
          </Text>
        </ReportsTotalGroupLabel>
        <Text style={{ fontSize: "1.125rem" }} c="#262626" fw={700}>
          {item.v}
          <Text style={{ fontSize: "1.125rem" }} c="#262626" fw={400} span>
            {item.bv}
          </Text>
        </Text>
      </ReportsTotalGroup>
    </Grid.Col>
  ));

  return (
    <ReportsTotalsCardContainer>
      <Grid gutter="0.5rem" align="start">
        <Grid.Col lg={12}>
          <Text size="1rem" fw={700} c="#404040">
            Totals
          </Text>
        </Grid.Col>
        <MediaQuery smallerThan="xs" styles={{ display: "none" }}>
          <Grid.Col lg={12}>
            <Text size="0.875rem" fw={400} c="#404040">
              {transactions} Transactions | {suppliers} Suppliers | {commodities} Commodities
            </Text>
          </Grid.Col>
        </MediaQuery>
        {items}
      </Grid>
    </ReportsTotalsCardContainer>
  );
};
