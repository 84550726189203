import { Fragment } from "react";
import { useParams } from "react-router-dom";

import { Button } from "@mantine/core";
import { UserPermissionGuard } from "components";
import { Feature } from "types";
import { SettingIcon } from "ui/icons";
import BuyspaceSelect from "ui/inputs/BuyspaceSelect/BuyspaceSelect";
import { TopHeader } from "ui/layouts/common";
import { getAccountId } from "utils/common.utils";

import { StyleButtonSearchWrapper, TableHeaderOptionsWrapper } from "../transactions.styles";
import { ITransactionFilters, ITransactionsModal } from "../transactions.types";

type TransactionTableHeaderProps = {
  filters: ITransactionFilters;
  modalController: (updatedModal: Partial<ITransactionsModal>) => void;
  updateFilters: (updatedFilters?: Partial<ITransactionFilters>, hardUpdate?: boolean) => void;
};

export const TransactionsTableHeader = ({ modalController, updateFilters, filters }: TransactionTableHeaderProps) => {
  const handleFiltersButton = () => modalController({ filters: true });

  const { id } = useParams();

  const accountId = id || getAccountId();

  const handleBuyspaceSelect = (buyspaceId: string | null) => updateFilters({ buyspaceId, page: 0 }, true);

  return (
    <Fragment>
      <TopHeader title="Transactions" />
      <TableHeaderOptionsWrapper>
        <StyleButtonSearchWrapper>
          <Button variant="default" size="lg" onClick={handleFiltersButton} leftIcon={<SettingIcon />}>
            Filter
          </Button>
          <UserPermissionGuard module={Feature.BUYSPACES_MODULE}>
            <BuyspaceSelect accountId={accountId} onChange={handleBuyspaceSelect} buyspaceId={filters.buyspaceId} />
          </UserPermissionGuard>
        </StyleButtonSearchWrapper>
      </TableHeaderOptionsWrapper>
    </Fragment>
  );
};
