import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button, Select } from "@mantine/core";
import { UserPermissionGuard } from "components";
import { pickBy } from "lodash";
import {
  dateFilterConfig,
  dateFilterMap,
  defaultDateFilter,
  groupFilterConfig
} from "modules/analytics/analytics.data";
import { useGetCustomFields } from "modules/customFields";
import { Feature, TAnalyticsFilters, TAnalyticsGroup, TOverviewDashboardModal } from "types";
import { DownloadIcon, SettingIcon } from "ui/icons";
import BuyspaceSelect from "ui/inputs/BuyspaceSelect/BuyspaceSelect";
import { stringifyQueryParams } from "utils";

import { useInsightDates } from "../hooks";
import { StyleDownloadButtonContainer, StyleSelectAndButtonsOverview, StyleSelectWrapperOverview } from "../styles";

type ReportsFiltersProps = {
  filters: TAnalyticsFilters;
  initialFilters: Partial<TAnalyticsFilters>;
  onDownloadReport: () => void;
  setFilters: React.Dispatch<React.SetStateAction<TAnalyticsFilters>>;
  setModal: React.Dispatch<React.SetStateAction<TOverviewDashboardModal>>;
  setPage: ((page: number) => void) | React.Dispatch<React.SetStateAction<number>>;
};

export const ReportsFilters = ({
  filters,
  setFilters,
  setPage,
  setModal,
  initialFilters,
  onDownloadReport
}: ReportsFiltersProps) => {
  const navigate = useNavigate();

  const { id: accountId } = useParams() as { id: string };

  const { start, end } = useInsightDates(accountId);

  const { data: customFieldListData } = useGetCustomFields({ accountId });

  const handleAdditionalFilter = () => setModal(prev => ({ ...prev, additionalFilter: true }));

  const updateGroupByFilter = (group: TAnalyticsGroup) => {
    setFilters(prevState => ({ ...prevState, group }));
    setPage(0);
  };

  const updateDateFilter = (timeWindow: string) => {
    switch (timeWindow) {
      case "custom": {
        setModal(prevModal => ({ ...prevModal, customInterval: true }));
        return;
      }
      case "mry": {
        setFilters(prevState => ({ ...prevState, timeWindow, start, end }));
        return;
      }
      default: {
        const selected = dateFilterMap.get(timeWindow) || defaultDateFilter;
        const { start, end } = selected;
        setFilters(prevState => ({ ...prevState, start, end, timeWindow }));
      }
    }
  };

  const updateBuyspaceSelect = (buyspaceId: string | null) => {
    setFilters(prevState => ({ ...prevState, buyspaceId }));
  };

  const bookmarkParams = () => {
    const copy = structuredClone(filters);
    const bookmark = pickBy(copy, (v, k) => {
      const key = k as keyof TAnalyticsFilters;
      return v !== initialFilters[key] && key !== "accountId";
    });
    navigate("?" + stringifyQueryParams(bookmark));
  };

  useEffect(() => {
    bookmarkParams();
  }, [filters]);

  const customFieldOptions =
    customFieldListData?.data?.map(customField => ({
      label: `${customField.fieldLabel || customField.field}*`,
      value: customField.id
    })) || [];

  const extendedOptions = [...groupFilterConfig, ...customFieldOptions];

  return (
    <StyleSelectAndButtonsOverview>
      <StyleSelectWrapperOverview>
        <Select
          placeholder="Select Type"
          readOnly={false}
          data={extendedOptions}
          value={filters.group}
          onChange={updateGroupByFilter}
          size="lg"
          maxDropdownHeight={1200}
        />
        <Select
          readOnly={false}
          data={dateFilterConfig}
          value={filters.timeWindow}
          onChange={updateDateFilter}
          size="lg"
        />
        <UserPermissionGuard module={Feature.BUYSPACES_MODULE}>
          <BuyspaceSelect accountId={accountId} buyspaceId={filters.buyspaceId} onChange={updateBuyspaceSelect} />
        </UserPermissionGuard>
        <Button size="lg" variant="outline" onClick={handleAdditionalFilter} leftIcon={<SettingIcon />}>
          Additional Filters
        </Button>
      </StyleSelectWrapperOverview>
      <StyleDownloadButtonContainer>
        <Button variant="white" leftIcon={<DownloadIcon />} size="lg" onClick={onDownloadReport}>
          Download Report
        </Button>
      </StyleDownloadButtonContainer>
    </StyleSelectAndButtonsOverview>
  );
};
