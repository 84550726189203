import { Feature } from "types";

export const userFeatureFlagsFormInitial = {
  accountId: "",
  features: []
};

export const featureFlagNameMap = {
  [Feature.BUYSPACES_MODULE]: "Buyspaces Module",
  [Feature.CUSTOM_FIELDS_MODULE]: "Custom Fields Module",
  [Feature.GOAL_MODULE]: "Goals Module",
  [Feature.SUPPLIER_QUESTIONNAIRES]: "Supplier Questionnaire Module"
};
