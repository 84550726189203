import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { errorModal } from "modules/error/ErrorHandlingModals";
import { ErrorResponse } from "types";

import { deleteImportService } from "../api";

export const useDeleteImportMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: deleteImportService,
    mutationKey: [QUERY_KEYS.delete_import],
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [QUERY_KEYS.imports_listing] });
    },
    onError: (error: ErrorResponse) => errorModal(error)
  });
  return mutation;
};
