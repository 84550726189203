import { ColumnDef } from "@tanstack/react-table";
import { LinkComponent } from "components";
import { QuestionnaireStatusMap } from "modules/supplierDetails/supplierDetails.data";
import { TQuestionnaireRequest } from "types";
import { getFullDayYear, getFullDayYearTime } from "utils";

import { QuestionnaireTableActionCell } from "../QuestionnaireTableActionCell";

export const questionnaireRequestListColumns: ColumnDef<TQuestionnaireRequest>[] = [
  {
    accessorKey: "supplier.name",
    header: "Supplier Name",
    enableSorting: false,
    size: 500,
    cell: props => (
      <LinkComponent to={`/supplier/${props.row.original.supplier.id}`}>
        {props.row.original.supplier.name}
      </LinkComponent>
    )
  },
  {
    accessorKey: "questionnaire.title",
    header: "Questionnaire",
    enableSorting: false,
    size: 500
  },
  {
    accessorKey: "sendTo",
    header: "Sent to",
    enableSorting: false,
    size: 500
  },
  {
    accessorKey: "respondBy",
    header: "Respond By",
    enableSorting: true,
    size: 500,
    cell: props => getFullDayYear(props.row.original.respondBy)
  },
  {
    accessorKey: "updatedAt",
    header: "Last update",
    enableSorting: true,
    size: 500,
    cell: props => getFullDayYearTime(props.row.original.updatedAt)
  },
  {
    accessorKey: "status",
    header: "Status",
    enableSorting: false,
    size: 500,
    cell: props => QuestionnaireStatusMap?.[props.row.original.status] || props.row.original.status
  },
  {
    id: "action",
    header: "Action",
    enableSorting: false,
    cell: props => <QuestionnaireTableActionCell {...props.row.original} />,
    meta: {
      align: "center"
    },
    size: 50
  }
];
