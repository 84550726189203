import { useQuery } from "@tanstack/react-query";
import { errorModal } from "modules/error/ErrorHandlingModals";
import { ErrorResponse } from "types";

import { getUserDetailsService } from "../api";

export const useGetUserDetailsQuery = (id: string) => {
  const query = useQuery({
    queryKey: ["user-details", id],
    queryFn: () => getUserDetailsService(id),
    enabled: id !== "" && id !== undefined,
    onError: (error: ErrorResponse) => errorModal(error),
    useErrorBoundary: true
  });
  return query;
};
