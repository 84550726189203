import { useTheme } from "@emotion/react";
import { Text } from "@mantine/core";
import { TMethodologyObject } from "types";
import { formatPercentagePoints } from "utils";

export const ReportsAnalysisQualityCell = (methodology: TMethodologyObject) => {
  const { sb = 0, cb = 0, ss = 0 } = methodology;

  const {
    other: { primaryColor, monochromeColor, warningColor }
  } = useTheme();

  const sbValue = formatPercentagePoints(sb);
  const cbValue = formatPercentagePoints(cb);
  const ssValue = formatPercentagePoints(ss);

  const sections = [
    { value: ssValue, color: primaryColor, text: "SUPPLIER" },
    { value: cbValue, color: warningColor, text: "COMMODITY" },
    { value: sbValue, color: monochromeColor[2], text: "TRANSACTION" }
  ];

  const relevantValue = Math.max(ssValue, sbValue, cbValue);

  const relevantSection = sections.find(i => i.value === relevantValue) ?? sections[0];

  return (
    <Text c={relevantSection.color} size="lg" ta="right">
      {relevantSection.text}
    </Text>
  );
};
