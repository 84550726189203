import { Button } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { ModalBodyMessage, ModalTitle } from "ui";
import { SettingIcon } from "ui/icons";
import Modal from "ui/overlays/Modal";

import { QuestionnaireRequestForm } from "./QuestionnaireRequestForm";

export const QuestionnaireRequestFilterModal = () => {
  const [opened, { close, open }] = useDisclosure();

  return (
    <>
      <Button size="lg" variant="default" leftIcon={<SettingIcon />} onClick={open}>
        Filters
      </Button>
      <Modal opened={opened} handleClose={close} withCloseButton modalProps={{ trapFocus: false }}>
        <ModalTitle>Filter</ModalTitle>
        <ModalBodyMessage>
          <QuestionnaireRequestForm onClose={close} />
        </ModalBodyMessage>
      </Modal>
    </>
  );
};
