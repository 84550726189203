import { useCurrentUser } from "modules/login";

import { Header, HeaderBottomDivider, HeaderContentContainer } from "./customFieldsHeader.styles";

export const CustomFieldsHeader = () => {
  const {
    userDetails: { accountName }
  } = useCurrentUser();

  return (
    <>
      <Header>
        <HeaderContentContainer>
          <h1>Account Settings</h1>
          <h2>{accountName ?? "Undefined Account"}</h2>
        </HeaderContentContainer>
      </Header>
      <HeaderBottomDivider />
    </>
  );
};
