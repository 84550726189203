import { Box, Flex, Group, Title } from "@mantine/core";
import { flexRender, Row, Table as TableType } from "@tanstack/react-table";

import { InsightTableHeadCell, InsightTableWrapper } from "./InsightTable.styles";

export type InsightTableProps<T> = {
  emptyState?: Partial<{ emptyText: string; icon: React.ReactNode }>;
  extra?: React.ReactNode;
  hoverable?: boolean;
  loading?: boolean;
  onRow?: {
    onClick?: (rowData: Row<T>) => void;
    onMouseLeave?: () => void;
    onMouseOver?: (rowData: Row<T>) => void;
  };
  rightHeader?: React.ReactNode;
  rowSize?: string;
  table: TableType<T>;
  title: string;
};

export const InsightTable = <T,>({
  table,
  title,
  extra,
  onRow = { onClick: () => {}, onMouseOver: () => {}, onMouseLeave: () => {} },
  rightHeader
}: InsightTableProps<T>) => {
  const { onClick, onMouseOver, onMouseLeave } = onRow;

  return (
    <>
      <Flex
        justify={{ xs: "center", sm: "center", md: "space-between", lg: "space-between" }}
        direction={{ xs: "column", sm: "column", md: "row" }}
        align="center"
        mb="2.5rem"
        gap="1rem"
        wrap="wrap"
      >
        <Group align="center" noWrap>
          <Title order={2} fw={700} c="#333333" size="2.5rem">
            {title}
          </Title>
          <Box td="underline" ml={{ xs: "1rem", md: "4.125rem" }}>
            {extra}
          </Box>
        </Group>
        <Flex mt={{ xs: "1rem", sm: "1rem", md: 0 }} justify={{ xs: "center", sm: "center", md: "flex-end" }}>
          {rightHeader}
        </Flex>
      </Flex>
      <InsightTableWrapper>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                const isSortable = header.column.getCanSort();

                const showSortIcon = isSortable && header.column.getIsSorted() ? "*" : null;

                const centered = header.column.columnDef.meta?.align === "center";

                const tableHeaderProps = {
                  style: {
                    width: header.getSize(),
                    textAlign: header.column.columnDef.meta?.align ?? "left"
                  },
                  onClick: header.column.getToggleSortingHandler()
                };

                const tableHeaderRender = header.isPlaceholder
                  ? null
                  : flexRender(header.column.columnDef.header, header.getContext());

                return (
                  <th key={header.id} {...tableHeaderProps}>
                    <InsightTableHeadCell centered={centered}>
                      {tableHeaderRender}
                      {isSortable && (
                        <div>
                          {{
                            asc: "",
                            desc: ""
                          }[header.column.getIsSorted() as string] ?? showSortIcon}
                        </div>
                      )}
                    </InsightTableHeadCell>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr
              key={row.id}
              style={{
                cursor: "default"
              }}
              onMouseOver={() => onMouseOver?.(row)}
              onMouseLeave={() => onMouseLeave?.()}
              onClick={() => onClick?.(row)}
            >
              {row.getVisibleCells().map(cell => (
                <td key={cell.id} style={{ textAlign: cell.column.columnDef.meta?.align ?? "left" }}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </InsightTableWrapper>
    </>
  );
};
