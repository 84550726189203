import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { errorModal } from "modules/error/ErrorHandlingModals";
import { ErrorResponse } from "types";

import { getQuestionnaireService } from "../api";

export const useGetQuestionnaireQuery = (id?: string) =>
  useQuery({
    queryKey: [QUERY_KEYS.get_questionnaire, id],
    useErrorBoundary: true,
    queryFn: () => getQuestionnaireService(id),
    enabled: Boolean(id),
    onError: (error: ErrorResponse) => errorModal(error)
  });
