import { LinkComponent } from "components";
import { useCurrentUser } from "modules/login";
import { USER_TYPE } from "modules/users/types/UserTypesEnum";

import { ISupplier } from "../suppliers.types";

export const SuppliersLinkCell: React.FC<
  ISupplier & { content: React.ReactNode; redirectDestination: "commodities" | "transactions" }
> = ({ id, redirectDestination, content }) => {
  const {
    userDetails: { userType, accountId }
  } = useCurrentUser();

  const to =
    userType === USER_TYPE.internal
      ? `/${redirectDestination}?supplierIds[]=${id}`
      : `/${redirectDestination}/${accountId}?supplierIds[]=${id}`;

  return <LinkComponent to={to}>{content}</LinkComponent>;
};
