import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { QUERY_KEYS } from "constants/queryKeys";
import { ResponseError, ResponseType, TImportTransactionMeta } from "types";

import { putImportTransactionMetaService } from "../api";

export const useUpdateTransactionMetaMutation = ({ importId }: { importId: string }) => {
  const queryClient = useQueryClient();
  return useMutation<
    ResponseType<{ columns: TImportTransactionMeta[] }>,
    AxiosError<ResponseError>,
    { importId: string; payload: { columns: (Omit<TImportTransactionMeta, "id"> & { id?: string })[] } }
  >({
    mutationFn: putImportTransactionMetaService,
    mutationKey: [QUERY_KEYS.import_transaction_meta, importId],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.imports_listing] });
    }
  });
};
