import { ReactNode } from "react";

import styled from "@emotion/styled";
import { Modal as MTModal, ModalProps as MTModalProps } from "@mantine/core";
import { CrossIcon } from "ui/icons";

export interface ModalProps {
  children?: ReactNode;
  title?: ReactNode | string;
  withCloseButton?: boolean;
  opened: boolean;
  setOpened?: React.Dispatch<React.SetStateAction<boolean>>;
  modalPadding?: string;
  titleMargin?: string;
  titleWidth?: string;
  maxModalWidth?: string;
  minHeight?: string;
  // @todo - Adjust or create a new modal with proper props
  onClose?: boolean;
  handleClose?: () => void;
  className?: string;
  mode?: "big" | "message" | "modal";
  modalProps?: Partial<MTModalProps>;
}
const CloseButton = styled.div`
  position: absolute;
  top: 18px;
  right: 20px;
  cursor: pointer;
  z-index: 1050;
`;

const StyledModal = styled(MTModal)<{ mode?: "big" | "message" | "modal" }>`
  .mantine-Modal-content {
    padding: ${({ fullScreen }) => (fullScreen ? "0" : "1rem")};

    flex: 0 0
      ${({ mode, fullScreen }) => {
        if (mode === "big") {
          return "85rem";
        }
        if (mode === "message") {
          return "30rem";
        }
        if (fullScreen) {
          return "100%";
        }
        return "50rem";
      }};

    min-height: auto;
    @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
      padding: 1rem;
    }
  }
  .mantine-Modal-title {
    margin: 0px;
    width: 100%;
  }
  .mantine-Modal-body {
    padding: ${({ fullScreen }) => (fullScreen ? "0" : "2rem")};
  }
`;

const Modal = ({
  children,
  opened,
  setOpened,
  title,
  withCloseButton = false,
  handleClose,
  className,
  onClose = true,
  mode = "modal",
  modalProps
}: ModalProps) => {
  // @todo Remove in future. Need to get rid of onClose: boolean and replace it with callback. This is a big change, therefore I leave it for now
  const mergeOnCloseCallback = () => {
    if (onClose) {
      setOpened?.(false);
    }
    handleClose?.();
  };

  return (
    <StyledModal
      className={className}
      centered
      {...modalProps}
      opened={opened}
      transitionProps={{
        transition: "scale",
        ...modalProps?.transitionProps
      }}
      overlayProps={{
        opacity: 0.7,
        ...modalProps?.overlayProps
      }}
      withCloseButton={false}
      onClose={mergeOnCloseCallback}
      title={title}
      mode={mode}
    >
      {withCloseButton && (
        <CloseButton onClick={mergeOnCloseCallback}>
          <CrossIcon />
        </CloseButton>
      )}
      {children}
    </StyledModal>
  );
};
export default Modal;
