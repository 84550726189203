import { TooltipContainer } from "./naicsTooltipContent.styles";

const NaicsTooltipContent: React.FC<{ type?: "detail" | "industry" | "sector" }> = ({ type }) => {
  const renderType = () => {
    if (type === "industry") {
      return {
        title: "NAICS Industry",
        description: "The Industry Group-level classification denoted by a 4-digit code."
      };
    }
    if (type === "sector") {
      return { title: "NAICS Sector", description: "The Sector-level classification denoted by a 2-digit code." };
    }
    return {
      title: "NAICS Code",
      description: "The National Industry-level classification denoted by a 6-digit code."
    };
  };

  const content = renderType();

  return (
    <TooltipContainer>
      <h2>{content.title}</h2>
      <p>{content.description}</p>
      <a
        href="https://www.census.gov/programs-surveys/economic-census/year/2022/guidance/understanding-naics.html#:~:text=The%20North%20American%20Industry%20Classification,to%20the%20U.S.%20business%20economy."
        target="_blank"
        rel="noreferrer"
      >
        Learn more about NAICS classifications.
      </a>
    </TooltipContainer>
  );
};

export default NaicsTooltipContent;
