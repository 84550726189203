import { ReactNode } from "react";

import { Footer, FooterLogoRow } from "./common";
import { PublicHeaderScreen } from "./common/PublicHeaderScreen";
import { PublicLayoutWrapper, StyleBackground, StyleFooterPositionWrapper } from "./publicLayout.styles";

type PublicLayoutProps = {
  children: ReactNode;
  isLoginPage?: boolean;
  showDemoButton?: boolean;
  showLoginButton?: boolean;
  showTabs?: boolean;
};
const PublicLayout: React.FC<PublicLayoutProps> = ({
  children,
  showTabs = true,
  showLoginButton = true,
  showDemoButton = true,
  isLoginPage = false
}) => {
  const tabData: string[] = [];
  return (
    <PublicLayoutWrapper>
      <PublicHeaderScreen
        tabs={showTabs ? tabData : []}
        showLoginButton={showLoginButton}
        showDemoButton={showDemoButton}
      />
      <>{children}</>
      <StyleFooterPositionWrapper>
        {isLoginPage && <FooterLogoRow />}
        <Footer links={[]} />
      </StyleFooterPositionWrapper>
      <StyleBackground />
    </PublicLayoutWrapper>
  );
};

export default PublicLayout;
