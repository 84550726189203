import { createContext, Dispatch, SetStateAction, useContext } from "react";

import { TFeatureFlagUser, TUserAccount, UserRoles } from "types";

export type TUserDetails = {
  accountId: string;
  accountName: string;
  accounts: TUserAccount[];
  email: string;
  featureFlags: TFeatureFlagUser[];
  firstName: string;
  image: string;
  lastName: string;
  userId: string;
  userRole: UserRoles;
  userType: string;
};

export type TCurrentUserContext = {
  activeTab: string | null;
  isInternal: boolean;
  JWT_Token: string | null;
  logout: () => void;
  setActiveTab: Dispatch<SetStateAction<string | null>>;
  setJWT_Token: Dispatch<SetStateAction<string | null>>;
  setUserDetails: Dispatch<SetStateAction<TUserDetails>>;
  setUserId: Dispatch<SetStateAction<string>>;
  userDetails: TUserDetails;
};

export const userDetailsDefaultValue: TUserDetails = {
  accountId: "",
  accountName: "",
  email: "",
  firstName: "",
  lastName: "",
  userId: "",
  userRole: "" as UserRoles,
  userType: "",
  accounts: [],
  featureFlags: [],
  image: ""
};

const currentUserContextDefaultValue: TCurrentUserContext = {
  activeTab: null,
  isInternal: false,
  JWT_Token: null,
  logout: () => {},
  setActiveTab: () => {},
  setJWT_Token: () => {},
  setUserDetails: () => {},
  setUserId: () => {},
  userDetails: userDetailsDefaultValue
};

export const CurrentUserContext = createContext<TCurrentUserContext>(currentUserContextDefaultValue);

export const useCurrentUser = () => {
  const ctx = useContext(CurrentUserContext);

  if (!ctx) {
    throw new Error("useCurrentUserContext must be wrapped with CurrentUserContext.Provider");
  }

  return ctx;
};
