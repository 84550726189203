import { useState } from "react";
import { useNavigate } from "react-router-dom";

import styled from "@emotion/styled";
import { Button } from "@mantine/core";
import { useQueryClient } from "@tanstack/react-query";
import { TUserDetails, userDetailsDefaultValue } from "modules/login";

const ErrorHeader = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: 1.625rem;
  line-height: 150%;
  color: ${props => props.theme.other.dangerColor};
  margin-left: auto;
  margin-right: auto;
`;
const ErrorMessage = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.3125rem;
  color: ${props => props.theme.other.dangerColor};
  margin-left: auto;
  margin-right: auto;
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  text-align: center;
  padding: 2rem 0;
`;
interface ErrorHandlerInterface {
  error?: any;
}
const ErrorHandler: React.FC<ErrorHandlerInterface> = ({ error }) => {
  const errorTitle = error && error.message ? error.message + "." : "";
  const errorDescription = error && error.response && error.response.data ? error.response.data.errorMessage : "";

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState<string | null>(null);

  const [queryEnabled, setQueryEnabled] = useState(false);

  const [JWT_Token, setJWT_Token] = useState<string | null>(null);

  const [userDetails, setUserDetails] = useState<TUserDetails>(userDetailsDefaultValue);

  const [userId, setUserId] = useState("");

  const message = `${errorTitle} ${errorDescription}`;

  const logout = () => {
    queryClient.removeQueries();
    setJWT_Token(null);
    setUserId("");
    setActiveTab(null);
    localStorage.clear();
    sessionStorage.clear();
    setUserDetails(userDetailsDefaultValue);
    navigate("/login");
  };

  return (
    <Wrapper role="alert">
      <ErrorHeader>Something went wrong!</ErrorHeader>
      <ErrorMessage>{message}</ErrorMessage>
      <ErrorMessage>If the problem persists, try logging in again.</ErrorMessage>
      <Button variant="outline" onClick={logout}>
        Log Out
      </Button>
    </Wrapper>
  );
};
export default ErrorHandler;
