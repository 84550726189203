import ReactApexChart from "react-apexcharts";

import { ApexOptions } from "apexcharts";
import { LineChartProps } from "ui";

const FilledLineChartView = ({
  series,
  offset,
  color
}: Omit<LineChartProps, "xAxis"> & { color: string; offset: number }) => {
  const opacity = color === "#dddddd" ? 1 : 0.3;

  const chartData: ApexOptions = {
    grid: {
      show: false,
      padding: { left: 0, right: 0, top: 0, bottom: offset }
    },
    chart: {
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    markers: { size: 0 },
    colors: [color],
    series,
    stroke: {
      show: false,
      curve: "straight",
      width: [4, 4, 4]
    },
    fill: {
      type: "solid",
      opacity
    },
    xaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false
      },
      crosshairs: {
        show: false
      },
      tooltip: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false,
      offsetX: 0,
      offsetY: 0
    },
    yaxis: [
      {
        show: false,
        axisTicks: {
          show: false
        },
        axisBorder: {
          show: false
        },
        labels: {
          show: false
        }
      },
      {
        show: false,
        axisTicks: {
          show: false
        },
        axisBorder: {
          show: false
        },
        labels: {
          show: false
        }
      }
    ],
    tooltip: {
      enabled: false
    },
    legend: {
      show: false
    }
  };

  return <ReactApexChart options={chartData} series={chartData.series} type="area" height="100%" />;
};

export const FilledLineChart = ({ series, color }: Omit<LineChartProps, "xAxis"> & { color: string }) => {
  const offset = series[0].data.length >= 22 ? -30 : -15;

  return <FilledLineChartView series={series} offset={offset} color={color} />;
};
