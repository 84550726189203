import { ColumnDef } from "@tanstack/react-table";

import { ImportHeaderType } from "../../types";
import { ImportMapErrorCell } from "./ImportMapErrorCell";
import { ImportMapFileFieldCell } from "./ImportMapFileFieldCell";
import { ImportMapModalSelect } from "./ImportMapModalSelect";
import { ImportMapSampleCell } from "./ImportMapSampleCell";

export const columns: ColumnDef<ImportHeaderType, string>[] = [
  {
    header: "File Field",
    accessorKey: "csvHeaderKey",
    enableSorting: false,
    size: 330,
    cell: props => <ImportMapFileFieldCell {...props.row.original} />
  },
  {
    header: "Kloopify Field",
    accessorKey: "kloopifyFieldKey",
    enableSorting: false,
    size: 330,
    cell: props => <ImportMapModalSelect {...props.row.original} />
  },
  {
    header: "Sample",
    id: "exampleSample",
    enableSorting: false,
    size: 330,
    cell: props => <ImportMapSampleCell {...props.row.original} />
  },
  {
    header: "Errors",
    id: "actions",
    enableSorting: false,
    size: 140,
    cell: props => <ImportMapErrorCell {...props.row.original} />
  }
];
