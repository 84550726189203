import { Text } from "@mantine/core";
import { TQuestionnaireItemMap } from "types";

import { mappingMap } from "../questionnaire.data";

type QuestionnaireMappingTextProps = {
  mapping?: TQuestionnaireItemMap;
};

const QuestionnaireMappingText = ({ mapping }: QuestionnaireMappingTextProps) => {
  if (!mapping) return null;

  const { field } = mapping;

  return (
    <Text fs="italic" fz="xs">
      Maps to {mappingMap[field]}
    </Text>
  );
};

export default QuestionnaireMappingText;
