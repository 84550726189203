import styled from "@emotion/styled";
import { Text } from "@mantine/core";
import { Button } from "@mantine/core";
import { closeAllModals, openModal } from "@mantine/modals";
import { OpenConfirmModal } from "@mantine/modals/lib/context";
import { dangerColor, monochromeColor, primaryColor } from "constants/colorPalette";
import { fontFamily, fontStyle } from "constants/typography";

const styleTitle = {
  fontFamily: fontFamily,
  fontStyle: fontStyle,
  fontWeight: 700
};
const styleBody = {
  fontFamily: fontFamily,
  fontStyle: fontStyle,
  fontSize: "1rem",
  color: monochromeColor[2]
};
const styleClose = {
  top: "0.5rem",
  right: "0.5rem"
};
export const errorModal = (_: unknown) => {
  return openModal({
    title: "Error",
    centered: true,
    children: "An unexpected error has occurred. If the problem persists, please notify support.",
    styles: {
      content: {
        maxWidth: "calc(596px - 67px)",
        minHeight: "calc(194px - 10px)",
        padding: "1rem",
        position: "relative"
      },
      title: {
        ...styleTitle,
        fontSize: "1.5625rem",
        lineHeight: "1.875rem",
        textAlign: "left",
        color: dangerColor,
        marginTop: "32px"
      },
      body: {
        ...styleBody,
        fontWeight: 400,
        marginTop: "5px"
      },
      close: { ...styleClose, position: "absolute" }
    }
  });
};

const StyleButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 29.75px;
  margin-right: 19px;
`;
export const successModal = (message: string) => {
  return openModal({
    title: `Success!`,
    centered: true,
    children: (
      <>
        {message}
        <StyleButtonWrapper>
          <Button onClick={() => closeAllModals()}>Close</Button>
        </StyleButtonWrapper>
      </>
    ),
    styles: {
      content: {
        padding: "1rem",
        position: "relative"
      },
      title: {
        ...styleTitle,
        fontSize: "3.125rem",
        lineHeight: "3.8125rem",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        color: primaryColor,
        marginTop: "63.75px",
        marginRight: 0,
        width: "100%"
      },
      body: {
        ...styleBody,
        fontWeight: 700,
        margin: "5px auto 0px auto",
        width: "100%",
        textAlign: "center"
      },
      close: { ...styleClose, position: "absolute", fontSize: "2rem" }
    }
  });
};

const StyledMultiButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  padding: 30px 20px 0 20px;
`;

export const confirmationModal = (
  payload: OpenConfirmModal & {
    buttonChildren?: JSX.Element;
    buttonConfig?: {
      cancelText: string;
      confirmText: string;
    };
    color?: string;
    message: string;
  }
) => {
  const {
    color = "klp",
    onCancel = () => {},
    onConfirm = () => {},
    message,
    buttonChildren,
    buttonConfig,
    ...rest
  } = payload;

  const buttons = buttonChildren ?? (
    <>
      <Button
        variant="subtle"
        color={color}
        onClick={() => {
          onCancel();
          closeAllModals();
        }}
      >
        {buttonConfig?.cancelText || "Cancel"}
      </Button>
      <Button
        color={color}
        onClick={() => {
          onConfirm();
          closeAllModals();
        }}
      >
        {buttonConfig?.confirmText || "Confirm"}
      </Button>
    </>
  );

  openModal({
    ...rest,
    centered: true,
    children: (
      <>
        <Text size="sm">{message}</Text>
        <StyledMultiButtonWrapper>{buttons}</StyledMultiButtonWrapper>
      </>
    ),
    styles: {
      content: {
        padding: "1rem",
        position: "relative",
        flex: "0 0 40rem !important"
      },
      title: {
        ...styleTitle,
        fontSize: "2.3rem",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        marginRight: 0,
        width: "100%"
      },
      body: {
        fontWeight: 700,
        margin: "5px auto 0px auto",
        width: "100%",
        textAlign: "center"
      },
      close: { ...styleClose, position: "absolute" }
    }
  });
};
