import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { SortingState } from "@tanstack/react-table";
import { isArray, isEmpty } from "lodash";
import { getBuyspacesService } from "modules/buyspaces";
import { getClassificationService } from "modules/classification";
import { getCommodityService } from "modules/commodity/api/getCommodityService";
import FilterTile from "ui/tiles/FilterTile/FilterTile";
import { getAccountId } from "utils";
import { parseQueryParams } from "utils/query.utils";

import { getSupplierName } from "../api";
import { getCostCenterService } from "../api/getCostCenterService";
import { annotationReplacementList, valueReplacementKeyList, valueReplacementList } from "../transactions.config";
import { initialFilters as transactionInitialFilters } from "../transactions.data";
import { StyledFilterTilesWrapper } from "../transactions.styles";
import { ITransactionFilters, TTransactionFiltersEntries } from "../transactions.types";

type Props = {
  filtersData: ITransactionFilters;
  setSorting: Dispatch<SetStateAction<SortingState>>;
  updateFilters: (updatedFilters?: Partial<ITransactionFilters>, hardUpdate?: boolean) => void;
};

export const TransactionFilterTiles: FC<Props> = ({ filtersData, updateFilters, setSorting }) => {
  const { search } = useLocation();

  const { id } = useParams();

  const [supplierNamesList, setSupplierNamesList] = useState<string[] | null>(null);
  const [costCenterNameList, setCostCenterNameList] = useState<string[] | null>(null);
  const [commodityNameList, setCommodityNameList] = useState<string[] | null>(null);
  const [codesNamesList, setCodesNamesList] = useState<Record<string, string>>({});
  const [buyspaceName, setBuyspaceName] = useState<string[] | null>(null);

  const accountId = id || getAccountId();

  const filterObject = useMemo(() => {
    const newFilterObject = parseQueryParams(search) as Partial<ITransactionFilters>;
    if (newFilterObject?.minTotalCost) {
      Object.assign(newFilterObject, { minTotalCost: newFilterObject.minTotalCost / 100 });
    }
    if (newFilterObject?.maxTotalCost) {
      Object.assign(newFilterObject, { maxTotalCost: newFilterObject.maxTotalCost / 100 });
    }
    delete newFilterObject.page;
    return newFilterObject;
  }, [search]);

  const fetchBuyspaceName = async () => {
    const buyspaceId = filterObject.buyspaceId;
    if (!buyspaceId) return setBuyspaceName([]);

    const buyspaces = await getBuyspacesService({ id: accountId });
    const name = buyspaces.data.find(buyspace => buyspace.id === buyspaceId)?.name;
    if (name) return setBuyspaceName([name]);
    setBuyspaceName([]);
  };

  const fetchSupplierNamesList = async () => {
    const supplierIds = filterObject.supplierIds;
    if (supplierIds) {
      const requestList = supplierIds.map(supplierId => getSupplierName(supplierId));
      const responseList = await Promise.all(requestList);
      const namesList = responseList.map(response => response.data.name);
      setSupplierNamesList(namesList);
      return;
    }
    setSupplierNamesList([]);
  };

  const fetchCostCenterNameList = async () => {
    const costCenterIdList = filterObject.costCenterIds;
    if (costCenterIdList) {
      const costCenterRequestList = costCenterIdList.map(id => getCostCenterService(id));
      const costCenterResponseList = await Promise.all(costCenterRequestList);
      const names = costCenterResponseList.map(response => response.data.name);
      setCostCenterNameList(names);
      return;
    }
    setCostCenterNameList([]);
  };

  const fetchCommodityNameList = async () => {
    const commodityIdList = filterObject.itemIds;
    if (commodityIdList) {
      const commodityRequestList = commodityIdList.map(id => getCommodityService(id));
      const commodityResponseList = await Promise.all(commodityRequestList);
      const names = commodityResponseList.map(response => response.data.name);
      setCommodityNameList(names);
      return;
    }
    setCommodityNameList([]);
  };

  const fetchCodesNames = async () => {
    const codes = [
      ...(filterObject.naicsCodes || []),
      ...(filterObject.naicsIndustries || []),
      ...(filterObject.naicsSectors || [])
    ];

    if (codes.length) {
      const requestList = codes.map(code => getClassificationService({ params: { code } }));
      const responseList = await Promise.all(requestList);

      const codesList = responseList
        .map(response => response.data[0])
        .filter(classification => classification.code)
        .reduce((prev, curr) => ({ ...prev, [curr.code as string]: curr.title }), {});
      setCodesNamesList(codesList);
      return;
    }
    setCodesNamesList({});
  };

  const handleOnClick = (filterKey: keyof ITransactionFilters, filterValue: string) => {
    let clearedFilter = transactionInitialFilters[filterKey];
    if (filterKey === "sortBy") setSorting([]);
    if (isArray(clearedFilter)) {
      const currentFilter = filterObject[filterKey] as string[];
      clearedFilter = currentFilter.filter(i => i !== filterValue);
    }
    updateFilters({ ...filtersData, [filterKey]: clearedFilter, page: 0 }, true);
  };

  const getAnnotationByKey = (entryKey: string) => {
    const annotation = annotationReplacementList.find(replacement => replacement.key === entryKey);
    return annotation ? annotation.label : "unknown option: ";
  };

  const getAnnotationValueByKey = (entryValue: string | string[], entryKey: keyof ITransactionFilters) => {
    const realEntryValue = isArray(entryValue) ? entryValue : [entryValue];
    const isReplace = valueReplacementKeyList.find(i => i === entryKey);
    return realEntryValue.map(realValue => {
      const replacement = isReplace ? valueReplacementList.find(e => e.value === realValue) : null;
      return replacement ? replacement.label : realValue;
    });
  };

  useEffect(() => {
    fetchCostCenterNameList();
    fetchSupplierNamesList();
    fetchCodesNames();
    fetchCommodityNameList();
    fetchBuyspaceName();
  }, [search]);

  const renderFilterTiles = () => {
    const filterEntries = Object.entries(filterObject) as TTransactionFiltersEntries;

    return filterEntries.map((filterEntry, filterEntryIndex) => {
      const [entryKey, entryValue] = filterEntry;
      const filterAnnotation = getAnnotationByKey(entryKey);
      const filterValueList = getAnnotationValueByKey(entryValue, entryKey);

      const codeValueList =
        Array.isArray(entryValue) && entryKey.includes("naics") ? entryValue.map(value => codesNamesList[value]) : [];

      const normalizedValueList = () => {
        if (entryKey === "supplierIds") return supplierNamesList;

        if (entryKey === "naicsCodes" || entryKey === "naicsSectors" || entryKey === "naicsIndustries") {
          return codeValueList;
        }

        if (entryKey === "costCenterIds") return costCenterNameList;

        if (entryKey === "itemIds") return commodityNameList;

        if (entryKey === "buyspaceId") return buyspaceName;

        return filterValueList;
      };

      return normalizedValueList()?.map((filterValue, filterValueIndex) => (
        <FilterTile
          key={"userFilterTile" + filterEntryIndex + filterValueIndex}
          text={filterAnnotation + filterValue}
          onClick={() => {
            handleOnClick(entryKey, isArray(entryValue) ? entryValue[filterValueIndex] : entryValue);
          }}
        />
      ));
    });
  };

  const filterTiles = supplierNamesList ? renderFilterTiles() : null;

  return isEmpty(filterObject) ? null : <StyledFilterTilesWrapper>{filterTiles}</StyledFilterTilesWrapper>;
};
