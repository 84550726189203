import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { TGetAnalyticsSummaryParams } from "types/Analytics/AnalyticsParams";

import { getSupplierAnalytics } from "../api/getSupplierAnalytics";

export const useGetSupplierAnalytics = (params: TGetAnalyticsSummaryParams) =>
  useQuery({
    queryKey: [QUERY_KEYS.supplier_analytics, { ...params }],
    queryFn: () => getSupplierAnalytics(params),
    enabled: Boolean(params.id)
  });
