import { useState } from "react";
import { useParams } from "react-router-dom";

import { SelectItem } from "@mantine/core";
import { ISupplier, useGetSupplierListQuery } from "modules/suppliers";
import { ClearableSelect } from "ui";
import { SearchIcon } from "ui/icons";

export const QuestionnaireRequestListSearchableSelect: React.FC<{
  error?: string | null;
  onChange: (value: string) => void;
  onNameChange: (value: string) => void;
  value: string | undefined;
}> = ({ onChange, value, onNameChange, error }) => {
  const { id } = useParams();

  const [searchValue, setSearchValue] = useState("");

  const { data: suppliersData } = useGetSupplierListQuery(
    id,
    { name: searchValue, limit: 5 },
    { enabled: !!id && searchValue.length >= 3 }
  );

  const handleSearch = (value: string) => {
    setSearchValue(value);
    if (!value) {
      onChange("");
      onNameChange("");
    }
  };

  const autocompleteData: SelectItem[] =
    suppliersData?.data?.map((supplier: ISupplier) => ({ value: supplier.id, label: supplier.name })) || [];

  const onSelectChange = (value: string) => {
    const findSupplierName = autocompleteData?.find(data => data.value === value);
    onChange(value);
    if (findSupplierName) {
      onNameChange(findSupplierName.label || "");
    }
  };

  return (
    <ClearableSelect
      name="supplierId"
      data={autocompleteData}
      searchable
      clearable
      searchValue={searchValue}
      onSearchChange={handleSearch}
      onChange={onSelectChange}
      value={value}
      icon={<SearchIcon />}
      placeholder="Search Supplier"
      error={error}
    />
  );
};
