import styled from "@emotion/styled";
import { rem } from "@mantine/core";

export const ICardContainer = styled.div`
  position: relative;
  max-width: 400px;
  border-radius: 10px;
  background-color: #eeeeee;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: ${rem(275)};
  @media screen and (max-width: 1400px) {
    max-width: unset;
  }
`;

export const GraphWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
`;

export const CardWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 70px;
  align-items: center;
  justify-content: center;
  z-index: 3;
`;

export const CardParagraph = styled.p`
  margin: 0;
  font-size: 1.25rem;
  color: #666666;
  font-weight: 700;
  white-space: nowrap;
`;

export const CardDescription = styled(CardParagraph)`
  font-weight: 400;
  font-size: ${rem(20)};
`;

export const CardTitle = styled.h1`
  margin: 0;
  font-weight: 700;
  font-size: 3rem;
  color: #222222;
`;

export const CardSpan = styled.span<{ color: string }>`
  color: ${({ color }) => color};
`;
