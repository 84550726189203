import { identity, pickBy } from "lodash";
import axiosInstance from "services/AxiosInstance";
import { Optional, TAnalyticsFilters, TAnalyticsOptions } from "types";

export type TGetAnalyticsGroupedListServicePayload = Optional<
  TAnalyticsFilters,
  "buyspaceId" | "end" | "interval" | "start" | "timeWindow"
>;

export const getAnalyticsGroupedListService = async (
  payload: TGetAnalyticsGroupedListServicePayload,
  options?: TAnalyticsOptions
) => {
  const { accountId, ...rest } = { ...payload, ...options };

  const {
    start = "1970-01-01T00:00:00Z",
    end = "2030-01-01T00:00:00Z",
    interval = undefined,
    buyspaceId = null,
    timeWindow = "alltime"
  } = rest;

  const params = pickBy({ ...rest, start, end, interval, buyspaceId, timeWindow }, identity);

  const response = await axiosInstance.get(`/analytics/${accountId}/grouped`, { params });
  return response.data;
};
