import moment from "moment";
import axiosInstance from "services/AxiosInstance";
import { ResponseType } from "types";

export const getAnalyticsDatumService = async (accountId: string): Promise<ResponseType<{ mostRecent: string }>> => {
  try {
    const response = await axiosInstance.get(`analytics/${accountId}/latest-datum`);
    return response.data;
  } catch (err) {
    const mostRecent = moment().utcOffset(0).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISOString();
    return { data: { mostRecent }, meta: { type: "errorInterceptedResponse" } };
  }
};
