import moment from "moment";
import { Maybe } from "yup/lib/types";

export function prettyNumber(n: Maybe<number | string>): string {
  if (isNaN(Number(n)) || n === null) {
    return "--";
  }
  const number = Number(n);
  const absoluteNumber = Math.abs(number);

  const isBelowZero = number < 0;

  let formattedNumber = "";

  if (absoluteNumber <= 1e3) {
    formattedNumber = absoluteNumber + "";
  }
  if (absoluteNumber > 1e3 && absoluteNumber < 1e6) {
    formattedNumber = (absoluteNumber / 1e3).toFixed(2) + "K";
  }
  if (absoluteNumber >= 1e6) {
    formattedNumber = (absoluteNumber / 1e6).toFixed(2) + "M";
  }

  if (isBelowZero) {
    return "-" + formattedNumber;
  }

  return formattedNumber;
}

export const priceFormatter = (cents: number, currency: Intl.NumberFormatOptions["currency"] = "USD") => {
  const money = prettyCents(cents);

  return new Intl.NumberFormat("en-US", { style: "currency", currency }).format(money);
};

export const percentageFormatter = (value: Maybe<number | string>) => {
  if (isNaN(Number(value)) || value === null) {
    return "--";
  }

  value = Number(value);
  return `${(value * 100).toFixed(2)}%`;
};

const getCurrencySymbol = (locale: Intl.LocalesArgument, currency?: Intl.NumberFormatOptions["currency"]) =>
  (0)
    .toLocaleString(locale, { style: "currency", currency, minimumFractionDigits: 0, maximumFractionDigits: 0 })
    .replace(/\d/g, "")
    .trim();

export const decimalFormatter = (value: Maybe<number | string>) => {
  if (isNaN(Number(value)) || value === null) {
    return "--";
  }

  value = Number(value);
  const absoluteValue = Math.abs(value);

  if (absoluteValue < 0.001) {
    return "0";
  }
  if (absoluteValue < 1e3) {
    return value.toFixed(3);
  }
  if (absoluteValue >= 1e3 && value < 1e6) {
    const kValue = (value / 1e3).toFixed(2);
    return `${kValue}K`;
  }
  const mValue = (absoluteValue / 1e6).toFixed(2);
  return `${mValue}M`;
};

export const prettyCents = (value: number) => Number((value / 100).toFixed(2));

export function prettyMoney(
  cents: Maybe<number | string>,
  currency: Intl.NumberFormatOptions["currency"] = "USD"
): string {
  if (isNaN(Number(cents)) || cents === null) {
    return "--";
  }

  cents = Number(cents);
  const money = prettyCents(+cents);

  const symbol = getCurrencySymbol("en-US", currency);

  return symbol + (money > 1e3 ? prettyNumber(money.toFixed(2)) : Number(prettyNumber(money)).toFixed(2));
}

export const formatCO2eValue = (value: number | string = 0): string => {
  const valueToNumber = Number(value);

  if (isNaN(valueToNumber)) {
    return "-";
  }

  if (valueToNumber === 0) {
    return "0.0000";
  }

  if (valueToNumber > 0 && valueToNumber < 0.0001) {
    return "< 0.0001";
  }

  return valueToNumber.toFixed(4);
};

export const formatDateString = (dateString: string) => {
  const date = new Date(dateString);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  const formattedMonth = month < 10 ? `0${month}` : `${month}`;
  const formattedDay = day < 10 ? `0${day}` : `${day}`;
  return `${formattedMonth}/${formattedDay}/${year}`;
};

export const formatDateStringFull = (dateString: string) => {
  const date = new Date(dateString).toLocaleString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true
  });
  return date;
};

export const getFullDayYear = (dateString: string) => {
  const date = new Date(dateString).toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric"
  });
  return date;
};

export const getFullDayYearTime = (dateString: string) => {
  const date = new Date(dateString)
    .toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour12: true,
      hour: "numeric",
      minute: "numeric"
    })
    .replace("at", "")
    .replace(" PM", "pm");
  return date;
};

export const getDateNoTime = (date: Date | null) => {
  if (!date) return null;
  return moment(date).format("YYYY-MM-DD");
};

export const formatArrayText = (value: string | string[], endConnector = "or") => {
  if (Array.isArray(value)) {
    const spacerIndex = value.length - 1;
    return value.length > 1
      ? structuredClone(value).splice(0, spacerIndex).join(", ") + ` ${endConnector} ` + value[spacerIndex]
      : value[spacerIndex];
  }
  return value;
};
