import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { errorModal } from "modules/error/ErrorHandlingModals";
import { ErrorResponse } from "types";

import { getQuestionnaireRequestService } from "../api";

export const useGetQuestionnaireRequestQuery = (questionnaireRequestId?: string) =>
  useQuery({
    queryKey: [QUERY_KEYS.get_questionnaire_request, questionnaireRequestId],
    queryFn: () => getQuestionnaireRequestService(questionnaireRequestId),
    enabled: Boolean(questionnaireRequestId),
    useErrorBoundary: true,
    onError: (error: ErrorResponse) => errorModal(error)
  });
