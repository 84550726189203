import { FC, Fragment } from "react";

import { useCurrentUser } from "modules/login";
import { roleStatusMap } from "modules/users/components/configs/userTableAccountConfig";
import { USER_TYPE } from "modules/users/types/UserTypesEnum";
import { TUserAccount } from "types";
import { XIcon } from "ui/icons";

import { AccountNameStyle, RoleStyle, StyleAccountTaps, TextWrapper } from "./roleTile.styles";

type RoleTileProps = {
  accountDetails: TUserAccount;
  closeButton?: boolean;
  handleCloseButton?: (accountDetails: TUserAccount) => void;
};

export const RoleTile: FC<RoleTileProps> = ({ accountDetails, closeButton = false, handleCloseButton }) => {
  const { accountName, role } = accountDetails;
  const prettyRole = roleStatusMap[role].label;
  const isInternal = role.includes(USER_TYPE.internal);

  const {
    userDetails: { userId }
  } = useCurrentUser();

  const isUserMyself = accountDetails.userId === userId;

  const showRemoveButton = !isUserMyself && closeButton;

  const handleCloseButtonClick = () => handleCloseButton?.(accountDetails);

  return (
    <Fragment key={accountName + role}>
      <StyleAccountTaps>
        <TextWrapper>
          <AccountNameStyle isIntenal={isInternal}>{`${accountName}: `}</AccountNameStyle>
          <RoleStyle>{prettyRole}</RoleStyle>
        </TextWrapper>
        {showRemoveButton && (
          <XIcon style={{ cursor: "pointer" }} width="6.6" height="8.36" onClick={handleCloseButtonClick} />
        )}
      </StyleAccountTaps>
    </Fragment>
  );
};
