import { useEffect, useState } from "react";

import { localPoint } from "@visx/event";
import { Group } from "@visx/group";
import { Pie } from "@visx/shape";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { initialTableData } from "modules/analytics/analytics.data";
import { TAnalyticsChartData } from "types";
import { decimalFormatter, prettyMoney } from "utils";

import { PieProps } from "./DonutChartTypes";
import {
  StyleTooltipDescription,
  StyleTooltipPercent,
  StyleTooltipValue,
  TooltipOnTableInteraction,
  TooltipWrapper
} from "./donutChart.styles";

const defaultMargin = { top: 20, right: 20, bottom: 20, left: 20 };
const donutThickness = 35;

export function DonutChart({
  width,
  height,
  margin = defaultMargin,
  donutChartData = [],
  tableData = initialTableData,
  frequencyHolder,
  selectedID,
  setHover,
  setHoverOff
}: PieProps) {
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const top = centerY + margin.top;
  const left = centerX + margin.left;

  const pieSortValues = (a: number, b: number) => b - a;

  const frequency = (d: TAnalyticsChartData) =>
    frequencyHolder === "CO2e" ? Number(d.CO2TonsPercent) : Number(d.spendPercent);

  const [toolTipData, setToolTipData] = useState<TAnalyticsChartData | undefined>();
  const [percentage, setPercentage] = useState<number>(0);
  const [toolTipDataOnTableInteraction, setToolTipDataOnTableInteraction] = useState<TAnalyticsChartData | undefined>();

  // Tooltip
  const { tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } = useTooltip();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    scroll: true
  });

  const handleTableInteraction = () => {
    if (!selectedID) return;
    const regularTableItem = donutChartData.find((i: TAnalyticsChartData) => i.id === selectedID);
    const otherTableItem = tableData.standard.find(i => i.id === selectedID);

    const othersChartData = donutChartData.find((i: TAnalyticsChartData) => i.id === "tail");
    if (regularTableItem) {
      setToolTipDataOnTableInteraction(regularTableItem);
    } else {
      const selectedObject = {
        CO2Tons: otherTableItem?.CO2Tons,
        colorCode: othersChartData?.colorCode,
        cost: otherTableItem?.cost,
        spend: otherTableItem?.spend,
        description: othersChartData?.description,
        id: "tail",
        CO2TonsPercent: otherTableItem?.CO2TonsPercent,
        costPercent: otherTableItem?.costPercent,
        spendPercent: otherTableItem?.spendPercent
      };
      setToolTipDataOnTableInteraction(selectedObject as TAnalyticsChartData);
    }
  };

  useEffect(() => {
    handleTableInteraction();
  }, [selectedID, tooltipOpen]);

  const toolTipOnTableInteraction = (data: TAnalyticsChartData | undefined): JSX.Element => {
    const percentageDataCO2Tons = data?.CO2TonsPercent;
    const percentageDataSpend = data?.spendPercent;
    return (
      <TooltipOnTableInteraction>
        <TooltipWrapper>
          <StyleTooltipPercent
            style={{
              color: data?.colorCode
            }}
          >
            {`${frequencyHolder === "CO2e" ? percentageDataCO2Tons : percentageDataSpend}%`}
          </StyleTooltipPercent>
          <StyleTooltipDescription style={{ color: data?.colorCode }}>{data?.description}</StyleTooltipDescription>
          <StyleTooltipValue>{`${decimalFormatter(data?.CO2Tons)} | ${prettyMoney(
            Number(data?.spend)
          )}`}</StyleTooltipValue>
        </TooltipWrapper>
      </TooltipOnTableInteraction>
    );
  };
  return (
    <>
      <svg width={width} height={height} ref={containerRef}>
        <Group top={top} left={left}>
          <Pie
            data={donutChartData}
            pieValue={frequency}
            pieSortValues={pieSortValues}
            innerRadius={radius - donutThickness}
            outerRadius={radius}
          >
            {pie => {
              return pie.arcs.map((arc, index) => {
                const { description, colorCode } = arc.data;
                const arcPath = pie.path(arc) as string;

                return (
                  <g
                    key={`arc-${description}-${index}`}
                    id={arc.data.id}
                    onMouseEnter={(event: React.MouseEvent<SVGGElement>) => {
                      const coords = localPoint(event);
                      if (frequencyHolder === "CO2e") {
                        setPercentage(Number(arc.data.CO2TonsPercent));
                      } else {
                        setPercentage(Number(arc.data.spendPercent));
                      }
                      setHover && setHover(arc.data);
                      setToolTipData(arc.data);
                      showTooltip({
                        tooltipLeft: coords?.x,
                        tooltipTop: coords?.y
                      });
                    }}
                    onMouseLeave={(event: React.MouseEvent<SVGGElement>) => {
                      setHoverOff && setHoverOff(event);
                      hideTooltip();
                    }}
                    pointerEvents="all"
                  >
                    {selectedID == null ? (
                      <path id={arc.data.id + index} d={arcPath} fill={colorCode} />
                    ) : (
                      <path
                        id={arc.data.id + index}
                        d={arcPath}
                        fill={colorCode}
                        fillOpacity={arc.data.id === toolTipDataOnTableInteraction?.id ? "100%" : "50%"}
                        opacity={arc.data.id === toolTipDataOnTableInteraction?.id ? "1" : "0.3"}
                        style={{
                          filter:
                            arc.data.id === toolTipDataOnTableInteraction?.id
                              ? "drop-shadow(0px 6px 5px rgba(0, 0, 0, 0.2))"
                              : "",
                          transition: "filter 0.2s"
                        }}
                      />
                    )}
                  </g>
                );
              });
            }}
          </Pie>
        </Group>
      </svg>
      {selectedID && !tooltipOpen && toolTipOnTableInteraction(toolTipDataOnTableInteraction)}
      {tooltipOpen && (
        <TooltipInPortal key={toolTipData?.id} top={tooltipTop} left={tooltipLeft}>
          <TooltipWrapper>
            <StyleTooltipPercent
              style={{
                color: toolTipData?.colorCode
              }}
            >
              {`${percentage || 0}%`}
            </StyleTooltipPercent>
            <StyleTooltipDescription style={{ color: toolTipData?.colorCode }}>
              {toolTipData?.description}
            </StyleTooltipDescription>
            <StyleTooltipValue>
              {`${decimalFormatter(toolTipData?.CO2Tons)} | ${prettyMoney(Number(toolTipData?.spend))}`}
            </StyleTooltipValue>
          </TooltipWrapper>
        </TooltipInPortal>
      )}
    </>
  );
}
