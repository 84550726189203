import { Box, Text } from "@mantine/core";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { LinkComponent } from "components";
import { monochromeColor } from "constants/colorPalette";
import { TAnalyticsFilters, TQuestionnaireRequest, TReportsDataSource } from "types";
import { decimalFormatter, prettyMoney } from "utils/formatting.utils";

import { MILLIONS_OF_DOLLARS } from "../analytics.utils";
import { InsightTableCell, SubValue, TableCell, TableHeadCellCentered, TableHeadNameCell } from "../styles";
import { InsightCommodityLink } from "./InsightCommodityLink";
import { InsightQuestionnaireCell } from "./InsightQuestionnaireCell";
import { InsightTransactionCell } from "./InsightTransactionCell";
import { InsightsDataQualityCell } from "./InsightsDataQualityCell";
import { InsightsDueCell } from "./InsightsDueCell";
import { InsightsSupplierCell } from "./InsightsSupplierCell";
import { ReportsAnalysisQualityCell } from "./ReportsAnalysisQualityCell";
import { ReportsNameCell } from "./ReportsNameCell";
import { renderIntensityStatus } from "./renderStatus.util";

export type TCustomColumnAccessor = { filters: TAnalyticsFilters; row: TReportsDataSource };

export type TQuestionnaireColumnAccessor = {
  groupAnalytics: TReportsDataSource & { mean: number; sDeviation: number };
  list: TQuestionnaireRequest[];
  sortingRank: number;
  supplier: TQuestionnaireRequest["supplier"];
};

export const columns: ColumnDef<
  TReportsDataSource,
  TCustomColumnAccessor | TReportsDataSource[keyof TReportsDataSource]
>[] = [
  {
    id: "nameCell",
    header: ({ column }) => <TableHeadNameCell isSortActive={!!column.getIsSorted()}>Name</TableHeadNameCell>,
    accessorKey: "description",
    enableSorting: true,
    minSize: 430,
    cell: info => <ReportsNameCell {...(info as CellContext<TReportsDataSource, TCustomColumnAccessor>)} />,
    sortingFn: (rowA, rowB) => rowB.original.description.localeCompare(rowA.original.description)
  },
  {
    header: ({ column }) => (
      <TableHeadCellCentered isSortActive={!!column.getIsSorted()}>CO₂e Tons</TableHeadCellCentered>
    ),
    accessorKey: "CO2Tons",
    enableSorting: true,
    size: 120,
    sortingFn: (rowA, rowB) => {
      const rankA = rowA.original.order.byCO2eOrder;

      const rankB = rowB.original.order.byCO2eOrder;

      return rankA < rankB ? 1 : rankA > rankB ? -1 : 0;
    },
    cell: info => {
      const value = info.getValue() as number;

      const rank = info.row.original.order.byCO2eOrder;

      return (
        <TableCell>
          <Text c={monochromeColor[3]}>(#{rank})</Text>
          {decimalFormatter(value)}
        </TableCell>
      );
    },
    meta: {
      align: "right",
      reverseHeader: true
    }
  },
  {
    header: ({ column }) => <TableHeadCellCentered isSortActive={!!column.getIsSorted()}>Spend</TableHeadCellCentered>,
    accessorKey: "spend",
    enableSorting: true,
    size: 90,
    sortingFn: (rowA, rowB) => {
      const rankA = rowA.original.order.bySpendOrder;

      const rankB = rowB.original.order.bySpendOrder;

      return rankA < rankB ? 1 : rankA > rankB ? -1 : 0;
    },
    cell: info => {
      const value = info.getValue() as number;

      const rank = info.row.original.order.bySpendOrder;

      return (
        <TableCell>
          <Text c={monochromeColor[3]}>(#{rank})</Text>
          {prettyMoney(value)}
        </TableCell>
      );
    },
    meta: {
      align: "right",
      reverseHeader: true
    }
  },
  {
    header: ({ column }) => (
      <TableHeadCellCentered isSortActive={!!column.getIsSorted()}>Carbon Intensity</TableHeadCellCentered>
    ),
    accessorKey: "carbonIntensity",
    enableSorting: true,
    sortingFn: (rowA, rowB) => {
      const previousValue = rowA.original.order.byCarbonIntensityOrder;

      const nextValue = rowB.original.order.byCarbonIntensityOrder;

      return previousValue < nextValue ? 1 : previousValue > nextValue ? -1 : 0;
    },
    size: 90,
    cell: info => {
      const { CO2Tons, spend } = info.row.original;

      const overallCarbonIntensity = spend === 0 ? 0 : CO2Tons / (spend / MILLIONS_OF_DOLLARS);

      const carbonIntensityValue = decimalFormatter(overallCarbonIntensity);

      const rank = info.row.original.order.byCarbonIntensityOrder;

      return (
        <TableCell>
          <Text c={monochromeColor[3]}>(#{rank})</Text>
          {carbonIntensityValue}
        </TableCell>
      );
    },
    meta: {
      align: "right",
      reverseHeader: true
    }
  },
  {
    header: ({ column }) => (
      <TableHeadCellCentered isSortActive={!!column.getIsSorted()}>Analysis Quality</TableHeadCellCentered>
    ),
    accessorKey: "_analysisValue",
    enableSorting: true,
    sortingFn: (rowA, rowB) => {
      const analysisValueA = rowA.original._analysisValue;
      const analysisValueB = rowB.original._analysisValue;
      if (analysisValueA && analysisValueB) {
        return analysisValueA - analysisValueB;
      }
      return 0;
    },
    size: 90,
    cell: info => {
      const methodology = info.row.original.methodology;

      return <ReportsAnalysisQualityCell {...methodology} />;
    },

    meta: {
      align: "right",
      reverseHeader: true
    }
  },
  { header: "", id: "padding", size: 12 }
];

export const insightSupplierColumns: ColumnDef<
  TReportsDataSource & { mean: number; sDeviation: number },
  keyof (TReportsDataSource & { mean: number; sDeviation: number })
>[] = [
  {
    header: "Rank",
    accessorKey: "order.byCO2eOrder",
    enableSorting: false,
    size: 90,
    cell: info => {
      const rank = info.row.index + 1;
      return <SubValue>#{rank}</SubValue>;
    },
    meta: {
      align: "center"
    }
  },
  {
    header: "Supplier",
    accessorKey: "description",
    enableSorting: false,
    size: 430,
    cell: info => {
      const name = info.getValue();
      return (
        <Box maw="fit-content">
          <LinkComponent to={`/supplier/${info.row.original.supplierId}`}>
            <Text c="#313c34" fw="bold" td="underline">
              {name}
            </Text>
          </LinkComponent>
        </Box>
      );
    }
  },
  {
    header: "CO₂e Tons",
    accessorKey: "CO2Tons",
    enableSorting: false,
    size: 130,
    cell: info => {
      const value = info.getValue();
      return (
        <InsightTableCell>
          <div>{decimalFormatter(value)}</div>
          <SubValue>{info.row.original.CO2TonsPercent}% Total</SubValue>
        </InsightTableCell>
      );
    },
    meta: {
      align: "center"
    }
  },
  {
    header: "Spend",
    accessorKey: "spend",
    enableSorting: false,
    size: 130,
    cell: info => {
      const value = info.getValue();
      return (
        <InsightTableCell>
          <div>{prettyMoney(value)}</div>
          <SubValue>#{info.row.original.order.bySpendOrder} Overall</SubValue>
        </InsightTableCell>
      );
    },
    meta: {
      align: "center"
    }
  },
  {
    header: "Carbon Intensity",
    accessorKey: "carbonIntensity",
    enableSorting: false,
    size: 260,
    cell: info => {
      const { CO2Tons, spend, order, mean, sDeviation } = info.row.original;
      const overallCarbonIntensity = spend === 0 ? 0 : CO2Tons / (spend / MILLIONS_OF_DOLLARS);
      const carbonIntensityValue = decimalFormatter(overallCarbonIntensity);

      const status = renderIntensityStatus(overallCarbonIntensity, mean, sDeviation);

      return (
        <>
          {status}
          <SubValue>
            {carbonIntensityValue} (#{order?.byCarbonIntensityOrder} Overall)
          </SubValue>
        </>
      );
    },
    meta: {
      align: "center"
    }
  },
  {
    header: "Data Source Quality",
    accessorKey: "dataQuality",
    enableSorting: false,
    cell: info => <InsightsDataQualityCell {...info.row.original} />,
    meta: {
      align: "center"
    }
  },
  {
    id: "supplierQuestionnaire",
    header: "Questionnaires",
    accessorKey: "supplierId",
    enableSorting: false,
    size: 160,
    cell: info => <InsightQuestionnaireCell {...info.row.original} />,
    meta: {
      align: "center"
    }
  }
];

export const insightCommodityColumns: ColumnDef<
  TReportsDataSource & { items: number; mean: number; sDeviation: number; suppliers: number },
  keyof (TReportsDataSource & { items: number; mean: number; sDeviation: number; suppliers: number })
>[] = [
  {
    header: "Rank",
    accessorKey: "order.byCO2eOrder",
    enableSorting: false,
    size: 90,
    cell: info => {
      const name = info.getValue();
      return <SubValue>#{name}</SubValue>;
    },
    meta: {
      align: "center"
    }
  },
  {
    header: "Commodity",
    accessorKey: "description",
    enableSorting: false,
    size: 430,
    cell: info => {
      return (
        <Box maw="fit-content">
          <InsightCommodityLink {...info.row.original} />
        </Box>
      );
    }
  },
  {
    header: "CO₂e Tons",
    accessorKey: "CO2Tons",
    enableSorting: false,
    size: 130,
    cell: info => {
      const value = info.getValue();
      return (
        <InsightTableCell>
          <div>{decimalFormatter(value)}</div>
          <SubValue>{info.row.original.CO2TonsPercent}% Total</SubValue>
        </InsightTableCell>
      );
    },
    meta: {
      align: "center"
    }
  },
  {
    header: "Spend",
    accessorKey: "spend",
    enableSorting: false,
    size: 130,
    cell: info => {
      const value = info.getValue();
      return (
        <InsightTableCell>
          <div>{prettyMoney(value)}</div>
          <SubValue>#{info.row.original.order.bySpendOrder} Overall</SubValue>
        </InsightTableCell>
      );
    },
    meta: {
      align: "center"
    }
  },
  {
    header: "Carbon Intensity",
    accessorKey: "carbonIntensity",
    enableSorting: false,
    size: 260,
    cell: info => {
      const { CO2Tons, spend, order, mean, sDeviation } = info.row.original;
      const overallCarbonIntensity = spend === 0 ? 0 : CO2Tons / (spend / MILLIONS_OF_DOLLARS);
      const carbonIntensityValue = decimalFormatter(overallCarbonIntensity);

      const status = renderIntensityStatus(overallCarbonIntensity, mean, sDeviation);

      return (
        <>
          {status}
          <SubValue>
            {carbonIntensityValue} (#{order?.byCarbonIntensityOrder} Overall)
          </SubValue>
        </>
      );
    },
    meta: {
      align: "center"
    }
  },
  {
    header: "Data Source Quality",
    accessorKey: "dataQuality",
    enableSorting: false,
    cell: info => <InsightsDataQualityCell {...info.row.original} />,
    meta: {
      align: "center"
    }
  },
  {
    header: "Suppliers",
    id: "suppliers",
    enableSorting: false,
    size: 160,
    meta: {
      align: "center"
    },
    cell: info => {
      return (
        <InsightTableCell>
          <InsightsSupplierCell {...info.row.original} />
        </InsightTableCell>
      );
    }
  }
];

export const insightsQuestionnaireColumns: ColumnDef<
  TQuestionnaireColumnAccessor,
  keyof TQuestionnaireColumnAccessor
>[] = [
  {
    header: () => <Box pl="2rem">Supplier</Box>,
    accessorKey: "supplier",
    enableResizing: false,
    enableSorting: false,
    size: 536,
    cell: info => {
      const { id, name } = info.row.original.supplier;
      return (
        <Box maw="fit-content" pl="1rem">
          <LinkComponent to={`/supplier/${id}`}>
            <Text c="#313c34" fw="bold" td="underline">
              {name}
            </Text>
          </LinkComponent>
        </Box>
      );
    }
  },
  {
    header: "Status",
    enableSorting: false,
    size: 130,
    cell: info => (
      <InsightQuestionnaireCell
        supplierId={info.row.original.supplier.id}
        description={info.row.original.supplier.name}
      />
    ),
    meta: {
      align: "center"
    }
  },
  {
    header: "Due Date",
    enableSorting: false,
    size: 130,
    cell: info => <InsightsDueCell questionnaireRequestList={info.row.original.list} />,
    meta: {
      align: "center"
    }
  },
  {
    header: "Priority",
    enableSorting: false,
    size: 260,
    cell: info => {
      const { groupAnalytics } = info.row.original;

      const status = renderIntensityStatus(groupAnalytics?.CO2Tons, groupAnalytics?.mean, groupAnalytics?.sDeviation);

      return (
        <>
          {status}
          <SubValue>#{groupAnalytics?.order?.byCarbonIntensityOrder} Co₂e Overall</SubValue>
        </>
      );
    },
    meta: {
      align: "center"
    }
  },
  {
    header: "Transactions",
    enableSorting: false,
    size: 160,
    cell: info => (
      <InsightTableCell>
        <InsightTransactionCell {...info.row.original.supplier} />
      </InsightTableCell>
    ),
    meta: {
      align: "center"
    }
  }
];
