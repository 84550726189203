import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import moment from "moment";
import { ResponseType, TAnalyticsData, TAnalyticsOptions } from "types";

import { getAnalyticsSummaryService, TGetAnalyticsSummaryServicePayload } from "../api";
import { useInsightContext } from "../insight.context";

type TUseGetAnalyticsSummary = (
  payload: TGetAnalyticsSummaryServicePayload,
  options?: TAnalyticsOptions
) => UseQueryResult<ResponseType<TAnalyticsData[]> & { summary: TAnalyticsData }>;

export const useGetAnalyticsSummaryQuery: TUseGetAnalyticsSummary = (payload, options) => {
  const insightContext = useInsightContext();

  return useQuery({
    queryKey: [QUERY_KEYS.get_analytics_summary_query, { ...payload }],
    queryFn: () => getAnalyticsSummaryService({ ...payload }, options),
    enabled: Boolean(payload.accountId),
    onSuccess: data => {
      if (data.data) {
        const sortedData = data.data.sort((a, b) => {
          return moment(a.interval[0]).valueOf() - moment(b.interval[0]).valueOf();
        });
        const startDate = moment(sortedData[0].interval[0].split("T"), "YYYY-MM-DD").format("MMM ‘YY");
        const endDate = moment(sortedData[data.data.length - 1].interval[0].split("T"), "YYYY-MM-DD").format("MMM ‘YY");
        const rangeString = `${startDate} - ${endDate}`;
        insightContext?.onRangeChange(rangeString);
      }
    }
  });
};
