import styled from "@emotion/styled";

export const TableCell = styled.div`
  display: inline-flex;
  gap: 5px;
  color: ${props => props.theme.other.monochromeColor[2]};
  font-size: 1rem;
`;

export const TableNameCellWrapper = styled.div<{ active?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0.5rem;
  gap: 1rem;
  pointer-events: all;
  width: min-content;
  white-space: nowrap;

  ${({ active }) => {
    if (active) {
      return `    
      text-decoration: underline;
      cursor: pointer;`;
    }
  }}

  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const TableHeadNameCell = styled.div<{ isSortActive?: boolean }>`
  color: ${({ theme, isSortActive }) => isSortActive && theme.other.primaryColor};
`;

export const TableHeadCellCentered = styled.div<{ isSortActive?: boolean }>`
  & > div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  color: ${({ theme, isSortActive }) => isSortActive && theme.other.primaryColor};
`;

export const TableNameCell = styled.div`
  border: none !important;
  text-align: left;
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: 1rem;
  text-transform: capitalize;
  color: ${props => props.theme.other.monochromeColor[2]};
  pointer-events: none;
`;

export const ColorIndicator = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  float: left;
  min-width: 14px;
`;

export const InsightTableCell = styled.div`
  font-weight: 700;
  font-size: 1.5rem;

  div:first-of-type {
    color: #313c34;
  }

  div:nth-of-type(2) {
    color: #999999;
  }
`;

export const NotSentTableCell = styled.div`
  font-weight: 700;
  font-size: 1.5rem;
  color: #999999;
`;

export const SubValue = styled.div<{ sm?: boolean }>`
  color: #999999 !important;
  font-weight: 700;
  font-size: ${({ sm }) => (sm ? "1" : "1.25")}rem;
`;
