import { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { LoadingOverlay } from "@mantine/core";
import { SortingState } from "@tanstack/react-table";
import { orderBy, pick } from "lodash";
import moment from "moment";
import { unparse } from "papaparse";
import {
  TAnalyticsChartData,
  TAnalyticsCustomInterval,
  TAnalyticsFilters,
  TAnalyticsResponse,
  TAnalyticsTableData,
  TOverviewDashboardModal
} from "types";
import Loader from "ui/feedback/Loader";
import { AnalyticsSubNavigation } from "ui/layouts/common";
import { convertToPaginated, percentageFormatter, priceFormatter } from "utils";
import { parseQueryParams } from "utils/query.utils";

import {
  analyticsServiceOptions,
  customIntervalInitial,
  initialTableData,
  overviewDashboardModalInitial
} from "../analytics.data";
import { initialReportsFilters } from "../analytics.types";
import {
  calculateRanks,
  calculateStandardDeviation,
  calculateWeightedMethodologyValue,
  convertTableDataWithOthers,
  convertToTabular,
  generateRandomColors,
  MILLIONS_OF_DOLLARS
} from "../analytics.utils";
import { useGetAnalyticsGrouped, useInsightDates } from "../hooks";
import { ReportLayout } from "../styles";
import { ReportsAdditionalFilters } from "./ReportsAdditionalFilters";
import { ReportsDashboard } from "./ReportsDashboard";
import { ReportsFilterTiles } from "./ReportsFilterTiles";
import { ReportsFilters } from "./ReportsFilters";
import { ReportsIntervalModal, TIntervalUpdateState } from "./ReportsIntervalModal";
import { ReportsTable } from "./ReportsTable";

const arrayFilters = ["methods", "naicsCodes", "naicsIndustries", "naicsSectors"];

export const Reports = () => {
  const { search } = useLocation();

  const { id: accountId } = useParams() as { id: string };

  const { start, end } = useInsightDates(accountId);

  const bookmarkedParams = parseQueryParams(search, { decoder: c => c });

  const initialFilters: initialReportsFilters = {
    group: "supplier",
    timeWindow: "mry",
    buyspaceId: null,
    start,
    end
  };

  const [filters, setFilters] = useState<TAnalyticsFilters>({
    accountId,
    ...initialFilters,
    ...bookmarkedParams
  });

  const [sorting, setSorting] = useState<SortingState>([
    {
      id: "CO2Tons",
      desc: true
    }
  ]);

  const [modal, setModal] = useState<TOverviewDashboardModal>(overviewDashboardModalInitial);

  const [customInterval, setCustomInterval] = useState<TAnalyticsCustomInterval>(customIntervalInitial);

  const [tableData, setTableData] = useState<TAnalyticsTableData>(initialTableData);

  const [chartData, setChartData] = useState<{
    emissionChart: TAnalyticsChartData[];
    spendChart: TAnalyticsChartData[];
  }>({
    spendChart: [],
    emissionChart: []
  });

  const [selectedGroup, setSelectedGroup] = useState<{ from: "chart" | "table"; id: string | null }>({
    id: null,
    from: "table"
  });

  const [page, setPage] = useState(0);

  const { data, isLoading } = useGetAnalyticsGrouped(filters, {
    ...analyticsServiceOptions,
    optionsExcludeCounts: false
  });

  const { data: baseDataWithoutBuyspace } = useGetAnalyticsGrouped(
    { ...filters, buyspaceId: null },
    {
      ...analyticsServiceOptions,
      optionsExcludeCounts: false
    }
  );

  const carbonIntensities = baseDataWithoutBuyspace?.data?.map((item: TAnalyticsResponse) => {
    const { co2e, spend } = item.data[0];

    const carbonIntensity = spend > 0 ? co2e / (spend / MILLIONS_OF_DOLLARS) : 0;

    return carbonIntensity;
  });

  const { sDeviation, mean } = useMemo(
    () => calculateStandardDeviation(carbonIntensities || []),
    [baseDataWithoutBuyspace]
  );

  const onFilterChange = (filterKey: keyof TAnalyticsFilters, value?: string | null) => {
    if (arrayFilters.includes(filterKey) && value) {
      setFilters(prevState => ({
        ...prevState,
        [filterKey]: (prevState?.[filterKey] as string[])?.filter(v => v !== value)
      }));
      return;
    }

    const initialValue = pick(initialFilters, filterKey);

    setFilters(prevState => ({ ...prevState, ...initialValue }));
  };

  const updateData = () => {
    const { data: analyticsData, summary } = data;
    const tableColors = generateRandomColors(analyticsData);
    const tabularTableData = convertToTabular(analyticsData, summary, tableColors);
    const updatedPaginatedTableData = convertToPaginated(tabularTableData);
    const { emissionChartData, spendChartData } = convertTableDataWithOthers(tabularTableData, summary, tableColors);

    const carbonIntensityArray = tabularTableData.map(data => data.carbonIntensity);
    const { sDeviation, mean } = calculateStandardDeviation(carbonIntensityArray);

    setTableData({
      standard: tabularTableData,
      paginated: updatedPaginatedTableData,
      summary,
      statistics: { sDeviation, mean }
    });
    setChartData({ emissionChart: emissionChartData, spendChart: spendChartData });
  };

  const onSubmitInteval = (interval: TIntervalUpdateState) => {
    if (!interval.end && !interval.start) {
      return setFilters(prev => ({ ...prev, start, end, timeWindow: "mry" }));
    }
    setFilters(prev => ({ ...prev, ...interval, timeWindow: "custom" }));
  };

  const onDownloadReport = () => {
    const csvData = calculateRanks(tableData.standard).map(data => ({
      ...data,
      _analysisValue: calculateWeightedMethodologyValue(data.methodology)
    }));

    const orderId = sorting?.[0]?.id || "CO2Tons";
    const orderString = sorting?.[0]?.desc ? "desc" : "asc";

    const sortedData = orderBy(csvData, [orderId], [orderString]) as typeof csvData;

    const transformedData = sortedData.map(data => ({
      Name: data.description,
      CO2e: data.CO2Tons,
      "CO2e Rank": data.order.byCO2eOrder,
      Spend: priceFormatter(data.spend),
      "Spend Rank": data.order.bySpendOrder,
      "Carbon Intensity": data.carbonIntensity,
      "Carbon Intensity Rank": data.order.byCarbonIntensityOrder,
      "Supplier Specific Percentage": percentageFormatter(data.methodology.ss),
      "Commodity Based Percentage": percentageFormatter(data.methodology.cb),
      "Spend Percentage": percentageFormatter(data.methodology.sb)
    }));

    const csv = unparse(transformedData);
    const url = window.URL.createObjectURL(new Blob([csv]));
    const link = document.createElement("a");
    link.href = url;
    const fileName = `Report ${moment().format("DD MMM YY")}.csv`;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  useEffect(() => setCustomInterval(customIntervalInitial), [filters.timeWindow]);

  useEffect(() => {
    if (!isLoading) updateData();
  }, [data]);

  return (
    <div>
      <AnalyticsSubNavigation
        extra={<ReportsFilters {...{ initialFilters, filters, setFilters, setPage, setModal, onDownloadReport }} />}
      />
      <ReportLayout>
        <LoadingOverlay loader={<Loader />} visible={isLoading} transitionDuration={500} />
        <ReportsFilterTiles onFilterChange={onFilterChange} filters={filters} />
        <ReportsDashboard
          {...{
            filters,
            chartData,
            selectedGroup,
            setSelectedGroup,
            tableData,
            summaryBase: baseDataWithoutBuyspace?.summary,
            summaryStatistics: { sDeviation, mean }
          }}
        />
        <ReportsTable
          {...{ filters, isLoading, page, setPage, setSelectedGroup, tableData, selectedGroup, sorting, setSorting }}
        />
      </ReportLayout>
      <ReportsIntervalModal
        opened={modal.customInterval}
        setModal={setModal}
        applyChanges={onSubmitInteval}
        interval={customInterval}
        setCustomInterval={setCustomInterval}
      />
      <ReportsAdditionalFilters {...{ filters, setFilters, modal, setModal }} />
    </div>
  );
};
