import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { createNewAccount } from "modules/account/apis/CreateNewAccount";
import { errorModal } from "modules/error/ErrorHandlingModals";
import { ErrorResponse } from "types";

const useCreateAccountMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createNewAccount,
    mutationKey: [QUERY_KEYS.create_account],
    onSuccess: () => {
      queryClient.refetchQueries({ queryKey: [QUERY_KEYS.accounts_listing] });
    },
    onError: (error: ErrorResponse) => errorModal(error)
  });
};

export default useCreateAccountMutation;
