import { memo, useMemo } from "react";
import { useParams } from "react-router-dom";

import { Box, Skeleton, Stack, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { LinkComponent } from "components";
import { useGetQuestionnaireRequestListQuery } from "modules/questionnaire";
import { QuestionnaireRequestModal } from "modules/questionnaire";
import moment from "moment";
import { QuestionnaireRequestStatus } from "types";

import { isOverdue, isPending } from "../analytics.utils";
import { InsightTableCell, NotSentTableCell, SubValue } from "../styles";

export const InsightQuestionnaireCell = memo(
  ({ supplierId, description }: { description: string; supplierId: string }) => {
    const { id } = useParams();

    const { data, isLoading } = useGetQuestionnaireRequestListQuery({
      apiArgs: { limit: 99999999, page: 0, sortBy: "updatedAt,desc" },
      pathArgs: { accountId: id as string, supplierId }
    });

    const [opened, { close, open }] = useDisclosure();

    const questionnaireRequests = [...(data?.data || [])];

    const pendingRequestList = questionnaireRequests.filter(i => isPending(i));

    const overdueRequestList = questionnaireRequests.filter(i => isOverdue(i));

    const pendingRequests = pendingRequestList.length;

    const overdueRequests = overdueRequestList.length;

    const latestQuestionnaireRequest = useMemo(() => {
      if (overdueRequests) return overdueRequestList[overdueRequests - 1];
      if (pendingRequests) return pendingRequestList[0];
      return questionnaireRequests[0];
    }, [pendingRequestList, overdueRequestList]);

    const renderStatus = () => {
      if (!questionnaireRequests.length) {
        return (
          <NotSentTableCell>
            <Text>Not Sent</Text>
            <Box onClick={open} td="underline" style={{ fontSize: "1.25rem", cursor: "pointer" }}>
              Send Request
            </Box>
          </NotSentTableCell>
        );
      }

      const updatedAtDate = moment(latestQuestionnaireRequest.updatedAt).format("M/DD");

      const respondByDate = moment(latestQuestionnaireRequest.respondBy).format("M/DD");

      const isComplete = [
        QuestionnaireRequestStatus.APPROVED,
        QuestionnaireRequestStatus.REJECTED,
        QuestionnaireRequestStatus.SUBMITTED
      ].includes(latestQuestionnaireRequest.status);

      if (overdueRequests) {
        return (
          <>
            <div>
              <Text c="#B20B33" fw={700} td="underline">
                <LinkComponent to={`/questionnaires/requests/${id}?supplierIds=${supplierId}`}>
                  ! {overdueRequests} Overdue
                </LinkComponent>
              </Text>
            </div>
            <SubValue>
              <Text c="#B20B33" fw={700}>
                Due {respondByDate}
              </Text>
            </SubValue>
          </>
        );
      }

      if (pendingRequests) {
        return (
          <InsightTableCell>
            <Text td="underline">
              <LinkComponent to={`/questionnaires/requests/${id}?supplierIds=${supplierId}`}>
                {pendingRequests} Pending
              </LinkComponent>
            </Text>
            <SubValue>Due {respondByDate}</SubValue>
          </InsightTableCell>
        );
      }

      if (isComplete) {
        return (
          <InsightTableCell>
            <Text td="underline">
              <LinkComponent to={`/questionnaires/requests/${id}?supplierIds=${supplierId}`}>Complete</LinkComponent>
            </Text>
            <SubValue>Sent {updatedAtDate}</SubValue>
          </InsightTableCell>
        );
      }

      return (
        <InsightTableCell>
          <div style={{ textTransform: "capitalize" }}>
            <LinkComponent to={`/questionnaires/requests/${id}?supplierIds=${supplierId}`}>
              {latestQuestionnaireRequest.status.toLowerCase()}
            </LinkComponent>
          </div>
          <SubValue>Due {updatedAtDate}</SubValue>
        </InsightTableCell>
      );
    };

    if (isLoading) {
      return (
        <Stack align="center" spacing="xs">
          <Skeleton height="1rem" radius="md" w="50%" />
          <Skeleton height="1rem" radius="md" w="35%" />
        </Stack>
      );
    }

    return (
      <div>
        {renderStatus()}
        <QuestionnaireRequestModal
          supplier={{ account: { id: id as string, name: "" }, name: description, id: supplierId }}
          opened={opened}
          setOpened={close}
        />
      </div>
    );
  }
);
