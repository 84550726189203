import { useContext } from "react";

import { CurrentUserContext } from "modules/login/CurrentUserContext";
import { PencilIcon } from "ui/icons";

import { AccountNameContainer, HeaderContainer, HeaderWrapper, PageDescription } from "./privateLayoutSubHeader.styles";

type PrivateLayoutSubHeaderProps = {
  accountName?: string | null;
  description?: string;
  setShowUpdateAccountModal?: React.Dispatch<React.SetStateAction<boolean>>;
  showEditAccountDetailsIcon?: boolean;
};

const PrivateLayoutSubHeader: React.FC<PrivateLayoutSubHeaderProps> = ({
  accountName = null,
  description,
  showEditAccountDetailsIcon,
  setShowUpdateAccountModal
}) => {
  const { userDetails } = useContext(CurrentUserContext);

  return (
    <div>
      <HeaderContainer>
        <HeaderWrapper>
          <AccountNameContainer>
            {accountName ?? userDetails.accountName}
            {showEditAccountDetailsIcon && (
              <div>
                <PencilIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowUpdateAccountModal && setShowUpdateAccountModal(true)}
                />
              </div>
            )}
          </AccountNameContainer>
          {description && <PageDescription>{description}</PageDescription>}
        </HeaderWrapper>
      </HeaderContainer>
    </div>
  );
};
export default PrivateLayoutSubHeader;
