import styled from "@emotion/styled";
import { Avatar, AvatarProps, Table } from "@mantine/core";
import { ProfileLeadLogo } from "ui/icons";

export const TableWrapper = styled.div`
  overflow-x: auto;
  margin-top: ${props => props.theme.other.table.marginTop};
  margin-bottom: ${props => props.theme.other.table.marginBottom};
`;

export const StyledTable = styled(Table)`
  border-spacing: 0px;
  table-layout: auto;
  width: 100%;

  tbody {
    tr > td {
      box-sizing: border-box;
      padding: 1rem;
    }
  }

  td:last-child,
  th:last-child {
    border-right: none !important;
  }
`;

export const StyledTableHead = styled.thead`
  position: sticky;
  top: -1px;
  z-index: 2;
`;

export const StyleTH = styled.th<{ isLast: boolean }>`
  border-right: 1px solid ${props => props.theme.other.monochromeColor[3]} !important;
  background: ${props => props.theme.other.monochromeColor[4]};
  padding: 12px 16px !important;
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: 1rem !important;
  color: ${props => props.theme.other.monochromeColor[1]} !important;
  border-top-width: 1px;
  width: ${({ isLast }) => (isLast ? "50px" : "auto")} !important;
  text-align: ${({ isLast }) => (isLast ? "center" : "left")} !important;
`;

export const StyleTD = styled.td`
  border-right: 1px solid ${props => props.theme.other.monochromeColor[3]} !important;
  border-top: none !important;
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: ${props => props.theme.other.font.fontSize} !important;
  line-height: ${props => props.theme.other.font.lineHeight};
  color: ${props => props.theme.other.monochromeColor[1]};
  text-align: left;
  height: auto;
  position: relative;
  padding: 1rem;
  max-width: 300px;
`;

export const StyleHeaderTable = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 3rem;
  color: ${props => props.theme.other.primaryColor};
  display: flex;
  align-items: center;
  gap: 15px;
  text-align: center;
  width: 100%;
  justify-content: center;
`;

export const TableTextWrapper = styled.div`
  overflow-wrap: break-word;
  overflow: hidden;
`;

export const TableTextWrapperIcon = styled(TableTextWrapper)`
  cursor: pointer;
  width: fit-content;
  margin: 0 auto;
`;

export const StyleMenuWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const AvatarWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
`;
export const AvatarLeadWrapper = styled.div`
  position: relative;
  margin-left: 10px;
`;
export const StyleMemoProfileLeadLogo = styled(ProfileLeadLogo)`
  position: absolute;
  bottom: 0px;
  left: -10px;
  color: ${({ theme }) => theme.other.primaryColor};
`;

export const StyleUserProfileImage = styled(Avatar)<AvatarProps>`
  border-radius: 50%;
  width: 54px;
  height: 54px;
  min-width: 54px;
  border: 2px solid ${props => props.theme.other.secondaryColor};
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AndMoreButton = styled.div`
  background: ${props => props.theme.other.monochromeColor[2]};
  border: 1px solid ${props => props.theme.other.monochromeColor[2]};
  border-radius: 50px;
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 500;
  font-size: ${props => props.theme.other.font.fontSize} !important;
  line-height: ${props => props.theme.other.font.lineHeight};
  color: ${props => props.theme.other.monochromeColor[6]};
  min-width: max-content;
  width: fit-content;
  padding: 4px 11px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin: 3.5px 5px 3.5px 6px;
`;

export const Wrapper = styled.div`
  width: 100%;
  @media only screen and (max-width: 1366px) {
    width: calc(100% - 20px);
    margin-left: auto;
    margin-right: auto;
  }
`;

export const TableHeaderOptionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 705px) {
    display: grid;
    gap: 12px;
    justify-content: center;
    width: 100%;
  }
`;
export const StyleButtonSearchWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  @media only screen and (max-width: 705px) {
    display: grid;
    gap: 12px;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledHeader = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: ${props => props.theme.other.font.header2.fontWeight};
  padding-top: ${props => props.theme.other.font.header2.paddingTop};
  font-size: ${props => props.theme.other.font.header2.fontSize};
  line-height: ${props => props.theme.other.font.header2.lineHeight};
  color: ${props => props.theme.other.primaryColor};
  padding-bottom: ${props => props.theme.other.font.header2.paddingBottom};
  text-align: left;
  @media only screen and (max-width: 705px) {
    text-align: center;
  }
`;
