import { useState } from "react";

import styled from "@emotion/styled";
import { Button, Grid, LoadingOverlay, Select, TextInput } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { Country, State } from "country-state-city";
import useCreateAccountMutation from "modules/account/mutations/CreateAccount";
import { TAccountCreation } from "types/Account/AccountCreation";
import Loader from "ui/feedback/Loader";
import Modal from "ui/overlays/Modal";
import SuccessModal from "ui/overlays/SuccessModal";
import { ModalBodyMessage, ModalFooter, ModalTitle } from "ui/styles";
import { getBrowserLocal } from "utils/common.utils";
import * as Yup from "yup";

const countries = Country.getAllCountries();

const Wrapper = styled.div`
  width: 100%;
  margin-top: 12px;
  @media only screen and (max-width: ${props => props.theme.other.breakpoints.lg}) {
    display: block;
    width: 100%;
  }
`;

const StyleModalHeaderDescription = styled.div`
  font-family: ${props => props.theme.other.font.fontFamily};
  font-style: ${props => props.theme.other.font.fontStyle};
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375rem;
  text-align: center;
  color: ${props => props.theme.other.monochromeColor[2]};
`;

interface AddNewUserInterface {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface DropdownDataInterface {
  label: string;
  value: string;
}

const countryDropdown = countries.map(country => ({ label: country.name, value: country.isoCode }));

const countryTimezoneDropdown = (countryCode: string): DropdownDataInterface[] => {
  const timezonesArr = Country.getCountryByCode(countryCode);
  if (timezonesArr?.timezones) {
    return timezonesArr?.timezones?.map(timezone => {
      return { label: timezone.zoneName, value: timezone.zoneName };
    });
  }
  return [];
};

const countryStatesDropdown = (countryCode: string): DropdownDataInterface[] => {
  const states = State.getStatesOfCountry(countryCode);
  return states?.map(state => {
    return { label: state.name, value: state.isoCode };
  });
};

export const AddNewAccount: React.FC<AddNewUserInterface> = ({ openModal, setOpenModal }) => {
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const createAccountMutation = useCreateAccountMutation();

  const onModalClose = () => {
    addNewAccountForm.reset();
    setOpenModal(false);
  };

  const handleCountryChange = (value: string) => {
    addNewAccountForm.setFieldValue("country", value);
    addNewAccountForm.setFieldValue("localTimeZone", "");
    addNewAccountForm.setFieldValue("region", "");
  };
  const onFormSubmitHandler = (payload: TAccountCreation) => {
    createAccountMutation.mutate(
      {
        ...payload,
        locale: getBrowserLocal()
      },
      {
        onSuccess: () => {
          setShowSuccessModal(true);
        }
      }
    );
    addNewAccountForm.reset();
  };

  const schema = Yup.object().shape({
    name: Yup.string().required("Account name is required"),
    country: Yup.string().required("Country is required"),
    localTimeZone: Yup.string().required("Timezone is required")
  });

  const addNewAccountForm = useForm({
    initialValues: {
      name: "",
      localTimeZone: "",
      street: "",
      city: "",
      region: "",
      postalCode: "",
      country: "",
      taxId: "",
      crmId: ""
    },
    validate: yupResolver(schema)
  });

  if (createAccountMutation.isLoading) {
    return <LoadingOverlay loader={<Loader />} visible />;
  }

  return (
    <Modal opened={openModal} setOpened={setOpenModal} handleClose={onModalClose} withCloseButton>
      <ModalTitle centered>Create New Organization Account</ModalTitle>
      <ModalBodyMessage>
        <StyleModalHeaderDescription>
          Enter in the account information. Required fields are marked with an asterisk*.
        </StyleModalHeaderDescription>
      </ModalBodyMessage>
      <Wrapper>
        <form onSubmit={addNewAccountForm.onSubmit(values => onFormSubmitHandler(values))}>
          <Grid gutter={12}>
            <Grid.Col span={12}>
              <TextInput label="Account Name" withAsterisk {...addNewAccountForm.getInputProps("name")} />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput label="Address" {...addNewAccountForm.getInputProps("street")} />
            </Grid.Col>
            <Grid.Col span={6}>
              <Select
                searchable
                placeholder="Select Country"
                label="Country"
                withAsterisk
                data={countryDropdown}
                value={addNewAccountForm.values.country}
                error={addNewAccountForm.errors.country}
                onChange={(value: string) => handleCountryChange(value)}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <Select
                searchable
                withAsterisk
                placeholder="Select Timezone"
                label="Time Zone"
                data={countryTimezoneDropdown(addNewAccountForm.values.country)}
                value={addNewAccountForm.values.localTimeZone}
                error={addNewAccountForm.errors.localTimeZone}
                onChange={(value: string) => addNewAccountForm.setFieldValue("localTimeZone", value)}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput label="City" {...addNewAccountForm.getInputProps("city")} />
            </Grid.Col>
            <Grid.Col span={6}>
              <Select
                searchable
                placeholder="State"
                label="State"
                data={countryStatesDropdown(addNewAccountForm.values.country)}
                value={addNewAccountForm.values.region}
                onChange={(value: string) => addNewAccountForm.setFieldValue("region", value)}
              />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput label="Zip Code" {...addNewAccountForm.getInputProps("postalCode")} />
            </Grid.Col>
            <Grid.Col span={6}>
              <TextInput label="VAT/Tax Id" {...addNewAccountForm.getInputProps("taxId")} />
            </Grid.Col>
            <Grid.Col span={12}>
              <TextInput label="CRM ID" {...addNewAccountForm.getInputProps("crmId")} />
            </Grid.Col>

            <Grid.Col span={12}>
              <ModalFooter>
                <Button
                  variant="default"
                  type="button"
                  onClick={() => {
                    setOpenModal(false);
                    addNewAccountForm.reset();
                  }}
                >
                  Cancel
                </Button>
                <Button type="submit">Create</Button>
              </ModalFooter>
            </Grid.Col>
          </Grid>
        </form>
      </Wrapper>
      <SuccessModal
        openModal={showSuccessModal}
        setOpenModal={setShowSuccessModal}
        message="A Kloopify Account was successfully created for this organization."
        closeOpenedModal={setOpenModal}
      />
    </Modal>
  );
};
