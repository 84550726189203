import { QUERY_KEYS } from "constants/queryKeys";
import { useSuspenseQuery } from "hooks";

import { getAnalyticsDatumService } from "../api";

export const useGetAnalyticsDatumQuery = (accountId: string) => {
  const query = useSuspenseQuery({
    queryKey: [QUERY_KEYS.get_analytics_datum_query, { accountId }],
    queryFn: () => getAnalyticsDatumService(accountId),
    suspense: true
  });
  return query;
};
