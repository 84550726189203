import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { debounce, uniqBy } from "lodash";
import { useGetSupplierListQuery } from "modules/suppliers";
import { ISupplier } from "modules/suppliers/suppliers.types";
import { MultiSelect } from "ui";

import { FilterTitle } from "./styles";
import { FilterMultiSelectProps } from "./types";

export const SupplierMultiSelect: React.FC<FilterMultiSelectProps<ISupplier>> = ({
  title,
  placeholder,
  label,
  placement = "bottom",
  onChange,
  value,
  initialData
}) => {
  const [search, setSearch] = useState<string | null>(null);
  const [options, setOptions] = useState<ISupplier[]>([]);

  const { id: accountId } = useParams();

  const onSearch = debounce((value: string) => (value ? setSearch(value) : setSearch(null)), 300);

  const fetchEnabled = !!(search && search?.length >= 3);

  const { data, error, isFetching } = useGetSupplierListQuery(accountId, { name: search }, { enabled: fetchEnabled });

  useEffect(() => {
    if (data?.data) {
      setOptions(prev => {
        const newOptions = [...prev, ...data.data].filter(option => option.id) ?? [];
        return uniqBy(newOptions, ({ id }) => id);
      });
    }
  }, [data?.data]);

  useEffect(() => {
    if (initialData) {
      setOptions(prev => {
        const newOptions = [...prev, ...initialData];
        return uniqBy(newOptions, ({ id }) => id);
      });
    }
  }, [initialData]);

  return (
    <>
      {title && <FilterTitle>{title}</FilterTitle>}
      <MultiSelect
        dataSource={options}
        label={label}
        transformData={d => ({ label: d.name ?? "", value: d.id ?? "" })}
        loading={isFetching}
        placeholder={placeholder}
        searchValue={search ?? ""}
        onSearchChange={onSearch}
        error={error?.response?.data?.errorMessage}
        clearSearchOnBlur={false}
        onChange={onChange}
        value={value}
        dropdownPosition={placement}
        withinPortal
      />
    </>
  );
};
