import { useQuery } from "@tanstack/react-query";
import { errorModal } from "modules/error/ErrorHandlingModals";
import { ErrorResponse } from "types";

import { getGoogleUserInfoService } from "../api";

export const useGetGoogleUserInfoQuery = (accessToken: string, queryEnabled: boolean) => {
  const query = useQuery({
    queryKey: ["googleLogin", accessToken],
    queryFn: () => getGoogleUserInfoService(accessToken),
    enabled: accessToken !== "" && queryEnabled === true,
    staleTime: Infinity,
    onError: (error: ErrorResponse) => errorModal(error),
    useErrorBoundary: true
  });
  return query;
};
