import { FC, Fragment, useMemo } from "react";

import { Button } from "@mantine/core";
import { Text } from "@mantine/core";
import { ISupplier } from "modules/suppliers";
import { TAnalyticsResponse } from "types";
import RoleGuardWrapper from "ui/wrappers/RoleGuardWrapper";

import {
  StyledAccountName,
  StyledEditSupplierButton,
  StyledSupplierName,
  StyledSupplierNameWrapper
} from "../supplierDetails.styles";

type SupplierDetailsHeaderProps = {
  accountAnalytics: TAnalyticsResponse[];
  onEdit?: () => void;
  supplier: ISupplier;
};

export const SupplierDetailsHeader: FC<SupplierDetailsHeaderProps> = ({ supplier, accountAnalytics, onEdit }) => {
  const accountName = supplier.account.name;

  const name = supplier.name;

  const isUrgent = useMemo(
    () =>
      accountAnalytics
        .sort((a, b) => b.data[0].urgencyScore - a.data[0].urgencyScore)
        .slice(0, 5)
        .find(i => i.group.supplier === supplier.id),
    [accountAnalytics]
  );

  return (
    <Fragment>
      <StyledAccountName>{accountName}</StyledAccountName>
      <StyledSupplierNameWrapper>
        <StyledSupplierName>
          {isUrgent && (
            <Text c="#B20B33" fw={700} display="inline">
              (Urgent)&nbsp;
            </Text>
          )}
          {name}
        </StyledSupplierName>
        {onEdit && (
          <RoleGuardWrapper roles={["ACCOUNT_ADMIN", "INTERNAL_ADMIN", "INTERNAL_ANALYST"]}>
            <StyledEditSupplierButton>
              <Button size="lg" onClick={() => onEdit()}>
                Edit Supplier
              </Button>
            </StyledEditSupplierButton>
          </RoleGuardWrapper>
        )}
      </StyledSupplierNameWrapper>
    </Fragment>
  );
};
