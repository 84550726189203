import { UseFormReturnType } from "@mantine/form";
import { TUserFeatureFlagModalForm } from "modules/users";
import { UserFeatureFlagsList } from "modules/users/components/UserFeatureFlagsModal/UserFeatureFlagsList";
import { UserFeatureFlagsAccountWrapper, UserFeatureFlagsRowWrapper } from "modules/users/components/styles";

export const AccountFeatureFlagModalBody = ({
  formInstance
}: {
  formInstance: UseFormReturnType<TUserFeatureFlagModalForm>;
}) => {
  const { getInputProps } = formInstance;

  const accountId = getInputProps("accountId").value;

  const features = getInputProps("features").value;

  return (
    <UserFeatureFlagsRowWrapper alignLeft accountFlags>
      <UserFeatureFlagsAccountWrapper>
        {accountId && <UserFeatureFlagsList features={features} formInstance={formInstance} />}
      </UserFeatureFlagsAccountWrapper>
    </UserFeatureFlagsRowWrapper>
  );
};
