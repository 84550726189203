import { useEffect, useMemo } from "react";

import { QCSelect } from "modules/questionnaire";
import { QCFormInstance, questionnaireItemMapOptions, TQCQuestion } from "modules/questionnaire";
import { QuestionnaireQuestionType } from "types";

type QCQuestionMappingSelectProps = {
  form: QCFormInstance;
  index: number;
  scope: "productQuestionList" | "supplierQuestionList";
};

export const QCQuestionMappingSelect = ({ form, scope, index }: QCQuestionMappingSelectProps) => {
  const type = form.getInputProps(`${scope}.${index}.type`).value;

  const isMapped =
    scope === "productQuestionList" &&
    (type === QuestionnaireQuestionType.SHORT_TEXT ||
      type === QuestionnaireQuestionType.LONG_TEXT ||
      type === QuestionnaireQuestionType.SELECT);

  const selectData = useMemo(() => {
    const scopeList = form.getInputProps(`${scope}`).value as TQCQuestion[];

    const currentMapping = form.getInputProps(`${scope}.${index}.itemMap`).value;

    const currentMappingOption = questionnaireItemMapOptions.find(i => i.value === currentMapping?.field);

    const appliedMappingList = scopeList.map(i => i.itemMap?.field).filter(i => i);

    const availableMappingList = questionnaireItemMapOptions.filter(i => !appliedMappingList.includes(i.value));

    return currentMapping && currentMappingOption
      ? [...availableMappingList, currentMappingOption]
      : availableMappingList;
  }, [form]);

  const clearCurrentSelection = () => {
    const scopeList = form.getInputProps(`${scope}`).value as TQCQuestion[];

    if (!scopeList.length) return;

    const updatedScopeList = scopeList.map((q, i) => {
      if (i !== index) return q;
      const updated = { ...q };
      delete updated.itemMap;
      return updated;
    });

    form.setValues(prev => ({ ...prev, [scope]: updatedScopeList }));
  };

  const onChange = (value: string) => {
    if (value) return form.getInputProps(`${scope}.${index}.itemMap`).onChange({ field: value });
    clearCurrentSelection();
  };

  useEffect(() => clearCurrentSelection(), [type]);

  if (!isMapped) return null;

  return (
    <QCSelect
      data={selectData}
      clearable
      size="xs"
      w="100%"
      placeholder="Map Answer to Item?"
      {...form.getInputProps(`${scope}.${index}.itemMap`)}
      value={form.getInputProps(`${scope}.${index}.itemMap`).value?.["field"] || null}
      onChange={onChange}
    />
  );
};
