import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";

import { getAccountFeatureFlagListService } from "../api";

export const useGetAccountFeatureFlagListQuery = (apiArgs: { accountId: string }) => {
  const { accountId } = apiArgs;
  return useQuery({
    queryKey: [QUERY_KEYS.get_feature_flag_list_account, apiArgs],
    queryFn: () => getAccountFeatureFlagListService(accountId),
    staleTime: Infinity,
    enabled: Boolean(accountId)
  });
};
