import { Text } from "@mantine/core";
import moment from "moment";
import { CompactLineChart, LineChartProps } from "ui";

import { ReportsEmissionsChartContainer, StyleLabel } from "../styles";

export const ReportsEmissionsChartCard = (compactChartProps: LineChartProps) => {
  const chartData = compactChartProps.xAxis;

  const startDate = chartData?.[0] || "";
  const endDate = chartData?.[chartData.length - 1] || "";

  const title =
    startDate && endDate
      ? `${moment(startDate, "MMM-YYYY").format("MMM YYYY")} - ${moment(endDate, "MMM-YYYY").format("MMM YYYY")}`
      : "No data for selected range.";
  return (
    <ReportsEmissionsChartContainer>
      <StyleLabel>Spend vs. CO₂e Emissions by Month</StyleLabel>
      <CompactLineChart {...compactChartProps} />
      {title && (
        <Text ta="center" c="#404040">
          {title}
        </Text>
      )}
    </ReportsEmissionsChartContainer>
  );
};
