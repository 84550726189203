import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "constants/queryKeys";
import { errorModal } from "modules/error/ErrorHandlingModals";
import { ErrorResponse } from "types";

import { getCommodityService } from "../api/getCommodityService";

export const useCommodityQuery = (commodityId?: string) =>
  useQuery({
    queryKey: [QUERY_KEYS.get_commodity, { commodityId }],
    queryFn: () => getCommodityService(commodityId),
    onError: (error: ErrorResponse) => errorModal(error),
    enabled: Boolean(commodityId),
    useErrorBoundary: true
  });
